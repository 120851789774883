import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'roundTotal'})
export class RoundTotalPipe implements PipeTransform {
    transform(input: number) {
        if (input) {
            return input.toFixed(2);
        } else {
            return 0.0;
        }
    }
}
