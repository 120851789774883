import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {ILateReason, LateReason} from 'app/blocks/model/late-reason.model';

@Injectable({
    providedIn: 'root'
})
export class LateReasonService extends EntityCrudService<ILateReason> {
    constructor(http: HttpClient) {
        super(http, Resource.LATE_REASONS, (obj?) => new LateReason(obj));
    }

    public getLite = (): Promise<LateReason[]> => {
        return this.http.get<any[]>(`${SERVER_API_URL}api/late-reasons-lite`).toPromise();
    };
}
