import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IUserDefault, UserDefault} from 'app/blocks/model/user-default-model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class UserDefaultService extends EntityCrudService<IUserDefault> {
    constructor(http: HttpClient) {
        super(http, Resource.USER_DEFAULT, (obj?) => new UserDefault(obj));
    }

    createUpdateUserDefault = (settings: string, screen: string): Promise<any> => {
        return this.http.put(`${SERVER_API_URL}api/user-defaults/create-edit/${screen}`, settings).toPromise();
    };

    getUserDefaultsForScreen = (screen: string): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/user-defaults/screen/${screen}`).toPromise();
    };
}
