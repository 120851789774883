import {DiDateTime} from 'app/blocks/util/di-date-time';
import {parseISO, format} from 'date-fns';
import moment from 'moment';
import {off} from 'process';

export function getTimeText(isoDateString: string, trimDateIfToday = true): string {
    const date = parseISO(isoDateString);
    let time;
    if (trimDateIfToday && isToday(date)) {
        time = format(date, 'p');
    } else {
        time = format(date, 'PPPp');
    }
    return time;
}

export function getShortDateTime(isoDateString: string): string {
    const date = parseISO(isoDateString);
    let time;
    time = format(date, 'p');

    const yearStr: string = date.getFullYear().toString();
    const month: number = date.getMonth() + 1;
    const monthStr: string = month < 10 ? '0' + month.toString() : month.toString();
    const day: number = date.getDate();
    const dayStr: string = day < 10 ? '0' + day.toString() : day.toString();

    return dayStr + '/' + monthStr + '/' + yearStr + ' ' + time;
}

export function isToday(someDate): boolean {
    const today = new Date();
    return isSameDay(someDate, today);
}

export function isSameDay(date1, date2): boolean {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function toIsoLocalDateString(date: Date): string {
    const yearStr: string = date.getFullYear().toString();

    const month: number = date.getMonth() + 1;
    const monthStr: string = month < 10 ? '0' + month.toString() : month.toString();

    const day: number = date.getDate();
    const dayStr: string = day < 10 ? '0' + day.toString() : day.toString();

    return yearStr + '-' + monthStr + '-' + dayStr;
}

export function toIsoLocalDateTimeString(date: Date): string {
    const yearStr: string = date.getFullYear().toString();

    const month: number = date.getMonth() + 1;
    const monthStr: string = month < 10 ? '0' + month.toString() : month.toString();

    const day: number = date.getDate();
    const dayStr: string = day < 10 ? '0' + day.toString() : day.toString();

    const hour: number = date.getHours();
    const hourStr: string = hour < 10 ? '0' + hour.toString() : hour.toString();

    const minute: number = date.getMinutes();
    const minuteStr: string = minute < 10 ? '0' + minute.toString() : minute.toString();

    const second: number = date.getSeconds();
    const secondStr: string = second < 10 ? '0' + second.toString() : second.toString();

    return yearStr + '-' + monthStr + '-' + dayStr + 'T' + hourStr + ':' + minuteStr + ':' + secondStr;
}

export function combineToIsoLocalDateTimeString(date: Date, time: Date): string {
    const yearStr: string = date.getFullYear().toString();

    const month: number = date.getMonth() + 1;
    const monthStr: string = month < 10 ? '0' + month.toString() : month.toString();

    const day: number = date.getDate();
    const dayStr: string = day < 10 ? '0' + day.toString() : day.toString();

    const hour: number = time.getHours();
    const hourStr: string = hour < 10 ? '0' + hour.toString() : hour.toString();

    const minute: number = time.getMinutes();
    const minuteStr: string = minute < 10 ? '0' + minute.toString() : minute.toString();

    const second: number = time.getSeconds();
    const secondStr: string = second < 10 ? '0' + second.toString() : second.toString();

    return yearStr + '-' + monthStr + '-' + dayStr + 'T' + hourStr + ':' + minuteStr + ':' + secondStr;
}

export function toStartOfDayLocalDateTimeString(date: Date): string {
    const yearStr: string = date.getFullYear().toString();

    const month: number = date.getMonth() + 1;
    const monthStr: string = month < 10 ? '0' + month.toString() : month.toString();

    const day: number = date.getDate();
    const dayStr: string = day < 10 ? '0' + day.toString() : day.toString();

    return yearStr + '-' + monthStr + '-' + dayStr + 'T00:00:00';
}

export function toEndOfDayLocalDateTimeString(date: Date): string {
    const yearStr: string = date.getFullYear().toString();

    const month: number = date.getMonth() + 1;
    const monthStr: string = month < 10 ? '0' + month.toString() : month.toString();

    const day: number = date.getDate();
    const dayStr: string = day < 10 ? '0' + day.toString() : day.toString();

    return yearStr + '-' + monthStr + '-' + dayStr + 'T23:59:59';
}

export function getDurationBetweenTimes(startTime: any, endTime: any): string {
    return millisecondsToDateString(new Date(endTime).valueOf() - new Date(startTime).valueOf());
}

export function millisecondsToDateString(duration: number): string {
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    return hours + ':' + (minutes < 10 ? '0' : '') + minutes;
}

export function millisecondsToTimezoneOffsetString(offset: number): string {
    const minutes = Math.floor((offset / (1000 * 60)) % 60);
    const hours = Math.floor((offset / (1000 * 60 * 60)) % 24);
    const minutesString: string = minutes > 0 ? '.' + (minutes < 10 ? '0' : '') + minutes : '';
    return (offset < 0 ? '-' : '+') + hours + minutesString;
}

export function formatToTimezone(dateTime?: any, offset: any = null): string {
    if (!dateTime) {
        return '';
    }
    if (offset === null) {
        return formatTimeIgnoreTimezones(moment(dateTime));
    }

    const localTime = moment(dateTime).utc().add(offset); // is this doing what it's intended to??
    let timeString = formatTimeIgnoreTimezones(localTime);

    if (moment(dateTime).utcOffset() - offset !== 0) {
        timeString += ` local time (UTC${millisecondsToTimezoneOffsetString(offset)})`;
    }

    return timeString;
}

export function formatTimeIgnoreTimezones(dateTime?: any): string {
    if (!dateTime) {
        return '';
    }
    const dateTimeMoment: moment.Moment = moment(dateTime);
    const today: moment.Moment = moment();

    const isToday: boolean =
        dateTimeMoment.date() === today.date() && dateTimeMoment.month() === today.month() && dateTimeMoment.year() === today.year();

    return dateTimeMoment.format((isToday ? '' : 'DD/MM/YYYY') + ' HH:mm');
}

export function parseTimezoneOffsetInMinutes(dtm: string) {
    const date = new Date(Date.parse(dtm));
    return date.getTimezoneOffset();
}

export function formatTimeWindow(startDate: DiDateTime, endDate: DiDateTime): string {
    const format = isSameDay(startDate.date, endDate.date) ? 'HH:mm' : 'DD/MM HH:mm';
    return `${startDate.format(format)} - ${endDate.format(format)}`;
}

export function tpCombineDateAndTimeToUTCFormatString(dateRef: Date, timeRef: Date): string {
    dateRef = dateRef as Date;
    timeRef = timeRef as Date;
    return (
        dateRef.getFullYear() +
        '-' +
        ('0' + (dateRef.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dateRef.getDate()).slice(-2) +
        'T' +
        ('0' + timeRef.getHours()).slice(-2) +
        ':' +
        ('0' + timeRef.getMinutes()).slice(-2) +
        ':00Z'
    );
}

export function toISOFormatStringWithoutTimezone(date: Date): string {
    return tpCombineDateAndTimeToUTCFormatString(date, date);
}

export function parseDateWithoutTimezoneOffset(dtm: string): Date {
    const date = new Date(dtm);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}
