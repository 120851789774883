import {Company} from 'app/blocks/model/company.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICompany} from 'app/blocks/model/company.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends EntityCrudService<ICompany> {
    constructor(http: HttpClient) {
        super(http, Resource.COMPANIES, (obj?) => new Company(obj));
    }

    getCompany = (): Promise<any> => {
        return this.http
            .get<any>(SERVER_API_URL + 'api/company')
            .toPromise()
            .then((response) => this._entityCreator(response));
    };

    addCompany = (entity: ICompany): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/company', entity).toPromise();
    };

    updateCompany = (entity: ICompany): Promise<any> => {
        return this.http.put<any>(SERVER_API_URL + 'api/company', entity).toPromise();
    };

    getConfig = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/company/config').toPromise();
    };

    uploadCustomerLogo = (selectedFile: any, width: number, height: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', selectedFile, selectedFile.name);
        fd.append('width', '' + width);
        fd.append('height', '' + height);
        return this.http.post<any>(SERVER_API_URL + `api/company/logo`, fd).toPromise();
    };

    getLogo(): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + 'api/company/logo';
        return this.http.get(REQUEST_URI, {responseType: 'blob'}).toPromise();
    }
}
