import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {DiDate} from 'app/blocks/util/di-date';
import {ILeg} from 'app/blocks/model/leg.model';

export interface ILegRoute {
    id?: number;
    startLatitude?: number;
    startLongitude?: number;
    endLatitude?: number;
    endLongitude?: number;
    targetDepartureTime?: any;
    targetArrivalTime?: any;
    departureTime?: any;
    arrivalTime?: any;
    distance?: number;
    polyline?: string;
}

export class LegRoute implements ILegRoute {
    public id?: number;
    public startLatitude?: number;
    public startLongitude?: number;
    public endLatitude?: number;
    public endLongitude?: number;
    public targetDepartureTime?: any;
    public targetArrivalTime?: any;
    public departureTime?: any;
    public arrivalTime?: any;
    public distance?: number;
    public polyline?: string;

    constructor(run?: any) {
        if (run) {
            this.id = run.id;
            this.startLatitude = run.startLatitude;
            this.startLongitude = run.startLongitude;
            this.endLatitude = run.endLatitude;
            this.endLongitude = run.endLongitude;
            this.targetDepartureTime = run.targetDepartureTime;
            this.targetArrivalTime = run.targetArrivalTime;
            this.departureTime = run.departureTime;
            this.arrivalTime = run.arrivalTime;
            this.distance = run.distance;
            this.polyline = run.polyline;
        }
    }

    clone(): LegRoute {
        return new LegRoute(this);
    }
}
