import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IOrderGroup, OrderGroup} from 'app/blocks/model/order-group.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class OrderGroupService extends EntityCrudService<IOrderGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.ORDER_GROUPS, (obj?) => new OrderGroup(obj));
    }

    public updateOrderInfo = (bodyInfo): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/order-groups/update-groups`;
        return this.http.post(REQUEST_URI, bodyInfo).toPromise();
    };
}
