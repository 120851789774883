import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {Currency, ICurrency} from 'app/blocks/model/currency.model';

export interface IDailyReportSchedule extends IBaseEntity {
    id?: number;
    email?: string;
    type?: string;
    filterJSON?: ICurrency;
}

export class DailyReportSchedule extends BaseEntity implements IDailyReportSchedule {
    public id?: number;
    public email?: string;
    public type?: string;
    public filterJSON?: ICurrency;

    constructor(dailyReportSchedule?) {
        super();
        if (dailyReportSchedule) {
            this.id = dailyReportSchedule.id;
            this.email = dailyReportSchedule.email;
            this.type = dailyReportSchedule.type;
            this.filterJSON = dailyReportSchedule.filterJSON;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DailyReportSchedule {
        return new DailyReportSchedule(this);
    }
}
