import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {DiInlineEntityActionMenuComponent} from 'app/common/di-inline-entity-action-menu/di-inline-entity-action-menu.component';

@NgModule({
    declarations: [DiInlineEntityActionMenuComponent],
    imports: [CommonModule, FontAwesomeModule],
    exports: [DiInlineEntityActionMenuComponent],
    providers: []
})
export class DiInlineEntityActionMenuModule {}
