import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';

import {FuseDemoContentComponent} from '@fuse/components/demo/demo-content/demo-content.component';
import {FuseDemoSidebarComponent} from '@fuse/components/demo/demo-sidebar/demo-sidebar.component';

@NgModule({
    declarations: [FuseDemoContentComponent, FuseDemoSidebarComponent],
    imports: [RouterModule, MatDividerModule, MatListModule],
    exports: [FuseDemoContentComponent, FuseDemoSidebarComponent]
})
export class FuseDemoModule {}
