import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DiSearchSelectComponent} from 'app/common/di-search-select/di-search-select.component';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzPopoverModule} from 'ng-zorro-antd/popover';

@NgModule({
    declarations: [DiSearchSelectComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NzSelectModule,
        NzFormModule,
        NzToolTipModule,
        NzIconModule
    ],
    exports: [DiSearchSelectComponent],
    providers: []
})
export class DiSearchSelectModule {}
