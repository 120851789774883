import {NgModule} from '@angular/core';

import {FuseWidgetComponent} from '@fuse/components/widget/widget.component';
import {FuseWidgetToggleDirective} from '@fuse/components/widget/widget-toggle.directive';

@NgModule({
    declarations: [FuseWidgetComponent, FuseWidgetToggleDirective],
    exports: [FuseWidgetComponent, FuseWidgetToggleDirective]
})
export class FuseWidgetModule {}
