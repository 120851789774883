import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IRestore extends IBaseEntity {
    id?: number;
    tenantName: string;
    created: string;
    lastUpdated: string;
    status: string;
    url: string;
}

export class Restore extends BaseEntity implements IRestore {
    public id: number;
    public tenantName: string;
    public created: string;
    public lastUpdated: string;
    public status: string;
    public url: string;

    constructor(restore?) {
        super();
        if (restore) {
            this.tenantName = restore.tenantName;
            this.created = restore.created;
            this.lastUpdated = restore.lastUpdated;
            this.status = restore.status;
            this.url = restore.url;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Restore {
        return new Restore(this);
    }
}
