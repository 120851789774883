import {Router, ActivatedRoute} from '@angular/router';

export interface INavigator {
    toHome(): void;
}

export interface IEntityNavigator extends INavigator {
    toList(): void;
    toView(id: number): void;
    toEdit(id: number): void;
    toNew(params?: any): void;
    toCustom(id: number, urlParam: string): void;
    openNewWindow(id: number);
}

export class EntityNavigator implements IEntityNavigator {
    private _resourcePath;

    constructor(
        private _basePath: string,
        private _resouceName: string,
        private _router: Router
    ) {
        this._resourcePath = this.camelCaseToDashed(_resouceName);
    }

    camelCaseToDashed(str): string {
        return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }

    toHome(): void {
        this.toList();
    }

    toList(params?: any): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath], params);
    }

    toView(id: number): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id]);
    }

    toEdit(id: number): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id, 'edit']);
    }

    toNew(params?: any): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, 'new'], params);
    }

    toCustom(id: number, urlParam: string): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id, urlParam]);
    }

    openNewWindow(id: number): void {
        const routeParts = ['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id];
        const absoluteURL = `${window.location.protocol}//${window.location.host}${routeParts[0]}${routeParts[1]}/${routeParts[2]}/${routeParts[3]}`;
        window.open(absoluteURL);
    }
}
