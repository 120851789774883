import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faBuilding} from '@fortawesome/free-solid-svg-icons';
import {OrganisationService} from 'app/blocks/service/api/organisation.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IOrganisation, Organisation} from 'app/blocks/model/organisation.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class OrganisationResource extends AbstractEntityResource<IOrganisation> {
    constructor(organisationService: OrganisationService, router: Router) {
        super(
            Resource.ORGANISATIONS,
            organisationService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ORGANISATIONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ORGANISATIONS),
            (entity?) => new Organisation(entity),
            (entity) => entity.name,
            faBuilding
        );
    }
}
