import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {DiCreateEditHeaderModule} from 'app/common/di-create-edit-header/di-create-edit-header.module';
import {DiEntityActionMenuModule} from 'app/common/di-entity-action-menu/di-entity-action-menu.module';
import {DiActionMenuModule} from 'app/common/di-action-menu/di-action-menu.module';
import {DiGroupActionMenuModule} from 'app/common/di-group-action-menu/di-group-action-menu.module';
import {DiFormsModule} from 'app/common/di-forms/di-forms.module';
import {DiListPageHeaderModule} from 'app/common/di-list-page-header/di-list-page-header.module';
import {DiPageTotalItemsModule} from 'app/common/di-page-total-items/di-page-total-items.module';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {DiViewHeaderModule} from 'app/common/di-view-header/di-view-header.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        NzTabsModule,
        NzButtonModule,
        FontAwesomeModule,
        DiFormsModule,
        DiListPageHeaderModule,
        DiCreateEditHeaderModule,
        DiEntityActionMenuModule,
        DiActionMenuModule,
        DiGroupActionMenuModule,
        DiPageTotalItemsModule,
        ReactiveFormsModule,
        NzTableModule,
        NzTabsModule,
        NzPaginationModule
    ],
    declarations: [],
    exports: [
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        NzTabsModule,
        NzButtonModule,
        FontAwesomeModule,
        DiFormsModule,
        DiListPageHeaderModule,
        DiCreateEditHeaderModule,
        DiViewHeaderModule,
        DiEntityActionMenuModule,
        DiActionMenuModule,
        DiGroupActionMenuModule,
        DiPageTotalItemsModule,
        ReactiveFormsModule,
        NzTableModule,
        NzPaginationModule,
        FlexLayoutModule
    ],
    providers: []
})
export class DiEntityLibsModule {}
