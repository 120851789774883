import {ModuleWithProviders, NgModule} from '@angular/core';
import {DiBlockingDialogComponent} from 'app/common/blocking-message/blocking-modal/blocking-modal.component';
import {BlockerDialogService} from 'app/common/blocking-message/blocker-dialog-service';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzSpinModule} from 'ng-zorro-antd/spin';

@NgModule({
    declarations: [DiBlockingDialogComponent],
    imports: [NzModalModule, NzSpinModule]
})
export class BlockerDialogModule {
    static forRoot(): ModuleWithProviders<BlockerDialogModule> {
        return {
            ngModule: BlockerDialogModule,
            providers: [BlockerDialogService]
        };
    }
}
