import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Principal} from 'app/core/auth/principal.service';
import {UnsubscribeDirective} from 'app/common/unsubscribe.directive';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ServerEventService extends UnsubscribeDirective {
    public userNotificationEvent = new BehaviorSubject<any>([]);
    public tenantSettingUpdateEvent = new BehaviorSubject<any>([]);
    public exhangeRateUpdateEvent = new BehaviorSubject<any>([]);
    public runStatusChangeEvent = new BehaviorSubject<any>([]);
    public reindexEvent = new BehaviorSubject<any>([]);

    private signedOut = new Subject<void>();

    constructor(
        private db: AngularFireDatabase,
        private principal: Principal
    ) {
        super();
        this.principal
            .getAuthenticationState()
            .pipe(takeUntil(this._destroyed$))
            .subscribe((userIdentity) => {
                if (userIdentity) {
                    this.registerFireEvents(userIdentity, 'notification', this.userNotificationEvent);
                    this.registerFireEvents(userIdentity, 'tenantSettings', this.tenantSettingUpdateEvent, true);
                    this.registerFireEvents(userIdentity, 'updateExchangeRate', this.exhangeRateUpdateEvent, true);
                    this.registerFireEvents(userIdentity, 'runStatusChange', this.runStatusChangeEvent, true);
                    this.registerFireEvents(userIdentity, 'reindex', this.reindexEvent, true);
                } else {
                    this.signedOut.next();
                }
            });
    }

    registerFireEvents(userIdentity: any, eventName: string, subject: BehaviorSubject<any>, isTenantEvent = false) {
        const path =
            environment.firebaseConfig.env + '/events/' + userIdentity.tenantId + (isTenantEvent ? '' : '_' + userIdentity.id) + '/' + eventName;
        this.db
            .object(path)
            .valueChanges()
            .subscribe((value) => {
                subject.next(value || {});
            });
    }
}
