import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export class ClausedDeliveryReasonLite {
    public id?: number;
    public name?: string;
    public description?: string;
}

export interface IClausedDeliveryReason extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
}

export class ClausedDeliveryReason extends BaseEntity implements IClausedDeliveryReason {
    public id?: number;
    public name?: string;
    public description?: string;

    constructor(clausedDeliveryReason?: any) {
        super();
        if (clausedDeliveryReason) {
            this.id = clausedDeliveryReason.id;
            this.name = clausedDeliveryReason.name;
            this.description = clausedDeliveryReason.description;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ClausedDeliveryReason {
        return new ClausedDeliveryReason(this);
    }
}
