import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faPallet} from '@fortawesome/free-solid-svg-icons';
import {HaulierOrderService} from 'app/blocks/service/api/haulier-order.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL, ENTITIES_ORDERS_BASE_URL} from 'app/app.constants';
import {IHaulierOrder, HaulierOrder} from 'app/blocks/model/haulier-order.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class HaulierOrderResource extends AbstractEntityResource<IHaulierOrder> {
    constructor(haulierOrderService: HaulierOrderService, router: Router) {
        super(
            Resource.HAULIER_ORDERS,
            haulierOrderService,
            new EntityNavigator(ENTITIES_ORDERS_BASE_URL, Resource.HAULIER_ORDERS, router),
            new EntityUrlProvider(ENTITIES_ORDERS_BASE_URL, Resource.HAULIER_ORDERS),
            (entity?) => new HaulierOrder(entity),
            (entity) => entity.orderNo,
            faPallet
        );
    }
}
