import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface ICurrency extends IBaseEntity {
    id?: number;
    code?: string;
    name?: string;
    htmlCode: string;
    prefix: boolean;
}

export class Currency extends BaseEntity implements ICurrency {
    public id?: number;
    public code?: string;
    public name?: string;
    public htmlCode: string;
    public prefix: boolean;

    constructor(currency?) {
        super();
        if (currency) {
            this.id = currency.id;
            this.code = currency.code;
            this.name = currency.name;
            this.htmlCode = currency.htmlCode;
            this.prefix = currency.prefix;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): Currency {
        return new Currency(this);
    }
}
