import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {MultiTrunkPoint} from 'app/blocks/model/multi-trunk-point.model';

export interface IMultiTrunk extends IBaseEntity {
    id?: number;
    description?: string;
    enabled?: boolean;

    trunkPoints: MultiTrunkPoint[];
}

export class MultiTrunk extends BaseEntity implements IMultiTrunk {
    id: number;
    description: string;
    enabled: boolean;

    trunkPoints: MultiTrunkPoint[] = [];

    constructor(multiTrunk?) {
        super();
        if (multiTrunk) {
            this.id = multiTrunk.id;
            this.description = multiTrunk.description;
            this.enabled = multiTrunk.enabled;
            this.trunkPoints = multiTrunk.trunkPoints;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): MultiTrunk {
        return new MultiTrunk(this);
    }
}
