import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IExpenseType extends IBaseEntity {
    id?: number;
    name: string;
}

export class ExpenseType extends BaseEntity implements IExpenseType {
    public id?: number;
    public name: string;

    constructor(expenseType?: any) {
        super();
        if (expenseType) {
            this.id = expenseType.id;
            this.name = expenseType.name;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ExpenseType {
        return new ExpenseType(this);
    }
}
