import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IDriverRequestType extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
}

export class DriverRequestType extends BaseEntity implements IDriverRequestType {
    id?: number;
    name?: string;
    description?: string;

    constructor(driverRequestType?) {
        super();
        if (driverRequestType) {
            this.id = driverRequestType.id;
            this.name = driverRequestType.name;
            this.description = driverRequestType.description;
        }
    }

    clone(): DriverRequestType {
        return new DriverRequestType(this);
    }

    get discriminator(): any {
        return this.id;
    }
}
