import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ICreditNote} from 'app/blocks/model/credit-note.model';

export interface IUpdateCreditNoteAllocated extends IEntityAction {
    entity?: ICreditNote;
}

export class UpdateCreditNoteAllocated implements IUpdateCreditNoteAllocated {
    static readonly type = '[Credit Note] Update Credit Note';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: ICreditNote,
        meta?: ActionMeta
    ) {}
}
