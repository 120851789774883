import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {ITranslation, Translation} from 'app/blocks/model/translation.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class TranslationService extends EntityCrudService<ITranslation> {
    constructor(http: HttpClient) {
        super(http, Resource.TRANSLATION, (obj?) => new Translation(obj));
    }

    getTranslation = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/translation/all`).toPromise();
    };
}
