import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {FilterSetting} from 'app/blocks/model/filter-setting.model';
import {FilterParams} from 'app/blocks/util/filter-params';

@Injectable({
    providedIn: 'root'
})
export class FilterSettingService {
    constructor(private http: HttpClient) {}

    public getFilterSettings = (resourceName: string): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/filter-settings?page=${resourceName}`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public saveFilterSettings = (filters: any, resourceName: string): Promise<any> => {
        const mappedFilters: any = this.mapFilterSettings(filters, resourceName);
        const REQUEST_URI = `${SERVER_API_URL}api/filter-settings`;
        return this.http.put(REQUEST_URI, mappedFilters, {observe: 'response'}).toPromise();
    };

    public resetDateFilters = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/filter-settings/reset-dates`;
        return this.http.put(REQUEST_URI, id, {observe: 'response'}).toPromise();
    };

    private mapFilterSettings(filters: any, resourceName: string): any {
        const mappedFilters: any[] = [];
        Object.entries(filters).forEach((filter) => {
            const mappedFilter: FilterSetting = new FilterSetting();
            mappedFilter.id = null;
            mappedFilter.page = resourceName;
            mappedFilter.filter = filter[0];
            if (filter[1]) {
                mappedFilter.value = filter[1].toString();
            } else {
                mappedFilter.value = '';
            }
            mappedFilters.push(mappedFilter);
        });
        return mappedFilters;
    }

    public mapToFilterParams(filterSettings: FilterSetting[]): FilterParams[] {
        const filterParams: FilterParams[] = [];

        if (filterSettings.length > 0) {
            filterSettings.forEach((filterSetting) => {
                const filterParam: FilterParams = {
                    columnName: filterSetting.filter,
                    currentValue: filterSetting.value
                };
                filterParams.push(filterParam);
            });
        }

        return filterParams;
    }
}
