import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {DiDate} from 'app/blocks/util/di-date';

export interface ICustomerTransaction extends IBaseEntity {
    id?: number;
    transactionDate: any;
    customer: string;
    transactionAmount: number;
    previousBalance: number;
    newBalance: number;
    invoiceNo?: string;
    paymentReference?: string;
}

export class CustomerTransaction extends BaseEntity implements ICustomerTransaction {
    public id?: number;
    public transactionDate: DiDate;
    public customer: string;
    public transactionAmount: number;
    public previousBalance: number;
    public newBalance: number;
    public invoiceNo?: string;
    public paymentReference?: string;

    constructor(customerTransaction?) {
        super();
        if (customerTransaction) {
            this.id = customerTransaction.id;
            this.transactionDate = customerTransaction.transactionDate;
            this.customer = customerTransaction.customer;
            this.transactionAmount = customerTransaction.transactionAmount;
            this.previousBalance = customerTransaction.previousBalance;
            this.newBalance = customerTransaction.newBalance;
            this.invoiceNo = customerTransaction.invoiceNo;
            this.paymentReference = customerTransaction.paymentReference;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): CustomerTransaction {
        return new CustomerTransaction(this);
    }
}
