import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IPerson} from 'app/blocks/model/person.model';
import {IOrganisation} from 'app/blocks/model/organisation.model';
import {IInvoice} from 'app/blocks/model/invoice.model';
import {DiTime} from 'app/blocks/util/di-time';
import {DiDate} from 'app/blocks/util/di-date';

export interface IApplyPaymentDTO {
    invoiceId: number;
    invoice: IInvoice;
    amount: number;
}

export class ApplyPaymentDTO implements IApplyPaymentDTO {
    public invoiceId: number;
    public invoice: IInvoice;
    public amount: number;
    constructor(invoice?: IInvoice) {
        if (invoice) {
            this.invoiceId = invoice.id;
            this.invoice = invoice;
        }
        this.amount = 0;
    }
}

export interface IPayment extends IBaseEntity {
    id?: number;
    customerCurrency?: any;
    paymentDate?: any;
    paymentTime?: any;
    chequeDate?: any;
    cashAmount?: number;
    chequeAmount?: number;
    ccAmount?: number;
    ccRef?: string;
    totalAmount?: number;
    customer?: IOrganisation;
    customerId?: number;
    driver?: any;
    collectedById?: number;
    chequeNo?: string;
    paidBy?: string;
    reference?: string;
    paymentStatus?: string;
    allocatedAmount?: number;
    invoicesPaid?: IInvoice[];
    paymentAllocation?: any[];
    exported?: boolean;
    pgRef?: string;
    pgAmount?: number;
    isPartnerPayment?: boolean;
    partnerCreditAmount?: number;
    unappliedAmount?: number;
    collectedBy?: any;
    customerName?: string;
    collectedByFirstName?: string;
    collectedByLastName?: string;
}

export class Payment extends BaseEntity implements IPayment {
    public id?: number;
    public paymentDate?: any;
    public chequeDate?: any;
    public paymentTime?: any;
    public cashAmount?: number;
    public chequeAmount?: number;
    public ccAmount?: number;
    public ccRef?: string;
    public totalAmount?: number;
    public customer?: IOrganisation;
    public customerId?: number;
    public driver?: any;
    public collectedById?: number;
    public chequeNo?: string;
    public paymentStatus?: string;
    public paidBy?: string;
    public reference?: string;
    public allocatedAmount?: number;
    public invoicesPaid?: IInvoice[];
    public paymentAllocation?: any[];
    public exported?: boolean;
    public pgRef?: string;
    public pgAmount?: number;
    public isPartnerPayment?: boolean;
    public partnerCreditAmount?: number;
    public unappliedAmount?: number;
    public collectedBy?: any;
    public customerName?: string;
    public collectedByFirstName?: string;
    public collectedByLastName?: string;
    public customerCurrency?: any;

    constructor(payment?) {
        super();
        if (payment) {
            this.id = payment.id;
            this.paymentDate = payment.paymentDate;
            this.chequeDate = payment.chequeDate;
            this.chequeAmount = payment.chequeAmount;
            this.customerName = payment.customerName;
            this.paymentTime = DiTime.newInstance(payment.paymentTime);
            this.cashAmount = payment.cashAmount;
            this.chequeAmount = payment.chequeAmount;
            this.ccAmount = payment.ccAmount;
            this.ccRef = payment.ccRef;
            this.totalAmount = payment.totalAmount;
            this.customer = payment.customer;
            this.driver = payment.driver;
            this.chequeNo = payment.chequeNo;
            this.paymentStatus = payment.paymentStatus;
            this.paidBy = payment.paidBy;
            this.collectedById = payment.collectedById;
            this.customerId = payment.customerId;
            this.collectedBy = payment.collectedBy;
            this.reference = payment.reference;
            this.customerCurrency = payment.customerCurrency;
            this.allocatedAmount = payment.allocatedAmount || 0;
            this.invoicesPaid = payment.invoicesPaid || [];
            this.paymentAllocation = payment.paymentAllocation || [];
            this.exported = payment.exported;
            this.collectedByFirstName = payment.collectedByFirstName;
            this.collectedByLastName = payment.collectedByLastName;
            this.pgRef = payment.pgRef;
            this.pgAmount = payment.pgAmount;
            this.partnerCreditAmount = payment.partnerCreditAmount;
            this.isPartnerPayment = payment.isPartnerPayment;
            this.unappliedAmount = payment.unappliedAmount;
        } else {
            this.paymentDate = DiDate.currentDate();
            this.paymentTime = DiTime.currentTime();
            this.cashAmount = 0;
            this.chequeAmount = 0;
            this.totalAmount = 0;
            this.unappliedAmount = 0;
            this.ccAmount = 0;
            this.allocatedAmount = 0;
            this.pgAmount = 0;
            this.partnerCreditAmount = 0;
            this.invoicesPaid = [];
            this.paymentAllocation = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Payment {
        return new Payment(this);
    }
}
