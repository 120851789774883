import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faBuilding} from '@fortawesome/free-solid-svg-icons';
import {AttemptedDelivery, IAttemptedDelivery} from 'app/blocks/model/attempted-delivery-model';
import {AttemptedDeliveryService} from 'app/blocks/service/api/attempted-delivery.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IPackageReturns, PackageReturns} from 'app/blocks/model/package-returns.model';
import {PackageReturnsService} from 'app/blocks/service/api/package-returns.service';

@Injectable({
    providedIn: 'root'
})
export class AttemptedDeliveryResource extends AbstractEntityResource<IAttemptedDelivery> {
    constructor(attemptedDeliveryService: AttemptedDeliveryService, router: Router) {
        super(
            Resource.ATTEMPTED_DELIVERY,
            attemptedDeliveryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ATTEMPTED_DELIVERY, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ATTEMPTED_DELIVERY),
            (entity?) => new AttemptedDelivery(),
            (entity) => entity.id,
            faBuilding
        );
    }
}
