import {DiDate} from 'app/blocks/util/di-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IExtraType extends IBaseEntity {
    id?: number;
    description?: string;
    fuelSurchargeApplies?: boolean;
    isEnabled: boolean;
    isSystem?: boolean;
    nominalCodeId?: number;
    nominalCodeCode?: string;
    shortDescription?: string;
    isDiplsayedOnAddUpdateOrder?: boolean;
    isAcceptanceRequired?: boolean;
    isDisplayedOnClientAddUpdateOrder?: boolean;
    isTimeBased?: boolean;
    extraTypeStartTime?: Date; // this has to be date time
    extraTypeEndTime?: Date;
    isDayBased?: boolean;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
    sunday?: boolean;
    isClausedRefusal?: boolean;
}

export class ExtraType extends BaseEntity implements IExtraType {
    public id?: number;
    public description?: string;
    public fuelSurchargeApplies?: boolean;
    public isEnabled: boolean;
    public isSystem?: boolean;
    public nominalCodeId?: number;
    public nominalCodeCode?: string;
    public shortDescription?: string;
    public isDiplsayedOnAddUpdateOrder?: boolean;
    public isAcceptanceRequired?: boolean;
    public isDisplayedOnClientAddUpdateOrder?: boolean;
    public isTimeBased?: boolean;
    public extraTypeStartTime?: Date; // this has to be date time
    public extraTypeEndTime?: Date;
    public isDayBased?: boolean;
    public monday?: boolean;
    public tuesday?: boolean;
    public wednesday?: boolean;
    public thursday?: boolean;
    public friday?: boolean;
    public saturday?: boolean;
    public sunday?: boolean;
    public isClausedRefusal?: boolean;

    constructor(extraType?: any) {
        super();
        if (extraType) {
            this.id = extraType.id;
            this.description = extraType.description;
            this.fuelSurchargeApplies = extraType.fuelSurchargeApplies;
            this.isEnabled = extraType.isEnabled;
            this.isSystem = extraType.isSystem;
            this.nominalCodeId = extraType.nominalCodeId;
            this.nominalCodeCode = extraType.nominalCodeCode;
            this.shortDescription = extraType.shortDescription;
            this.isDiplsayedOnAddUpdateOrder = extraType.isDiplsayedOnAddUpdateOrder;
            this.isAcceptanceRequired = extraType.isAcceptanceRequired;
            this.isDisplayedOnClientAddUpdateOrder = extraType.isDisplayedOnClientAddUpdateOrder;
            this.isTimeBased = extraType.isTimeBased;
            this.extraTypeStartTime = this.extraTypeStartTime ? new Date(extraType.extraTypeStartTime) : extraType.extraTypeStartTime;
            this.extraTypeEndTime = this.extraTypeEndTime ? new Date(extraType.extraTypeEndTime) : extraType.extraTypeEndTime;
            this.isDayBased = extraType.isDayBased;
            this.monday = extraType.monday;
            this.tuesday = extraType.tuesday;
            this.wednesday = extraType.wednesday;
            this.thursday = extraType.thursday;
            this.friday = extraType.friday;
            this.saturday = extraType.saturday;
            this.sunday = extraType.sunday;
            this.isClausedRefusal = extraType.isClausedRefusal;
        }
    }

    get discriminator(): any {
        return this.description;
    }

    clone(): ExtraType {
        return new ExtraType(this);
    }
}
