import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faTruck} from '@fortawesome/free-solid-svg-icons';
import {DriverService} from 'app/blocks/service/api/driver.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDriver, Driver} from 'app/blocks/model/driver.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DriverResource extends AbstractEntityResource<IDriver> {
    constructor(driverService: DriverService, router: Router) {
        super(
            Resource.DRIVERS,
            driverService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DRIVERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DRIVERS),
            (entity?) => new Driver(entity),
            (entity) => entity.firstName + ' ' + entity.lastName,
            faTruck
        );
    }
}
