import {IUser} from 'app/core/user/user.model';
import {IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IAsyncRequest extends IBaseEntity {
    id?: number;
    user?: IUser;
    dataJson?: string;
    entityJson?: string;
    logJson?: string;
    operationName?: string;
    requestDateTime?: string;
    successCount?: number;
    failureCount?: number;
    totalCount?: number;
    inProgress?: boolean;
}

export class AsyncRequest implements IAsyncRequest {
    id?: number;
    user?: IUser;
    dataJson?: string;
    entityJson?: string;
    logJson?: string;
    operationName?: string;
    requestDateTime?: string;
    successCount?: number;
    failureCount?: number;
    totalCount?: number;
    inProgress?: boolean;

    constructor(asyncRequest?: any) {
        if (asyncRequest) {
            this.id = asyncRequest.id;
            this.user = asyncRequest.user;
            this.dataJson = asyncRequest.dataJson;
            this.entityJson = asyncRequest.entityJson;
            this.logJson = asyncRequest.logJson;
            this.operationName = asyncRequest.operationName;
            this.requestDateTime = asyncRequest.requestDateTime;
            this.successCount = asyncRequest.successCount;
            this.failureCount = asyncRequest.failureCount;
            this.totalCount = asyncRequest.totalCount;
            this.inProgress = asyncRequest.inProgress;
        }
    }
    discriminator?: any;
    toFormObject() {
        throw new Error('Method not implemented.');
    }
    clone(): IBaseEntity {
        throw new Error('Method not implemented.');
    }
}
