import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IProfile extends IBaseEntity {
    id?: number;
    profileName?: string;
}

export class Profile extends BaseEntity implements IProfile {
    id?: number;
    profileName?: string;

    constructor(role?) {
        super();
        if (role) {
            this.id = role.id;
            this.profileName = role.profileName;
        }
    }

    clone(): Profile {
        return new Profile(this);
    }
}
