import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {DiErrorNotificationComponent} from 'app/common/di-error-notification/di-error-notification.component';

@NgModule({
    declarations: [DiErrorNotificationComponent],
    imports: [CommonModule, TranslateModule, NzIconModule, NzNotificationModule],
    exports: [DiErrorNotificationComponent],
    providers: []
})
export class DiErrorNotificationModule {}
