import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IDbUser extends IBaseEntity {
    username: any;
}

export class DbUser extends BaseEntity implements IDbUser {
    username: any;

    constructor(dbuser?) {
        super();
        if (dbuser) {
            this.username = dbuser.username;
        }
    }

    clone(): DbUser {
        return new DbUser(this);
    }

    get discriminator(): any {
        return this.username;
    }
}
