import {tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {DialogService} from 'app/common/dialog/dialog-service';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {REGEX_GOOGLE_SHEET_URL} from 'app/app.constants';
import {ERROR_SEVERITY_TOKEN, ErrorSeverity} from 'app/blocks/util/request-util';
import {AlertService} from 'app/common/alert/alert.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private dialogService: DialogService,
        private alertService: AlertService
    ) {}

    readonly ignoreCases = [
        {url: 'traffic-areas-for-postcode-area', status: 404},
        {url: '/api/company', status: 404}
    ];

    modalRef: NzModalRef;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => {
                    if (err.status === 0 && err.error instanceof ProgressEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.log('Client side error:', err.error);
                        this.alertService.showWarning('common.networkErrorMessage', 'common.networkError', {
                            timeOut: 30000,
                            extendedTimeOut: 0,
                            closeButton: true,
                            tapToDismiss: false
                        });
                    } else if (err instanceof HttpErrorResponse) {
                        if (err.error instanceof Blob) {
                            // https://github.com/angular/angular/issues/19888
                            // When request of type Blob, the error is also in Blob instead of object of the json data
                            return new Promise<any>((resolve, reject) => {
                                let reader = new FileReader();
                                reader.onload = (e: Event) => {
                                    try {
                                        const err = JSON.parse((<any>e.target).result);
                                        this.dialogService
                                            .showMessage(
                                                {
                                                    message: err.detail ?? 'common.errorMessage',
                                                    title: err.title ?? 'common.error',
                                                    confirmText: 'common.ok'
                                                },
                                                {},
                                                undefined,
                                                this.modalRef
                                            )
                                            .then((ref) => {
                                                this.modalRef = ref;
                                            });
                                    } catch (e) {
                                        console.log(e);
                                        reject(err);
                                    }
                                };
                                reader.onerror = (e) => {
                                    console.log(e);
                                    reject(err);
                                };
                                reader.readAsText(err.error);
                            });
                        }
                        if (
                            !(
                                err.status === 401 ||
                                err.message === '' ||
                                (err.url &&
                                    (err.url.indexOf('/api/account') > 0 ||
                                        err.url.indexOf('/api/company/logo') > 0 ||
                                        err.url.indexOf('/LocalSetting') > 0)) ||
                                REGEX_GOOGLE_SHEET_URL.test(err.url)
                            )
                        ) {
                            console.log('[ErrorHandlerInterceptor] There was an http error: ', err);
                            console.log('URL', err.url);
                            const message: string = err.error?.detail ?? err.error?.message;
                            const title: string = err.error?.title;
                            const params = {};
                            if (err.url) {
                                for (const ignoreCase of this.ignoreCases) {
                                    if (err.url.includes(ignoreCase.url) && err.status === ignoreCase.status) {
                                        return;
                                    }
                                }
                            }
                            const errorSeverity = request.context?.get(ERROR_SEVERITY_TOKEN);

                            if (errorSeverity === ErrorSeverity.ERROR) {
                                this.dialogService
                                    .showMessage(
                                        {
                                            message: message ?? 'common.errorMessage',
                                            title: title ?? 'common.error',
                                            confirmText: 'common.ok'
                                        },
                                        params,
                                        undefined,
                                        this.modalRef
                                    )
                                    .then((ref) => {
                                        this.modalRef = ref;
                                    });
                            } else if (errorSeverity === ErrorSeverity.WARN) {
                                this.alertService.showWarning(message ?? 'common.backgroundErrorMessage', title ?? 'common.backgroundError', {
                                    timeOut: 30000,
                                    extendedTimeOut: 0,
                                    closeButton: true,
                                    tapToDismiss: false
                                });
                            } else if (errorSeverity === ErrorSeverity.IGNORE) {
                                return;
                            }
                        }
                    }
                }
            )
        );
    }
}
