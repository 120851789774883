import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {DiPageTotalItemsComponent} from 'app/common/di-page-total-items/di-page-total-items.component';

@NgModule({
    declarations: [DiPageTotalItemsComponent],
    imports: [CommonModule, TranslateModule],
    exports: [DiPageTotalItemsComponent],
    providers: []
})
export class DiPageTotalItemsModule {}
