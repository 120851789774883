import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';
import {FilterSettingService} from 'app/blocks/service/api/filter-setting.service';
import {HaulierOrderService} from 'app/blocks/service/api/haulier-order.service';
import {Resource} from 'app/constants/resource';
import * as _ from 'lodash';
import {GetPlanningOrders, UpdatePlanningOrdersSelectedIds} from 'app/blocks/store/actions/planning-orders-actions';
import {getDefaultPlanningOrdersState, PlanningOrdersStateModel} from 'app/blocks/store/state-model/planning-orders-state-model';
import {DiDate} from 'app/blocks/util/di-date';

@State<PlanningOrdersStateModel>({
    name: Resource.PLANNING_ORDERS.valueOf(),
    defaults: getDefaultPlanningOrdersState()
})
@Injectable({
    providedIn: 'root'
})
export class PlanningOrdersState {
    constructor(
        private _service: HaulierOrderService,
        private _filterSettingService: FilterSettingService
    ) {}

    readonly statusesForPlanning: string[] = ['PENDING', 'APPROVED', 'AWAITING_APPROVAL'];

    @Action(GetPlanningOrders)
    async getOrdersForPlanning(ctx: StateContext<PlanningOrdersStateModel>, action: GetPlanningOrders): Promise<any[]> {
        let newFilters;

        let prevFilters;

        // eslint-disable-next-line prefer-const
        prevFilters = ctx.getState() ? ctx.getState().listView.filters : getDefaultPlanningOrdersState().listView.filters;
        // eslint-disable-next-line prefer-const

        newFilters = action.filters ?? prevFilters;

        if (action.primaryFilter === 'COLLECTIONS_FOR_PLANNING') {
            prevFilters.dateFilterOn = ctx.getState() ? ctx.getState().listView.collectionsDateFilterOn : null;
            newFilters.dateFilterOn =
                action.collectionsDateFilterOn ??
                prevFilters.collectionsDateFilterOn ??
                getDefaultPlanningOrdersState().listView.collectionsDateFilterOn;
        } else {
            prevFilters.dateFilterOn = ctx.getState() ? ctx.getState().listView.deliveriesDateFilterOn : null;
            newFilters.dateFilterOn =
                action.deliveriesDateFilterOn ??
                prevFilters.deliveriesDateFilterOn ??
                getDefaultPlanningOrdersState().listView.deliveriesDateFilterOn;
        }

        // currently we have a requirement to not save the filters
        // leaving the below part commented in case this changes later on
        //     if (!newFilters) {
        // const filtersApi: (
        //     request: any
        // ) => Promise<HttpResponse<any[]>> = this._filterSettingService
        //     .getFilterSettings;
        // newFilters = await this._fetchOrderFilters(ctx, filtersApi);
        // newFilters = {dateFromFilter: DiDate.currentDate(), dateToFilter: DiDate.currentDate(), dateFilterOn: 'ALL'};
        // }

        const prevSearchTerm = ctx.getState() ? ctx.getState().listView.searchTerm : null;
        const searchTerm = action.searchTerm || action.searchTerm === '' ? action.searchTerm : prevSearchTerm;
        const api = this._service.getOrdersForPlanning;
        let requiresDataRefresh;

        if (!_.isEqual(prevFilters, newFilters) || prevSearchTerm !== searchTerm) {
            this.updateStateForDataRefreshRequired(ctx);
            requiresDataRefresh = true;
        } else {
            requiresDataRefresh = ctx.getState()
                ? (action.primaryFilter === 'COLLECTIONS_FOR_PLANNING' && ctx.getState().listView.collectionsRequireRefresh) ||
                  (action.primaryFilter === 'DELIVERIES_FOR_PLANNING' && ctx.getState().listView.deliveriesRequireRefresh)
                : true;
        }

        if (action.forceRefresh || requiresDataRefresh) {
            return this._getOrdersForPlanning(ctx, api, newFilters, searchTerm, action.primaryFilter);
        }
    }

    @Action(UpdatePlanningOrdersSelectedIds)
    async updateSelectedIds(ctx: StateContext<PlanningOrdersStateModel>, action: UpdatePlanningOrdersSelectedIds): Promise<any> {
        const currentListView = ctx.getState() ? ctx.getState().listView : getDefaultPlanningOrdersState().listView;

        ctx.setState({
            listView: {
                ...currentListView,
                selectedIds: action.ids
            }
        });
    }

    async updateStateForDataRefreshRequired(ctx: StateContext<PlanningOrdersStateModel>): Promise<any> {
        const currentListView = ctx.getState() ? ctx.getState().listView : getDefaultPlanningOrdersState().listView;

        ctx.setState({
            listView: {
                ...currentListView,
                deliveriesRequireRefresh: true,
                collectionsRequireRefresh: true
            }
        });
    }

    private async _getOrdersForPlanning(
        ctx: StateContext<PlanningOrdersStateModel>,
        api: (req: any) => Promise<HttpResponse<IHaulierOrder[]>>,
        filters: any,
        query?: string,
        primaryFilter?: string
    ) {
        const currentListView = ctx.getState() ? ctx.getState().listView : getDefaultPlanningOrdersState().listView;

        ctx.setState({
            listView: {
                ...currentListView,
                collectionsLoading: primaryFilter === 'COLLECTIONS_FOR_PLANNING' ? true : currentListView.collectionsLoading,
                deliveriesLoading: primaryFilter === 'DELIVERIES_FOR_PLANNING' ? true : currentListView.deliveriesLoading,
                searchTerm: query
            }
        });

        const req = {primaryFilter: primaryFilter, searchValue: query};
        Object.entries(filters).forEach((entry) => {
            if (entry[1]) {
                req[entry[0]] = entry[1];
            }
        });

        const res = await api(req);
        const orders = res.body;

        this._updatePlanningOrdersState(ctx, orders, filters, primaryFilter, query);
        return orders;
    }

    private hasId(orders: IHaulierOrder[], id: number): boolean {
        return orders.map((order) => order.id).includes(id);
    }

    private _updatePlanningOrdersState(
        ctx: StateContext<PlanningOrdersStateModel>,
        orders: any[],
        filters: any,
        primaryFilter: string,
        searchTerm?: string
    ): void {
        const currentListView = ctx.getState() ? ctx.getState().listView : getDefaultPlanningOrdersState().listView;

        const collections: IHaulierOrder[] = primaryFilter === 'COLLECTIONS_FOR_PLANNING' ? orders : currentListView.orders.collections;
        const deliveries: IHaulierOrder[] = primaryFilter === 'DELIVERIES_FOR_PLANNING' ? orders : currentListView.orders.deliveries;

        const selectedIds: number[] = currentListView.selectedIds.filter((id) => this.hasId(collections, id) || this.hasId(deliveries, id));
        ctx.setState({
            listView: {
                ...currentListView,
                initialized: true,
                orders: {
                    collections,
                    deliveries
                },
                searchTerm,
                collectionsLoading: primaryFilter === 'COLLECTIONS_FOR_PLANNING' ? false : currentListView.collectionsLoading,
                deliveriesLoading: primaryFilter === 'DELIVERIES_FOR_PLANNING' ? false : currentListView.deliveriesLoading,
                collectionsDateFilterOn:
                    primaryFilter === 'COLLECTIONS_FOR_PLANNING' ? filters.dateFilterOn : currentListView.collectionsDateFilterOn,
                deliveriesDateFilterOn: primaryFilter === 'DELIVERIES_FOR_PLANNING' ? filters.dateFilterOn : currentListView.deliveriesDateFilterOn,
                filters: filters,
                selectedIds,
                collectionsRequireRefresh: primaryFilter === 'COLLECTIONS_FOR_PLANNING' ? false : currentListView.collectionsRequireRefresh,
                deliveriesRequireRefresh: primaryFilter === 'DELIVERIES_FOR_PLANNING' ? false : currentListView.deliveriesRequireRefresh
            }
        });
    }

    private async _fetchOrderFilters(ctx: StateContext<PlanningOrdersStateModel>, api: (request: any) => Promise<HttpResponse<any[]>>): Promise<any> {
        const res = await api(Resource.HAULIER_ORDERS);
        const filters = {};

        if (res.body && res.body.length) {
            res.body.forEach((filter) => (filters[filter.filter] = filter.value));
        }
        return filters;
    }
}
