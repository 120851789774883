import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ICurrency} from 'app/blocks/model/currency.model';
import {CompanyService} from 'app/blocks/service/api/company.service';
import {GetConfig, GetTenantSettings, TrackAsyncRequest} from 'app/blocks/store/actions/common-actions';
import produce from 'immer';
import {ASYNC_TASKS, TENANT_SETTINGS} from 'app/app.constants';
import {COMPANY_SETTINGS} from 'app/app.constants';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {AccountService} from 'app/core/auth/account.service';
import {RoleService} from 'app/blocks/service/api/role.service';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {CommonUtilsService} from 'app/common/common-utils-service';
import {AsyncRequestService} from 'app/blocks/service/api/async_request.service';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SettingService} from 'app/blocks/service/api/settings.service';
import {ITenantSettings, TenantSettings} from 'app/blocks/model/tenant-setting.model';
import {CurrencyService} from 'app/blocks/service/api/currency.service';

export interface CommonStateModel {
    [COMPANY_SETTINGS]: any;
    [ASYNC_TASKS]: any;
    [TENANT_SETTINGS]: ITenantSettings;
}

export function getDefaultCommonState(): CommonStateModel {
    return {
        [COMPANY_SETTINGS]: null,
        [ASYNC_TASKS]: null,
        [TENANT_SETTINGS]: {
            tenantSettings: null,
            loaded: false
        }
    };
}

@State<CommonStateModel>({
    name: 'common',
    defaults: getDefaultCommonState()
})
@Injectable({
    providedIn: 'root'
})
export class CommonState {
    isSuperuser = false;
    constructor(
        private _accountService: AccountService,
        private superUserService: SuperUserService,
        private roleService: RoleService,
        private commonUtilsService: CommonUtilsService,
        private asyncRequestService: AsyncRequestService,
        private _settingService: SettingService,
        private _currencyService: CurrencyService,
        private _companyService: CompanyService
    ) {}

    private destroy$ = new Subject<void>();

    @Action(TrackAsyncRequest)
    async getAyncRequestInProgress(ctx: StateContext<CommonStateModel>, action: TrackAsyncRequest): Promise<void> {
        localStorage.setItem('bulkActionStarted', String(true));
        timer(0, 5000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (localStorage.getItem('bulkActionStarted') == 'true' && !this.isSuperuser) {
                    this.asyncRequestService.getInProgessAsyncRequest().then((result) => {
                        if (result.body.length <= 0) {
                            localStorage.setItem('bulkActionStarted', String(false));
                        }
                        ctx.setState(
                            produce(ctx.getState(), (draft) => {
                                draft[ASYNC_TASKS] = result.body;
                            })
                        );
                    });
                } else {
                    this.destroy$.next();
                    this.destroy$.complete();
                }
            });
    }

    @Action(GetConfig)
    async getConfigForCompany(ctx: StateContext<CommonStateModel>, action: GetConfig): Promise<void> {
        this.isSuperuser = this.superUserService.getIsSuperUser();

        if (this.commonUtilsService.getToken()) {
            ctx.setState(produce(ctx.getState(), (draft) => {}));
        }
    }

    @Action(GetTenantSettings)
    async getAllTenantSettings(ctx: StateContext<CommonStateModel>, action: GetTenantSettings): Promise<void> {
        //super users don't need tenant settings
        if (this.superUserService.getIsSuperUser()) {
            if (ctx.getState()[TENANT_SETTINGS].loaded) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft[TENANT_SETTINGS].loaded = true;
                    })
                );
            }

            return;
        }

        if (ctx.getState()[TENANT_SETTINGS].loaded) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    draft[TENANT_SETTINGS].loaded = false;
                })
            );
        }

        const tenantSettings = await this._settingService.getAllTenantSettingsFromServer();

        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[TENANT_SETTINGS].tenantSettings = tenantSettings;
                draft[TENANT_SETTINGS].loaded = true;
            })
        );
    }
}
