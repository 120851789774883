import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IAdHocLine extends IBaseEntity {
    id?: number;
    amount: number;
    description: string;
}

export class AdHocLine extends BaseEntity implements IAdHocLine {
    public id?: number;
    public amount: number;
    public description: string;

    constructor(adHocLine?: any) {
        super();
        if (adHocLine) {
            this.id = adHocLine.id;
            this.amount = adHocLine.amount;
            this.description = adHocLine.description;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): AdHocLine {
        return new AdHocLine(this);
    }
}
