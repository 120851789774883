import {DiDate} from 'app/blocks/util/di-date';
import {DiTime} from 'app/blocks/util/di-time';

export interface IBaseEntity {
    id?: number;
    discriminator?: any;

    toFormObject(): any;
    clone(): IBaseEntity;
}

export abstract class BaseEntity implements IBaseEntity {
    get discriminator(): any {
        return this['id'];
    }

    toFormObject(): any {
        for (const key of Object.keys(this)) {
            if (this.hasOwnProperty(key)) {
                if (this[key] instanceof DiDate) {
                    this[key] = (this[key] as DiDate).asDate();
                } else if (this[key] instanceof DiTime) {
                    this[key] = (this[key] as DiTime).asDate();
                } else if (Array.isArray(this[key])) {
                    const newArray = [];
                    this[key].forEach((obj) => {
                        if (obj instanceof DiDate) {
                            newArray.push(obj.asDate());
                        } else if (obj instanceof DiTime) {
                            newArray.push(obj.asDate());
                        } else if (obj instanceof BaseEntity) {
                            newArray.push(obj.toFormObject());
                        } else {
                            newArray.push(obj);
                        }
                    });
                    this[key] = newArray;
                } else if (this[key] instanceof BaseEntity) {
                    this[key] = (this[key] as BaseEntity).toFormObject();
                }
            }
        }
        return this;
    }

    abstract clone(): IBaseEntity;
}
