import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ITaxCategory, TaxCategory} from 'app/blocks/model/tax-category.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class TaxCategoryService extends EntityCrudService<ITaxCategory> {
    constructor(http: HttpClient) {
        super(http, Resource.UK_VAT_RATES, (obj?) => new TaxCategory(obj));
    }

    getTaxRateForType(defaultVatType: any): Observable<any> {
        const params = new HttpParams().set('type', defaultVatType);
        return this.http.get(SERVER_API_URL + 'api/vat-rates', {
            params: params
        });
    }

    getTaxRatesForType(defaultVatType: any): Observable<any[]> {
        const params = new HttpParams().set('type', defaultVatType);
        return this.http.get<any[]>(SERVER_API_URL + 'api/vat-rates/all', {
            params: params
        });
    }

    getTaxRateByCustomerId(customerId: any): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/vat-rates/' + customerId);
    }
}
