import {ENTITIES_TARIFFS_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faTasks} from '@fortawesome/free-solid-svg-icons';
import {ServiceLevelService} from 'app/blocks/service/api/service-level.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IServiceLevel, ServiceLevel} from 'app/blocks/model/service-level.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ServiceLevelResource extends AbstractEntityResource<IServiceLevel> {
    constructor(serviceLevelService: ServiceLevelService, router: Router) {
        super(
            Resource.SERVICE_LEVELS,
            serviceLevelService,
            new EntityNavigator(ENTITIES_TARIFFS_BASE_URL, Resource.SERVICE_LEVELS, router),
            new EntityUrlProvider(ENTITIES_TARIFFS_BASE_URL, Resource.SERVICE_LEVELS),
            (entity?) => new ServiceLevel(entity),
            (entity) => entity.shortCode,
            faTasks
        );
    }
}
