import {ENTITIES_TARIFFS_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {ExtraTypeService} from 'app/blocks/service/api/extra-type.service';
import {Resource} from 'app/constants/resource';

import {IExtraType, ExtraType} from 'app/blocks/model/extra-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ExtraTypeResource extends AbstractEntityResource<IExtraType> {
    constructor(extraTypeService: ExtraTypeService, router: Router) {
        super(
            Resource.EXTRA_TYPES,
            extraTypeService,
            new EntityNavigator(ENTITIES_TARIFFS_BASE_URL, Resource.EXTRA_TYPES, router),
            new EntityUrlProvider(ENTITIES_TARIFFS_BASE_URL, Resource.EXTRA_TYPES),
            (entity?) => new ExtraType(entity),
            (entity) => entity.id,
            faStar
        );
    }

    getEntityDiscriminator(entity: ExtraType): any {
        return entity.id?.toString() + (entity.description ? ' - ' + entity.description : '');
    }
}
