import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {StateStorageService} from 'app/core/auth/state-storage.service';
import {Principal} from 'app/core/auth/principal.service';

import {Store} from '@ngxs/store';
import {GetConfig} from 'app/blocks/store/actions/common-actions';

@Injectable()
export class CustomerRouteAccessService {
    constructor(
        private store: Store,
        private router: Router,
        private principal: Principal,
        private stateStorageService: StateStorageService
    ) {
        this.store.dispatch(new GetConfig());
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        return this.principal.hasAuthority('ROLE_CUSTOMER').then((response) => {
            if (response) {
                this.router.navigate(['/orders/haulier-orders/dashboard']);
                return false;
            }
            return true;
        });
    }
}
