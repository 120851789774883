import {DbPool, IDbPool} from 'app/blocks/model/db-pool.model';
import {ITenantDetails, TenantDetails} from 'app/blocks/model/tenant-details.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ITenantAccessLog, TenantAccessLog} from 'app/blocks/model/tenant-access-log.model';
import {ITenantDbMigration, TenantDbMigration} from 'app/blocks/model/tenant-db-migration.model';

export interface ITenant extends IBaseEntity {
    id?: number;
    name?: string;
    initialize?: boolean;
    dbPool?: IDbPool;
    tenantDetails?: ITenantDetails;
    tenantAccessLog?: ITenantAccessLog;
    tenantDbMigration?: ITenantDbMigration;
}

export class Tenant extends BaseEntity implements ITenant {
    id?: number;
    name?: string;
    initialize?: boolean;
    dbPool?: DbPool;
    tenantDetails?: TenantDetails;
    tenantAccessLog?: TenantAccessLog;
    tenantDbMigration?: TenantDbMigration;

    constructor(tenant?) {
        super();
        if (tenant) {
            this.id = tenant.id;
            this.name = tenant.name;
            this.initialize = tenant.initialize;
            this.dbPool = tenant.dbPool;
            this.tenantDetails = tenant.tenantDetails;
            this.tenantAccessLog = tenant.tenantAccessLog;
            this.tenantDbMigration = tenant.tenantDbMigration;
        }
    }

    clone(): Tenant {
        return new Tenant(this);
    }

    get discriminator(): any {
        return this.id;
    }
}
