export const locale = {
    lang: 'fr',
    data: {
        error: {
            validation: 'fr Validation error',
            userexists: 'fr Employee already exists'
        },
        common: {
            search: 'Rechercher',
            confirmation: 'Confirmation',
            yes: 'Oui',
            no: 'Non',
            error: 'fr Error',
            ok: 'fr OK',
            errorMessage: 'fr Internal server error. Please contact to the administrator.',
            confirmDelete: {
                message: 'Êtes-vous sûr de vouloir supprimer {{ discriminator }}?'
            },
            sentPdfEmails: 'fr PDF Emails have been sent to recipients.',
            listActions: 'fr Actions',
            noSelectedColumn: 'fr Please, select at least one column to be displayed.',
            noSelectedOrder: 'Please, select at least one order before creating a Run',
            haulierOrderDatesError: 'Delivery window is set before Collection Window',
            forms: {
                validationMessages: {
                    required: {
                        default: 'Le champ {{_fieldName}} est requise'
                    },
                    floatRange: {
                        default: '{{_the}} {{_fieldName}} devrait être comprise entre {{min}} et {{max}}'
                    },
                    maxlength: {
                        default: '{{_the}} {{_fieldName}} peut avoir un maximum de {{requiredLength}} caractères'
                    },
                    minlength: {
                        default: '{{_the}} {{_fieldName}} doit comporter au moins {{requiredLength}} caractères'
                    },
                    min: {
                        default: '{{_the}} {{_fieldName}} doit être supérieur à {{min}}'
                    },
                    max: {
                        default: '{{_the}} {{_fieldName}} doit être au plus {{max}}'
                    },
                    formValid: {
                        default: 'You need to fill in all mandatory fields with valid value, before this can be saved. {{invalidFields}}'
                    },
                    latitudeLongitudenotEqualToZero: {
                        default: 'Latitude and Longitude must not be equal to zero simultaneously'
                    },
                    collectionTimeShouldBeBeforeDeliveryTime: {
                        default: 'Collection End Time should be before Delivery Start Time'
                    },
                    errorFields: {
                        default: 'There are errors in the following fields - please correct before attempting to save: {{_fieldList}}'
                    },
                    confirmPassword: {
                        default: 'fr Confirm password and password not equal'
                    },
                    email: {
                        default: 'Invalid email'
                    },
                    timeRange: {
                        default: 'Invalid time window'
                    },
                    dateTimePast: {
                        default: 'fr Date-time is in the past'
                    },
                    maxWeight: {
                        default: 'Invalid weight'
                    },
                    pastDate: {
                        default: "Date can't be set on the past"
                    },
                    sameCurrency: {
                        default: 'fr Currencies cannot be the same'
                    },
                    rate: {
                        default: 'Rate cannot be blank or Zero if Tariff Override is not set'
                    },
                    noMatchError: {
                        default: "Password doesn't match."
                    },
                    collectionInThePast: {
                        default: "Collection date can't be set in the past"
                    },
                    deliverBeforeCollection: {
                        default: "Order can't be delivered before collected"
                    }
                },
                unsavedChangesWarning: {
                    default: 'You have unsaved changes, are you sure you want to continue?'
                }
            },
            fields: {
                name: 'Name',
                accountNo: 'Account Number',
                vatRegNo: 'VAT Registration Number',
                website: 'Website URL',
                registrationNo: 'Registration Number',
                currencyId: 'Currency',
                invoiceTemplateId: 'Invoice Template',
                line1: 'Line 1',
                townCity: 'Town',
                countyState: 'County',
                postCodeZip: 'Post Code'
            },
            selection: {
                selectAll: 'Tout',
                selectNone: 'Aucun'
            },
            genderWords: {
                m: {
                    _a: 'Un',
                    _the: 'Le'
                },
                f: {
                    _a: 'Une',
                    _the: 'La'
                }
            }
        }
    }
};
