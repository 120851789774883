import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {DriverSubType, IDriverSubType} from 'app/blocks/model/driver-sub-type.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {DriverSubTypeService} from 'app/blocks/service/api/driver-sub-type.service';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DriverSubTypeResource extends AbstractEntityResource<IDriverSubType> {
    constructor(DriverSubTypeService: DriverSubTypeService, router: Router) {
        super(
            Resource.DRIVER_SUB_TYPES,
            DriverSubTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DRIVER_SUB_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DRIVER_SUB_TYPES),
            (entity?) => new DriverSubType(entity),
            (entity) => entity
        );
    }
}
