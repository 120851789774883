import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {DiTime} from 'app/blocks/util/di-time';
import {DiDate} from 'app/blocks/util/di-date';

export interface ITimeWindow extends IBaseEntity {
    id?: number;
    startTime?: any;
    endTime?: any;
    startDate?: any;
    endDate?: any;
    dayInterval?: number;
    priority?: number;
}

export class TimeWindow extends BaseEntity implements ITimeWindow {
    public id?: number;
    public startTime?: any;
    public endTime?: any;
    public startDate?: any;
    public endDate?: any;
    public dayInterval?: number;
    public priority?: number;

    static processArray(obj: TimeWindow[]): TimeWindow[] {
        if (obj) {
            const result = [];
            obj.forEach((o) => result.push(new TimeWindow(o)));
            return result;
        }
        return null;
    }

    constructor(timeWindow?) {
        super();
        if (timeWindow) {
            this.id = timeWindow.id;
            this.startTime = DiTime.newInstance(timeWindow.startTime);
            this.endTime = DiTime.newInstance(timeWindow.endTime);
            this.dayInterval = timeWindow.dayInterval;
            this.priority = timeWindow.priority;
            this.startDate = DiDate.newInstance(timeWindow.startDate);
            this.endDate = DiDate.newInstance(timeWindow.endDate);
        } else {
            this.dayInterval = 0;
            this.priority = 0;
        }
    }

    get discriminator(): any {
        return this.priority;
    }

    clone(): TimeWindow {
        return new TimeWindow(this);
    }
}
