import {ENTITIES_TARIFFS_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {PriceListService} from 'app/blocks/service/api/price-list.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IPriceList, PriceList} from 'app/blocks/model/price-list.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class PriceListResource extends AbstractEntityResource<IPriceList> {
    constructor(priceListService: PriceListService, router: Router) {
        super(
            Resource.PRICE_LISTS,
            priceListService,
            new EntityNavigator(ENTITIES_TARIFFS_BASE_URL, Resource.PRICE_LISTS, router),
            new EntityUrlProvider(ENTITIES_TARIFFS_BASE_URL, Resource.PRICE_LISTS),
            (entity?) => new PriceList(entity),
            (entity) => entity.id,
            faStar
        );
    }
}
