import {FuseNavigation} from '@fuse/types';

export const userFeature: FuseNavigation[] = [
    {
        id: 'features',
        title: 'Features',
        type: 'group',
        children: [
            {
                id: 'driver-app-access',
                title: 'Driver App Access',
                type: 'group',
                children: []
            },
            {
                id: 'invoice-approveAndPost-toggle',
                title: 'Invoice Approve And Post',
                type: 'group',
                children: []
            },
            {
                id: 'driver-emergencyContact-access',
                title: 'Driver Emergency Contact',
                type: 'group',
                children: []
            },
            {
                id: 'organisations-feature',
                title: 'Organisations',
                type: 'group',
                children: [
                    {
                        id: 'organisations-delete-toggle',
                        title: 'Toggle Delete Access',
                        type: 'item',
                        children: []
                    },
                    {
                        id: 'organisations-edit-toggle',
                        title: 'Toggle Edit Access',
                        type: 'item',
                        children: []
                    }
                ]
            },
            {
                id: 'driver-strikes-feature',
                title: 'Drvier Strikes',
                type: 'group',
                children: [
                    {
                        id: 'driver-strikes-remove-toggle',
                        title: 'Toggle Remove Driver Strike',
                        type: 'item',
                        children: []
                    },
                    {
                        id: 'driver-strikes-add-toggle',
                        title: 'Toggle Add Driver Strike',
                        type: 'item',
                        children: []
                    },
                    {
                        id: 'driver-strikes-view-toggle',
                        title: 'Toggle View Driver Strike',
                        type: 'item',
                        children: []
                    }
                ]
            },
            {
                id: 'order-feature',
                title: 'Order',
                type: 'group',
                children: [
                    {
                        id: 'haulierOrders-edit-toggle',
                        title: 'Delivered Order Edit Access',
                        type: 'item',
                        children: []
                    }
                ]
            }
        ]
    }
];
