import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IShiftType extends IBaseEntity {
    id?: number;
    name?: string;
    colour?: string;
}

export class ShiftType extends BaseEntity implements IShiftType {
    public id?: number;
    public name?: string;
    public colour?: string;

    constructor(shiftType?: any) {
        super();
        if (shiftType) {
            this.id = shiftType.id;
            this.name = shiftType.name;
            this.colour = shiftType.colour;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ShiftType {
        return new ShiftType(this);
    }
}
