export const processArray = (obj: any[], entityCreator: (o) => any): any[] => {
    if (obj) {
        const result = [];
        obj.forEach((o) => result.push(entityCreator(o)));
        return result;
    }
    return [];
};

import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {floatRangeValidator, startEndTimeValidator} from 'app/blocks/validation/validators';

export function customStatementAddressValidator(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        vatRegNo: [, [Validators.maxLength(20)]],
        registrationNo: [, [Validators.maxLength(25)]],
        headerLogo: imageValidator(_formBuilder),
        contactInfo: contactInfoValidator(_formBuilder)
    });
}

export function imageValidator(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        name: ['', [Validators.maxLength(50)]],
        path: ['', [Validators.maxLength(255)]]
    });
}

export function bankingInfoValidator(_formBuilder: UntypedFormBuilder, hideName = false): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        name: [, [Validators.maxLength(100), Validators.required]],
        accountNo: [, [Validators.maxLength(25), Validators.required]],
        beneficiaryName: [, [Validators.maxLength(100), Validators.required]],
        contactInfo: contactInfoValidator(_formBuilder),
        currency: [, [Validators.required]],
        iban: [, [Validators.maxLength(34), Validators.required]],
        swiftCode: [, [Validators.maxLength(11), Validators.required]]
    });
}

export function contactInfoValidator(_formBuilder: UntypedFormBuilder, hideName = false): UntypedFormGroup {
    const nameValidators = [Validators.minLength(3), Validators.maxLength(50)];
    if (!hideName) {
        nameValidators.push(Validators.required);
    }

    return _formBuilder.group({
        id: [],
        name: [, nameValidators],
        phone: [, Validators.maxLength(20)],
        phoneExt: [, Validators.maxLength(10)],
        workPhone: [, Validators.maxLength(20)],
        workPhoneExt: [, Validators.maxLength(10)],
        fax: [, Validators.maxLength(20)],
        email: [, [Validators.maxLength(100)]],
        address: addressValidator(_formBuilder)
    });
}

export function addressValidator(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        line1: [, [Validators.minLength(1), Validators.maxLength(150), Validators.required]],
        line2: [, [Validators.minLength(2), Validators.maxLength(150)]],
        townCity: [, [Validators.maxLength(30), Validators.required]],
        countyState: [, [Validators.maxLength(30), Validators.required]],
        postCodeZip: [, [Validators.maxLength(20), Validators.required]],
        latitude: [, [floatRangeValidator(-90, 90)]],
        longitude: [, [floatRangeValidator(-180, 180)]],
        country: []
    });
}

export function locationValidator(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        description: [],
        underReview: [],
        residential: [],
        contactInfo: contactInfoValidator(_formBuilder),
        notes: createNote(_formBuilder)
    });
}

export function createNote(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        note: [],
        noteDateTime: []
    });
}

export function countryValidator(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        code: ['', [Validators.minLength(3), Validators.maxLength(5), Validators.required]],
        name: ['', [Validators.maxLength(25), Validators.required]]
    });
}

export function createTimeWindowFormValidator(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group(
        {
            id: [],
            startTime: [, [Validators.required]],
            endTime: [, [Validators.required]],
            dayInterval: [0],
            priority: [, [Validators.min(0)]]
        },
        {
            validator: startEndTimeValidator('startTime', 'endTime')
        }
    );
}

export function createFuelSurcharge(_formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return _formBuilder.group({
        id: [],
        effectiveFromDate: [],
        baseFuelPrice: [],
        currentFuelPrice: [],
        proportionOfCostBase: [],
        percentage: [],
        useFlatRate: [],
        flatRate: [],
        deleted: [],
        calculatePercentage: [],
        fuelSurchargeGroupId: []
    });
}
