import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {ILateReason, LateReason} from 'app/blocks/model/late-reason.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {LateReasonService} from 'app/blocks/service/api/late-reason.service';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class LateReasonResource extends AbstractEntityResource<ILateReason> {
    constructor(lateReasonService: LateReasonService, router: Router) {
        super(
            Resource.LATE_REASONS,
            lateReasonService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.LATE_REASONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.LATE_REASONS),
            (entity?) => new LateReason(entity),
            (entity) => entity
        );
    }
}
