export class TenantSettingKeys {
    static readonly TARIFF_SETTINGS = 'tariffSettings';
    static readonly DELETE_INACTIVE_RESOURCE = 'deleteInactiveResource';
    static readonly ENABLE_AUTO_INVOICING = 'enableAutoInvoicing';
    static readonly ENABLE_DRIVER_AVAILABLE_NOTIFICATIONS = 'enableDriverAvailableNotifications';
    static readonly NO_MINS_CONSIDERED_AVAILABLE = 'noMinsConsideredAvailable';
    static readonly ENABLE_VEHICLE_AVAILABLE_CHECKS = 'enableVehicleAvailableChecks';
    static readonly ENABLE_ANALYTICS_IN_HOME = 'enableAnalyticsInHome';
    static readonly ANALYTIC_SURL = 'analyticsUrl';
    static readonly ENABLE_QUOTE_MANAGEMENT = 'enableQuoteManagement';
    static readonly ENABLE_ANYTIME_ON_ORDER = 'enableAnytimeOnOrder';
    static readonly ENABLE_PAST_ORDER_CREATION = 'enablePastOrderCreation';
    static readonly ENABLE_AUDIT_LOGGING = 'enableAuditLogging';
    static readonly ENABLE_CALL_IN_AUDIT = 'enableCallInAudit';
    static readonly ENABLE_ORDER_AUDIT = 'enableOrderAudit';
    static readonly ENABLE_INVOICE_AUDIT = 'enableInvoiceAudit';
    static readonly EXPIRY_EMAIL_NOTIFICATIONS = 'expiryEmailNotifications';
    static readonly ENABLE_AUTO_APPROVING = 'enableAutoApproving';
    static readonly ENABLE_AUTO_CALLING_IN = 'enableAutoCallingIn';
    static readonly AUTO_CALL_IN_MINS = 'autoCallInMins';
    static readonly HAULIER_MAGIC_SUPPORT_EMAIL = 'haulierMagicSupportEmail';
    static readonly DEFAULT_LENGTH_OF_DRIVER_DAY = 'defaultLengthOfDriverDay';
    static readonly ENABLE_RUN_AUDIT = 'enableRunAudit';
    static readonly ENABLE_EXTRA_QUANTITY = 'enableExtraQuantity';
    static readonly ENABLE_VEHICLE_TYPE_RATING = 'enableVehicleTypeRating';
    static readonly ENABLE_PREINVOICE_BATCH_GROUP_BY_BT = 'enablePreInvoiceBatchGroupByBT';
    static readonly SHOW_CUSTOM_GOOD_TYPES_ON_LEGPLANNER = 'showCustomGoodTypesOnLegPlanner';
    static readonly ENABLE_LOCATION_SWAP = 'enableLocationSwap';
    static readonly MAX_PREINVOICE_LIMIT = 'maxPreInvoiceLimit';
    static readonly ENABLE_DEFAULT_COUNTRY = 'enableDefaultCountry';
    static readonly HONOR_DELIVERY_TIME = 'honorDeliveryTime';
    static readonly MANIFEST_INSTRUCTION = 'manifestInstruction';
    static readonly ENABLE_BATCH_LEG_POINT_UPDATE = 'enableBatchLegPointUpdate';
    static readonly SHOW_CUSTOMER_ACCOUNT_IN_DROPDOWN = 'showCustomerAccountInDropdown';
    static readonly USE_STANDARDISED_INVOICE_NO = 'useStandardisedInvoiceNo';
    static readonly SHOW_INVOICE_INFO_ON_ORDER = 'showInvoiceInfoOnOrder';
    static readonly SHOW_LINKED_ORDER_ON_ONE_LINER = 'showLinkedOrderOnOneLiner';
    static readonly INVOICE_PREFIX = 'invoicePrefix';
    static readonly CONSOLIDATED_INVOICE_PREFIX = 'consolidatedInvoicePrefix';
    static readonly INVOICE_SUFFIX_AMOUNT = 'invoiceSuffixAmount';
    static readonly MAIL_TENANT_ON_INVOICE = 'mailTenantOnInvoice';
    static readonly ENABLE_QUICK_QUOTE_CONVERSION = 'enableQuickQuoteConversion';
    static readonly ENABLE_MANIFEST_DRIVER_GROUPING = 'enableManifestDriverGrouping';
    static readonly ENABLE_RESOURCE_ICONS = 'enableResourceIcons';
    static readonly OPEN_AT_TYPE = 'openAtType';
}
