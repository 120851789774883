import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IVatType, VatType} from 'app/blocks/model/vat-type-model';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VatTypeService extends EntityCrudService<IVatType> {
    constructor(http: HttpClient) {
        super(http, Resource.VAT_TYPE, (obj?) => new VatType(obj));
    }

    findActive(vatTypeId): Promise<any> {
        return this.http.get(SERVER_API_URL + 'api/vat-types/' + vatTypeId + '/active').toPromise();
    }
}
