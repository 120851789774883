import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IResourceIcon, ResourceIcon} from 'app/blocks/model/resource-icon.model';
import {Encode} from 'app/blocks/util/encode';
import {LegPlannerEvent} from 'app/blocks/model/leg-planner-event.model';
import {Store} from '@ngxs/store';
import {TENANT_SETTINGS} from 'app/app.constants';
import {ITenantSettings, TenantSetting} from 'app/blocks/model/tenant-setting.model';
import {TenantSettingKeys} from 'app/constants/tenant-settings';
import {BehaviorSubject, Observable} from 'rxjs';
import {SettingService} from 'app/blocks/service/api/settings.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceIconService extends EntityCrudService<IResourceIcon> {
    private _resourceIconEnabled = false;

    constructor(
        http: HttpClient,
        private _store: Store,
        private _settingService: SettingService
    ) {
        super(http, Resource.RESOURCE_ICONS, (obj?) => new ResourceIcon(obj));
    }

    async getResourceIconsEnabledWhenLoadedAsync() {
        try {
            await this._settingService.waitForTenantSettingsToLoad();

            const tenantSettings = this._store.selectSnapshot((state) => <ITenantSettings>state.common[TENANT_SETTINGS]);

            const enableResourceIconsSetting = tenantSettings?.tenantSettings?.find((ts) => ts.key == TenantSettingKeys.ENABLE_RESOURCE_ICONS);

            if (!enableResourceIconsSetting) {
                this._resourceIconEnabled = false;
            } else {
                this._resourceIconEnabled = enableResourceIconsSetting.value.toLowerCase() === 'true';
            }
        } catch (e) {
            this._resourceIconEnabled = false;
        }

        return this._resourceIconEnabled;
    }

    getResourceIconsEnabled() {
        return this._resourceIconEnabled;
    }

    getBarWebComponentMarkup(driverSkills: ResourceIcon[], vehicleSkills: ResourceIcon[], positionAbsolute = true): string {
        if (!this._resourceIconEnabled) {
            return '';
        }

        if (!driverSkills?.length && !vehicleSkills?.length) {
            return '';
        }

        if (driverSkills) {
            driverSkills.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }
        if (vehicleSkills) {
            vehicleSkills.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }

        const driverSkillsString = Encode.encodeForHTML(driverSkills);
        const vehicleSkillsString = Encode.encodeForHTML(vehicleSkills);

        return `<resource-icons-bar-webcomponent
                    is-vehicle-driver-version="true"
                    driver-skill-icons-json="${driverSkillsString}" 
                    vehicle-skill-icons-json="${vehicleSkillsString}" 
                    class="w-100-p" >            
                </resource-icons-bar-webcomponent>`;
    }

    public getLegEventIconMarkupIfEnabled(event: LegPlannerEvent): string {
        if (!this._resourceIconEnabled) {
            return '';
        }
        const icons = event.orders?.flatMap((o) => o?.resourceIcons).filter((o) => o);

        return this.getBarMarkup(icons, false);
    }

    private getBarMarkup(icons: IResourceIcon[], positionAbsolute = true): string {
        if (!this._resourceIconEnabled) {
            return '';
        }

        if (!icons?.length) {
            return '';
        }

        icons.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

        //get rid of duplicates
        icons = icons.filter((skill, idx, thisArray) => thisArray.findIndex((skill2) => skill2.id === skill.id) === idx);
        const skillsString = Encode.encodeForHTML(icons);

        return `<resource-icons-bar 
                    is-vehicle-driver-version="false"
                    skill-icons-json="${skillsString}" 
                    class="w-100-p" >            
                </resource-icons-bar>`;
    }
}
