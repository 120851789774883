import {ResourceType} from 'app/blocks/model/resource-event.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IResourceEventType, ResourceEventType} from 'app/blocks/model/resource-event-type.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ResourceEventTypeService extends EntityCrudService<IResourceEventType> {
    constructor(http: HttpClient) {
        super(http, Resource.RESOURCE_EVENT_TYPES, (obj?) => new ResourceEventType(obj));
    }

    public getAllResourceEventByType = (type: any): Promise<any[]> => {
        return this.http.get<IResourceEventType[]>(`${SERVER_API_URL}api/resource-event-types/enum/${type}`).toPromise();
    };

    public getAllResourceEventTypes = (): Promise<IResourceEventType[]> => {
        return this.http.get<IResourceEventType[]>(`${SERVER_API_URL}api/resource-event-types`).toPromise();
    };
}
