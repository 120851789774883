import {HttpContext, HttpContextToken, HttpParams} from '@angular/common/http';

export const ERROR_SEVERITY_TOKEN = new HttpContextToken(() => ErrorSeverity.ERROR);

export enum ErrorSeverity {
    ERROR = 'error',
    WARN = 'warn',
    IGNORE = 'ignore'
}

export const createRequestContext = (context: {errorSeverity?: ErrorSeverity}) => {
    const httpContext = new HttpContext();
    if (context.errorSeverity) {
        httpContext.set(ERROR_SEVERITY_TOKEN, context.errorSeverity);
    }

    return httpContext;
};

export const createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach((key) => {
            // if (key !== 'sort') {
            options = options.set(key, req[key]);
            // }
        });
        // if (req.sort) {
        //     req.sort.forEach(val => {
        //         options = options.append('sort', val);
        //     });
        // }
    }
    return options;
};

export const createRequestOption2 = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach((key) => {
            // if (key !== 'sort') {
            if (req[key]) {
                options = options.set(key, req[key]);
            }
            // }
        });
        // if (req.sort) {
        //     req.sort.forEach(val => {
        //         options = options.append('sort', val);
        //     });
        // }
    }
    return options;
};
