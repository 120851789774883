import {Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {navigation} from 'app/navigation/navigation';
import {adminNavigation} from 'app/navigation/admin-navigation';
import {customerNavigation} from 'app/navigation/customer-navigation';
import {superAdminNavigation} from 'app/navigation/super-admin-navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationFrench} from 'app/navigation/i18n/fr';
import {Principal} from 'app/core/auth/principal.service';
import {RoleService} from 'app/blocks/service/api/role.service';
import {SettingService} from 'app/blocks/service/api/settings.service';
import {Store} from '@ngxs/store';
import {GetTenantSettings} from 'app/blocks/store/actions/common-actions';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {ServerEventService} from 'app/blocks/service/server-event.service';
import {LogService} from 'app/blocks/service/log.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {ServiceWorkerUpdateService} from 'app/common/service-worker-update.service';

import {locale as english} from 'app/common/i18n/en';
import {locale as french} from 'app/common/i18n/fr';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    adminNavigation: any;
    customerNavigation: any;
    superAdminNavigation: any;
    @ViewChild('dfsch') defaultScheduler: ElementRef;
    schedulerTemplateReady: Promise<any>;
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _principal: Principal,
        private roleService: RoleService,
        private superUserService: SuperUserService,
        private _store: Store,
        private _serverEventService: ServerEventService,
        private logService: LogService,
        private router: Router,
        private serviceWorkerUpdates: ServiceWorkerUpdateService
    ) {
        // Get default navigation
        this.navigation = navigation;
        this.adminNavigation = adminNavigation;
        this.customerNavigation = customerNavigation;
        this.superAdminNavigation = superAdminNavigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('admin', this.adminNavigation);
        this._fuseNavigationService.register('main', this.navigation);
        this._fuseNavigationService.register('customer', this.customerNavigation);
        this._fuseNavigationService.register('super-admin', this.superAdminNavigation);

        // Register service worker update logging
        this.serviceWorkerUpdates.logServiceWorkerUpdates();

        // Set the navigation depending upon the user's role
        this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (userIdentity) {
                if (userIdentity.authorities && userIdentity.authorities.length > 0 && userIdentity.authorities.indexOf('ROLE_SUPER_ADMIN') > -1) {
                    this._fuseNavigationService.setCurrentNavigation('super-admin');
                } else if (userIdentity.authorities && userIdentity.authorities.length > 0 && userIdentity.authorities.indexOf('ROLE_ADMIN') > -1) {
                    this._fuseNavigationService.setCurrentNavigation('admin');
                    this.roleService.updateNavigation();
                } else if (
                    userIdentity.authorities &&
                    userIdentity.authorities.length > 0 &&
                    userIdentity.authorities.indexOf('ROLE_CUSTOMER') > -1
                ) {
                    this._fuseNavigationService.setCurrentNavigation('customer');
                    this.roleService.updateNavigation();
                } else {
                    this._fuseNavigationService.setCurrentNavigation('main');
                    this.roleService.updateNavigation();
                }
                this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationFrench);
            }
        });

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationFrench);

        // Set the common translations
        this._fuseTranslationLoaderService.loadTranslations(english, french);

        // Use a language
        this._translateService.use('en');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
        this._unsubscribeAll = new Subject<void>();

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const url = event.urlAfterRedirects || event.url;

            const urlParams = new URLSearchParams(url.split('?')[1]);

            if (urlParams.get('hideSideBar') === 'true') {
                const sidebarElement = document.querySelector('.toggle-sidebar-folded');
                if (sidebarElement) {
                    sidebarElement.setAttribute('style', 'display: none;');
                }
                const navbarElement = document.querySelector('.navbar-scroll-container');
                if (navbarElement) {
                    navbarElement.setAttribute('style', 'display: none;');
                }
                const topNavbarElement = document.querySelector('.navbar-top');
                if (topNavbarElement) {
                    topNavbarElement.setAttribute('style', 'display: none;');
                }
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;

            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
