import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PersonService} from 'app/blocks/service/api/person.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IPerson} from 'app/blocks/model/person.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Person} from 'app/blocks/model/person.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class PersonResource extends AbstractEntityResource<IPerson> {
    constructor(personService: PersonService, router: Router) {
        super(
            Resource.PERSONS,
            personService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PERSONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PERSONS),
            (entity?) => new Person(entity)
        );
    }
}
