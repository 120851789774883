<i *ngIf="!useFontAwesome()"
    class="resource-icon"
    [ngStyle]="getIconStyle()"
    [ngClass]="getShapeClass()"
    nz-tooltip
    [nzTooltipTitle]="data.tooltipText"></i>

<fa-icon *ngIf="useFontAwesome()"
    [ngStyle]="getIconStyle(true)"
    [ngClass]="getShapeClass()"
    nz-tooltip
    [nzTooltipTitle]="data.tooltipText"
    [icon]="getFontAwesomeIconName()"
></fa-icon>