import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ISafetyProfile, SafetyProfile} from 'app/blocks/model/safety-profile.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ResourceModel} from 'app/blocks/model/resource.model';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class SafetyProfileService extends EntityCrudService<ISafetyProfile> {
    constructor(http: HttpClient) {
        super(http, Resource.SAFETY_PROFILES, (obj?) => new SafetyProfile(obj));
    }

    async update(entity: ISafetyProfile): Promise<ISafetyProfile> {
        if (entity.resources?.length) {
            await this.updateAssignedResources(entity.id, entity.resources);
        }

        return super.update(entity);
    }

    updateAssignedResources = (id: number, resources: {id: string}[]) => {
        const body = resources.map((r) => parseInt(r.id));

        return this.http
            .post<ResourceModel[]>(`${this._resourceUrl}/${id}/assign-resources`, body, {
                observe: 'response'
            })
            .toPromise();
    };

    getAssignedResourcesPaged = (safetyProfileId: number, req?: any): Promise<HttpResponse<ResourceModel[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<ResourceModel[]>(`${this._resourceUrl}/${safetyProfileId}/assigned-resources`, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getUnassignedResourcesPaged = (safetyProfileId: number, req?: any): Promise<HttpResponse<ResourceModel[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<ResourceModel[]>(`${this._resourceUrl}/${safetyProfileId}/unassigned-resources`, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };
}
