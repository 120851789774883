import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {DiCreateEditHeaderComponent} from 'app/common/di-create-edit-header/di-create-edit-header.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DiHelpModule} from 'app/common/di-help/di-help.module';

@NgModule({
    declarations: [DiCreateEditHeaderComponent],
    imports: [CommonModule, TranslateModule, NzIconModule, NzButtonModule, FontAwesomeModule, FlexLayoutModule, DiHelpModule],
    exports: [DiCreateEditHeaderComponent],
    providers: []
})
export class DiCreateEditHeaderModule {}
