import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IServiceLevel, ServiceLevel} from 'app/blocks/model/service-level.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ServiceLevelService extends EntityCrudService<IServiceLevel> {
    constructor(http: HttpClient) {
        super(http, Resource.SERVICE_LEVELS, (obj?) => new ServiceLevel(obj));
    }

    getAllServiceLevelsForPriceListScreen = (): Promise<any> => {
        return this.http.get(`${SERVER_API_URL}api/service-levels`).toPromise();
    };

    public getLite = (): Promise<ServiceLevel[]> => {
        return this.http.get<any[]>(`${SERVER_API_URL}api/service-levels-lite`).toPromise();
    };
}
