import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITranslation extends IBaseEntity {
    id?: number;
    key?: string;
    value?: string;
}

export class Translation extends BaseEntity implements ITranslation {
    public id?: number;
    public key?: string;
    public value?: string;

    constructor(translation?: any) {
        super();
        if (translation) {
            this.id = translation.id;
            this.key = translation.key;
            this.value = translation.value;
        }
    }

    get discriminator(): any {
        return this.key;
    }

    clone(): Translation {
        return new Translation(this);
    }
}
