import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface INominalCode extends IBaseEntity {
    id?: number;
    nominalCode?: string;
    description?: string;
    isActive?: boolean;
}

export class NominalCode extends BaseEntity implements INominalCode {
    public id?: number;
    public nominalCode?: string;
    public description?: string;
    public isActive?: boolean;

    constructor(nominalCode?: any) {
        super();
        if (nominalCode) {
            this.id = nominalCode.id;
            this.nominalCode = nominalCode.nominalCode;
            this.isActive = nominalCode.isActive;
        }
    }

    get discriminator(): any {
        return this.nominalCode;
    }

    clone(): NominalCode {
        return new NominalCode(this);
    }
}
