import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IHaulierOrder, HaulierOrder} from 'app/blocks/model/haulier-order.model';
import {IHaulierOrderAwaitingIntegration} from 'app/blocks/model/haulier-order-awaiting-integration.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable, firstValueFrom, map} from 'rxjs';
import {RunCreationParameters} from 'app/blocks/model/run-creation-parameters.model';
import {TourPlanRunCreationParameters} from 'app/blocks/model/tour-plan/tour-plan-run-creation-parameters.model';
import {createRequestOption} from 'app/blocks/util/request-util';
import {NumberLiteralType} from 'typescript';
import {TimeWindow} from 'app/blocks/model/time-window.model';
import {OrderNoteUpdateParameters} from 'app/blocks/model/order-note-update-parameters.model';
import {FileType} from 'app/blocks/model/hm-file.model';

@Injectable({
    providedIn: 'root'
})
export class HaulierOrderService extends EntityCrudService<IHaulierOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.HAULIER_ORDERS, (obj?) => new HaulierOrder(obj));
    }

    downloadCSVForXero = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('haulierOrderIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/csv/xero';
        return this.http.get(REQUEST_URI, {
            params: REQUEST_PARAMS,
            responseType: 'arraybuffer'
        });
    };

    sendDeliveryETAMail = (id: number): any => {
        const REQUEST_URI = SERVER_API_URL + `api/haulier-orders/${id}/delivery-eta-mail`;
        return this.http.get(REQUEST_URI, {}).toPromise();
    };

    findMultiple = (ids: string, projection?: string): Observable<IHaulierOrder[]> => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        if (projection) {
            REQUEST_PARAMS.set('projection', projection);
        }
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/multiple';
        return this.http.get<IHaulierOrder[]>(REQUEST_URI, {
            params: REQUEST_PARAMS
        });
    };

    getExtraTypeStateCount = (): any => {
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/extra-type-state-count';
        return this.http
            .get(REQUEST_URI, {
                params: {
                    size: 200
                }
            })
            .toPromise();
    };

    findByNumber = (orderNo: string, maxResults: number): Observable<any> => {
        const REQUEST_PARAMS = new HttpParams().set('page', 0).set('size', maxResults).set('sort', 'id,asc').set('orderNo', orderNo);
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders-by-number';
        return this.http.get(REQUEST_URI, {
            params: REQUEST_PARAMS
        });
    };

    uploadFile = (label: string, file: File, base64File: string, haulierOrderId: number, fileType: string): Promise<any> => {
        const fd = new FormData();
        if (base64File) {
            fd.append('base64File', base64File);
        } else {
            fd.append('file', file, file.name);
        }
        fd.append('label', label);
        if (fileType) {
            fd.append('fileType', fileType);
        } else {
            fd.append('fileType', FileType.CUSTOMER_OWN_PAPERWORK);
        }
        return this.http.post<any>(`${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/docs`, fd).toPromise();
    };

    uploadFiles = (label: string, file: File, base64File: string, haulierOrderIds: number[], fileType: string): Promise<any> => {
        const fd = new FormData();
        if (base64File) {
            fd.append('base64File', base64File);
        } else {
            fd.append('file', file, file.name);
        }
        fd.append('label', label);
        fd.append('fileType', fileType || FileType.CUSTOMER_OWN_PAPERWORK);
        fd.append('ids', haulierOrderIds.join(','));

        return this.http.post<any>(`${SERVER_API_URL}api/haulier-orders/multiple/docs`, fd).toPromise();
    };

    uploadPodFile = (file: File, haulierOrderId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        return this.http.post<any>(`${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/pods`, fd).toPromise();
    };

    uploadPodFileForMultipleOrders = (file: File, haulierOrderIds: number[]): Promise<any> => {
        const fd = new FormData();
        fd.append('orderIds', haulierOrderIds.toString());
        fd.append('file', file, file.name);
        return this.http.post<any>(`${SERVER_API_URL}api/haulier-orders/pods`, fd).toPromise();
    };

    uploadPodScanForMultipleOrders = (scanData: string, haulierOrderIds: number[]): Promise<any> => {
        const fd = new FormData();
        fd.append('orderIds', haulierOrderIds.toString());
        fd.append('scanData', scanData);
        return this.http.post<any>(`${SERVER_API_URL}api/haulier-orders/pods/scan`, fd).toPromise();
    };

    replacePodFile = (file: File, haulierOrderId: number, hmFileId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        return this.http.put<any>(`${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/pods/${hmFileId}`, fd).toPromise();
    };

    replacePodFileForMultipleOrders = (file: File, haulierOrderIds: number[], hmFileIds: number[]): Promise<any> => {
        const fd = new FormData();
        fd.append('orderIds', haulierOrderIds.toString());
        fd.append('hmFileIds', hmFileIds.toString());
        fd.append('file', file, file.name);
        return this.http.put<any>(`${SERVER_API_URL}api/haulier-orders/pods`, fd).toPromise();
    };

    replacePodWithScanForMultipleOrders = (scanData: string, haulierOrderIds: number[], hmFileIds: number[]): Promise<any> => {
        const fd = new FormData();
        fd.append('orderIds', haulierOrderIds.toString());
        fd.append('hmFileIds', hmFileIds.toString());
        fd.append('scanData', scanData);
        return this.http.put<any>(`${SERVER_API_URL}api/haulier-orders/pods/scan`, fd).toPromise();
    };

    appendPodFileToMultipleOrders = (file: File, haulierOrderIds: number[], hmFileIds: number[]): Promise<any> => {
        const fd = new FormData();
        fd.append('orderIds', haulierOrderIds.toString());
        fd.append('hmFileIds', hmFileIds.toString());
        fd.append('file', file, file.name);
        return this.http.put<any>(`${SERVER_API_URL}api/haulier-orders/pods/append`, fd).toPromise();
    };

    appendPodScanToMultipleOrders = (scanData: string, haulierOrderIds: number[], hmFileIds: number[]): Promise<any> => {
        const fd = new FormData();
        fd.append('orderIds', haulierOrderIds.toString());
        fd.append('hmFileIds', hmFileIds.toString());
        fd.append('scanData', scanData);
        return this.http.put<any>(`${SERVER_API_URL}api/haulier-orders/pods/append/scan`, fd).toPromise();
    };

    unassignPodFileForMultipleOrders = (haulierOrderIds: number[], hmFileIds: number[]): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('orderIds', haulierOrderIds.toString()).set('hmFileIds', hmFileIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/pods/unassign`;
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    checkIfPodExistsForHaulierOrders = (haulierOrderIds: number[]): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('orderIds', haulierOrderIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/pods/check-exist`;
        return this.http.get<any>(REQUEST_URI, {params: REQUEST_PARAMS}).toPromise();
    };

    setPodNotAvailableForMultipleOrders = (haulierOrderIds: number[]): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('orderIds', haulierOrderIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/pods/not-available`;
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    deleteFile = (haulierOrderId: number, hmFileId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/docs/${hmFileId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    downloadFile = (haulierOrderId: number, hmFileId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/docs/${hmFileId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    sendBookingEmails = (ids): any => {
        const requestParams = new HttpParams().set('ids', ids);
        return this.http
            .post<any>(SERVER_API_URL + 'api/haulier-orders/booking-email', null, {observe: 'response', params: requestParams})
            .toPromise();
    };
    bulkUpdate = (orders): Promise<any> => {
        const requestParams = new HttpParams().set('orders', orders);
        return this.http.put<IHaulierOrder[]>(SERVER_API_URL + 'api/haulier-orders-bulk', orders, {observe: 'response'}).toPromise();
    };

    invoiceOrders = (ids): any => {
        const requestParams = new HttpParams().set('ids', ids);
        return this.http
            .post<any>(SERVER_API_URL + 'api/haulier-orders/invoice', null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    public downloadPDF = (haulierOrderId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public downloadPOD = (haulierOrderId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/pod`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public sendPdfEmail = (haulierOrderIds: any): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', haulierOrderIds);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/email-pdf`;
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS});
    };

    public confirmSendQuoteEmail = (haulierOrderIds: any): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', haulierOrderIds);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/confirm-send-quote`;
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS});
    };

    public sendQuoteEmailCustomer = (orderIds: any, emails: any): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', orderIds).set('emails', emails);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/send-quote`;
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS});
    };

    public sendQuoteEmail = (haulierOrderIds: any): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', haulierOrderIds);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/email-quote`;
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS});
    };

    public approve = (haulierOrderId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/approve`;
        return this.http
            .post(REQUEST_URI, null)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public bulkApprove = (haulierOrderIds: number[]): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/bulk-approve`;
        return this.http
            .post(REQUEST_URI, haulierOrderIds)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public bulkApproveQuote = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/quotes/approve`;
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public reject = (haulierOrderId: number, reason?: string): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/reject`;
        return this.http
            .post(REQUEST_URI, reason)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public bulkReject = (haulierOrderIds: number[], reason?: string): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/bulk-reject`;
        return this.http
            .post(REQUEST_URI, {orderIds: haulierOrderIds, reason: reason})
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public updateWindow = (collectionWindow: TimeWindow, deliveryWindow: TimeWindow, orderId): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${orderId}/update-window`;
        return this.http
            .post(REQUEST_URI, {collectionWindow: collectionWindow, deliveryWindow: deliveryWindow})
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public bulkRejectQuote = (ids: string, reason?: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/quotes/reject`;
        return this.http
            .post<any>(REQUEST_URI, reason, {
                observe: 'response',
                params: REQUEST_PARAMS
            })
            .toPromise();
    };

    public checkRunBeforeCancellingOrder = (haulierOrderId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/checkLegsBeforeCancel`;
        return this.http.post<any[]>(REQUEST_URI, null).toPromise();
    };

    public deleteRunAndCancelOrder = (haulierOrderId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/cancelOrderAndDeleteRun`;
        return this.http
            .post(REQUEST_URI, null)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public cancel = (haulierOrderId: number): any => {
        this.checkRunBeforeCancellingOrder(haulierOrderId);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/cancel`;
        return this.http
            .post(REQUEST_URI, null)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public unCancel = (haulierOrderId: number): any => {
        this.checkRunBeforeCancellingOrder(haulierOrderId);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/un-cancel`;
        return this.http
            .post(REQUEST_URI, null)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    public bulkCancelQuote = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/quotes/cancel`;
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public bulkConvertQuoteToOrder = (ids: string): any => {
        const REQUEST_PARAMS = new HttpParams().set('ids', ids);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/quotes/convert`;
        return this.http.post<any>(REQUEST_URI, REQUEST_PARAMS, {observe: 'response'}).toPromise();
    };

    public setLoadNumberForOrders = (orderIds: number[], loadNumber: string): any => {
        orderIds.forEach((id) => {
            this.find(id).then((order) => {
                if (!order) {
                    return;
                }

                order.loadNumber = loadNumber;

                this.update(order);
            });
        });
    };

    createRun = (params: RunCreationParameters): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/create-run';
        return this.http.post<any>(REQUEST_URI, params).toPromise();
    };

    updateOrderNotes = (params: OrderNoteUpdateParameters[]): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/traffic-notes/bulk-amend';
        return this.http.put<any>(REQUEST_URI, params).toPromise();
    };

    createRunForUnplanned = (params: RunCreationParameters): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/create-unplanned-run';
        return this.http.post<any>(REQUEST_URI, params).toPromise();
    };

    createRunForTourPlan = (params: TourPlanRunCreationParameters): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/create-run-for-tour-plan';
        return this.http.post<any>(REQUEST_URI, params).toPromise();
    };

    createBulkRunsForTourPlan(params: TourPlanRunCreationParameters[]): Promise<any[]> {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/create-run-for-tour-plan/bulk';
        return this.http.post<any>(REQUEST_URI, params).toPromise();
    }

    markAsReadyToInvoice = (ids): Promise<any> => {
        const requestParams = new HttpParams().set('orderIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/mark-as-ready-to-invoice';
        return this.http.post<any>(REQUEST_URI, requestParams, {observe: 'response'}).toPromise();
    };

    markAsDelivered = (ids): Promise<any> => {
        const requestParams = new HttpParams().set('orderIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/mark-as-delivered';
        return this.http.post<any>(REQUEST_URI, requestParams, {observe: 'response'}).toPromise();
    };

    markAsNotReadyToInvoice = (ids): Promise<any> => {
        const requestParams = new HttpParams().set('orderIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/mark-as-not-ready-to-invoice';
        return this.http.post<any>(REQUEST_URI, requestParams, {observe: 'response'}).toPromise();
    };

    createBatchesFromIds = (ids, date, batchRef?): Promise<any> => {
        let requestParams = new HttpParams().set('ids', ids);
        if (date !== null && date !== undefined) {
            requestParams = requestParams.set('date', date.toISOString());
        }
        if (batchRef) {
            requestParams = requestParams.set('batchRefMap', JSON.stringify(batchRef));
        }
        return this.http.post<any>(SERVER_API_URL + 'api/pre-invoice-batchs/create', requestParams, {observe: 'response'}).toPromise();
    };

    copyOrder = (orderIds, params): Promise<any> => {
        const body = params;
        body.orderIds = orderIds;
        const REQUEST_URI = SERVER_API_URL + 'api/haulier-orders/copy';
        return this.http.post<any>(REQUEST_URI, body).toPromise();
    };

    splitOrder = (orderId, splitQtys): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${orderId}/split`;
        return this.http.post<any>(REQUEST_URI, splitQtys).toPromise();
    };

    markAsPodReceived = (orderId, params): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/haulier-orders/${orderId}/pods/mark-as-received`, null, {observe: 'response', params: params})
            .toPromise();
    };

    checkCrossDockLocations = (haulierOrderIds): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('ids', haulierOrderIds);
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/check-cross-dock`;
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS}).toPromise();
    };

    getOrdersForPlanning = (req?: any): Promise<HttpResponse<IHaulierOrder[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<IHaulierOrder[]>(`${SERVER_API_URL}api/haulier-orders/planning`, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getOrdersAwaitingIntegration = (req?: any): Promise<HttpResponse<IHaulierOrderAwaitingIntegration[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<IHaulierOrderAwaitingIntegration[]>(`${SERVER_API_URL}api/haulier-orders/awaiting-integration`, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    updateRates = (ordersForRating) => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/haulier-orders/update-rates`, ordersForRating, {
                observe: 'response'
            })
            .toPromise();
    };

    getOrderIdsNotOnRuns = (haulierOrderIds: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderIds}/check-runs`;
        return this.http.get(REQUEST_URI, {}).toPromise();
    };

    validateCrossDockTiming = (ids, type, dateTime): Promise<any> => {
        const options = createRequestOption({
            ids: ids,
            type: type,
            dateTime: dateTime
        });

        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${ids}/cross-dock-timing`;
        return this.http.get(REQUEST_URI, {params: options}).toPromise();
    };

    getOrdersForSubcontracting = (req: any): Promise<HttpResponse<HaulierOrder[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<HaulierOrder[]>(`${SERVER_API_URL}api/cp/haulier-order-subcontracting`, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getInvoicesForOrder = (haulierOrderId: any): Promise<any[]> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/invoices`;
        return this.http.get<any[]>(REQUEST_URI, {}).toPromise();
    };

    getCreditNotesForOrder = (haulierOrderId: any): Promise<any[]> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/credit-notes`;
        return this.http.get<any[]>(REQUEST_URI, {}).toPromise();
    };

    getAllForIds = (req): Promise<any[]> => {
        const options = createRequestOption(req);

        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/ids`;
        return this.http.get<any[]>(REQUEST_URI, {params: options}).toPromise();
    };

    splitForGoods = (orderId): Promise<any[]> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${orderId}/goods-type-split`;
        return this.http.post<any>(REQUEST_URI, {}).toPromise();
    };

    getOrderHasUnapprovedPoints = (haulierOrderId: number): Promise<boolean> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/${haulierOrderId}/has-unapproved-points`;
        return this.http.get<boolean>(REQUEST_URI, {}).toPromise();
    };

    gettextSuggession = (endpoint: string, typedText: string): Promise<string> => {
        return this.http.get<string>(SERVER_API_URL + `api${endpoint}?typedText=${typedText}`, {responseType: 'text' as 'json'}).toPromise();
    };

    updateBookIn = (order: IHaulierOrder): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/update-book-in`;
        return this.http.post<any>(REQUEST_URI, order).toPromise();
    };

    getHaulierOrderWithOrderStatusAndSource = async (orderStatus: string, orderSource: string): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/by-status-and-source?orderStatus=${encodeURIComponent(orderStatus)}&orderSource=${encodeURIComponent(orderSource)}`;
        return await firstValueFrom(this.http.get<any[]>(REQUEST_URI));
    };

    public generatePil = async (orderIds: any[], preview = false): Promise<any> => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        const orderIdsString = orderIds.join(',');
        return await this.http
            .get<any>(`${SERVER_API_URL}api/haulier-orders/${orderIdsString}/generate-pil`, {
                responseType: type as 'json'
            })
            .toPromise();
    };

    getOrdersByCustomerId = async (customerId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/haulier-orders/by-customer/${customerId}`;
        return await firstValueFrom(this.http.get<any[]>(REQUEST_URI));
    };

    metsaOrderProcessing(orderIds, businessTypeId): Promise<void> {
        const url = `${SERVER_API_URL}api/haulier-orders/metsa-order-processing/${businessTypeId}`;
        return firstValueFrom(this.http.post<void>(url, orderIds));
    }
}
