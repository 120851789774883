import {getActionTypeFromInstance} from '@ngxs/store';
import {getDefaultEntitiesState} from 'app/blocks/store/states/entities-state';
import {getDefaultCommonState} from 'app/blocks/store/states/common-state';
import {NgxsNextPluginFn} from '@ngxs/store/src/symbols';

export class LogoutUser {
    static readonly type = 'Logout User';
    constructor() {}
}

export function logoutPlugin(state: any, action: any, next: NgxsNextPluginFn): any {
    if (getActionTypeFromInstance(action) === LogoutUser.type) {
        state = {
            entities: getDefaultEntitiesState(),
            common: getDefaultCommonState()
        };
    }
    return next(state, action);
}
