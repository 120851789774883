import {ENTITIES_TARIFFS_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faPercent} from '@fortawesome/free-solid-svg-icons';
import {FuelSurchargeGroupService} from 'app/blocks/service/api/fuel-surcharge-group.service';
import {Resource} from 'app/constants/resource';

import {IFuelSurchargeGroup, FuelSurchargeGroup} from 'app/blocks/model/fuel-surcharge-group.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class FuelSurchargeGroupResource extends AbstractEntityResource<IFuelSurchargeGroup> {
    constructor(fuelSurchargeGroupService: FuelSurchargeGroupService, router: Router) {
        super(
            Resource.FUEL_SURCHARGE_GROUPS,
            fuelSurchargeGroupService,
            new EntityNavigator(ENTITIES_TARIFFS_BASE_URL, Resource.FUEL_SURCHARGE_GROUPS, router),
            new EntityUrlProvider(ENTITIES_TARIFFS_BASE_URL, Resource.FUEL_SURCHARGE_GROUPS),
            (entity?) => new FuelSurchargeGroup(entity),
            (entity) => entity.name,
            faPercent
        );
    }
}
