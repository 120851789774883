import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DiHelpComponent} from 'app/common/di-help/di-help.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [DiHelpComponent],
    imports: [CommonModule, FontAwesomeModule],
    exports: [DiHelpComponent],
    providers: []
})
export class DiHelpModule {}
