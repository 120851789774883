import {ContainerType} from 'app/blocks/model/container-type.model';
import {LocationPoint} from 'app/blocks/model/location-point.model';
import {Organisation} from 'app/blocks/model/organisation.model';
import {Run} from 'app/blocks/model/run.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IAttemptedDelivery extends IBaseEntity {
    id?: number;
    resolutionType?: string;
    refusalType?: string;
    orderAction?: string;
    refusedReason?: string;
    refusedReasonDesc?: string;
    isCharging?: boolean;
    amountCharged?: number;
    chargeType?: string;
    chargeState?: string;
    chargeReason?: string;
    order?: any;
    run?: Run;
    runId: number;
    orderId: number;
    newOrderId: number;
    newRunId: number;
    productName?: string;
    productCode?: string;
    receiptNumber?: string;
    packSize?: number;
    quantityDispatched?: number;
    quantityRefused?: number;
    pallets?: number;
    spaces?: number;
    partialDeliveryReason?: string;
    partialDeliveryStatus?: string;
    notes?: string;
    returnToSupplierBy?: string;
    currentLocation?: LocationPoint;
    goodsStoredLocation?: LocationPoint;
    goodsReturnLocation?: LocationPoint;
    containerType?: ContainerType;
    baseGoodsType?: string;
    containerNo?: string;
    sealNo?: string;
    temperatures?: number;
    litres?: number;
    cases?: number;
    weight?: number;
    customer?: Organisation;
    legId?: number;
    litresAmbient?: number;
    stopId?: number;
}

export class AttemptedDelivery extends BaseEntity implements IAttemptedDelivery {
    id?: number;
    resolutionType?: string;
    refusalType?: string;
    orderAction?: string;
    refusedReason?: string;
    refusedReasonDesc?: string;
    isCharging?: boolean;
    amountCharged?: number;
    chargeType?: string;
    chargeState?: string;
    chargeReason?: string;
    order?: any;
    run?: Run;
    runId: number;
    orderId: number;
    newOrderId: number;
    newRunId: number;
    productName?: string;
    productCode?: string;
    receiptNumber?: string;
    packSize?: number;
    quantityDispatched?: number;
    quantityRefused?: number;
    pallets?: number;
    spaces?: number;
    partialDeliveryReason?: string;
    partialDeliveryStatus?: string;
    notes?: string;
    returnToSupplierBy?: string;
    currentLocation?: LocationPoint;
    goodsStoredLocation?: LocationPoint;
    goodsReturnLocation?: LocationPoint;
    containerType?: ContainerType;
    containerNo?: string;
    sealNo?: string;
    temperatures?: number;
    litres?: number;
    litresAmbient?: number;
    cases?: number;
    weight?: number;
    customer?: Organisation;
    legId?: number;
    stopId?: number;

    constructor(attemptedDelivery?) {
        super();
        if (attemptedDelivery) {
            this.containerType = attemptedDelivery.containerType;
            this.containerNo = attemptedDelivery.containerNo;
            this.sealNo = attemptedDelivery.sealNo;
            this.temperatures = attemptedDelivery.temperatures;
            this.litres = attemptedDelivery.litres;
            this.cases = attemptedDelivery.cases;
            this.weight = attemptedDelivery.weight;
            this.id = attemptedDelivery.id;
            this.resolutionType = attemptedDelivery.resolutionType;
            this.refusalType = attemptedDelivery.refusalType;
            this.refusedReason = attemptedDelivery.refusedReason;
            this.refusedReasonDesc = attemptedDelivery.refusedReasonDesc;
            this.orderAction = attemptedDelivery.orderAction;
            this.isCharging = attemptedDelivery.isCharging;
            this.amountCharged = attemptedDelivery.amountCharged;
            this.chargeType = attemptedDelivery.chargeType;
            this.chargeState = attemptedDelivery.chargeState;
            this.chargeReason = attemptedDelivery.chargeReason;
            this.order = attemptedDelivery.order;
            this.run = attemptedDelivery.run;
            this.productName = attemptedDelivery.productName;
            this.productCode = attemptedDelivery.productCode;
            this.receiptNumber = attemptedDelivery.receiptNumber;
            this.packSize = attemptedDelivery.packSize;
            this.quantityDispatched = attemptedDelivery.quantityDispatched;
            this.quantityRefused = attemptedDelivery.quantityRefused;
            this.pallets = attemptedDelivery.pallets;
            this.spaces = attemptedDelivery.spaces;
            this.partialDeliveryReason = attemptedDelivery.partialDeliveryReason;
            this.partialDeliveryStatus = attemptedDelivery.partialDeliveryStatus;
            this.notes = attemptedDelivery.notes;
            this.returnToSupplierBy = attemptedDelivery.returnToSupplierBy;
            this.currentLocation = attemptedDelivery.currentLocation;
            this.goodsStoredLocation = attemptedDelivery.goodsStoredLocation;
            this.goodsReturnLocation = attemptedDelivery.goodsReturnLocation;
            this.runId = attemptedDelivery.runId;
            this.orderId = attemptedDelivery.orderId;
            this.customer = attemptedDelivery.customer;
            this.newOrderId = attemptedDelivery.newOrderId;
            this.newRunId = attemptedDelivery.newRunId;
            this.legId = attemptedDelivery.legId;
            this.litresAmbient = attemptedDelivery.litresAmbient;
            this.stopId = attemptedDelivery.stopId;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): AttemptedDelivery {
        return new AttemptedDelivery(this);
    }
}
