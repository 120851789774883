import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ITpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';
import {PostcodeArea} from 'app/blocks/model/postcode-area.model';

export class TrafficAreaLite {
    public id?: number;
    public shortName?: string;
    public description?: string;
    public controlAreaId?: number;
    public tp_traffic_area_skills?: ITpVehicleSkill[];
}

export interface ITrafficArea extends IBaseEntity {
    id?: number;
    shortName?: string;
    description?: string;
    controlAreaId?: number;
    tp_traffic_area_skills?: ITpVehicleSkill[];
    postcodeAreas?: PostcodeArea[];
}

export class TrafficArea extends BaseEntity implements ITrafficArea {
    public id?: number;
    public shortName?: string;
    public description?: string;
    public controlAreaId?: number;
    public tp_traffic_area_skills?: ITpVehicleSkill[];
    public postcodeAreas?: PostcodeArea[];

    constructor(trafficAreaLite?: any) {
        super();
        if (trafficAreaLite) {
            this.id = trafficAreaLite.id;
            this.shortName = trafficAreaLite.shortName;
            this.description = trafficAreaLite.description;
            this.controlAreaId = trafficAreaLite.controlAreaId;
            this.tp_traffic_area_skills = trafficAreaLite.tp_traffic_area_skills;
            this.postcodeAreas = trafficAreaLite.postcodeAreas;
        } else {
            this.tp_traffic_area_skills = [];
            this.postcodeAreas = [];
        }
    }

    get discriminator(): any {
        return this.shortName;
    }

    clone(): TrafficArea {
        return new TrafficArea(this);
    }
}
