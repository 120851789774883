import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DriverSubType, IDriverSubType} from 'app/blocks/model/driver-sub-type.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DriverSubTypeService extends EntityCrudService<IDriverSubType> {
    constructor(http: HttpClient) {
        super(http, Resource.DRIVER_SUB_TYPES, (obj?) => new DriverSubType(obj));
    }
}
