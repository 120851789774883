import {Country} from 'app/blocks/model/country.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICountry} from 'app/blocks/model/country.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class CountryService extends EntityCrudService<ICountry> {
    constructor(http: HttpClient) {
        super(http, Resource.COUNTRIES, (obj?) => new Country(obj));
    }

    getDefaultForTenant = (): Promise<ICountry> => {
        return this.http.get<ICountry>(SERVER_API_URL + 'api/countries/tenant-default').toPromise();
    };

    getCountryForCode = (code): Promise<ICountry> => {
        return this.http.get<ICountry>(SERVER_API_URL + 'api/countries/code/' + code).toPromise();
    };
}
