import {FuseNavigation} from '@fuse/types';

export const customerFeature: FuseNavigation[] = [
    {
        id: 'features',
        title: 'Features',
        type: 'group',
        children: []
    }
];
