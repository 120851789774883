import {Component, Input, OnInit} from '@angular/core';
import {faRegistered} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'leg-planner-icon',
    templateUrl: './leg-planner-icon.component.html',
    styleUrls: ['./leg-planner-icon.component.scss'],
    providers: [LegPlannerIconComponent]
})
export class LegPlannerIconComponent implements OnInit {
    @Input() icon: any;

    @Input() tooltip: any;
    @Input() color: any;

    @Input() click: any;

    iconDeserialised: any;

    constructor() {}

    ngOnInit(): void {
        this.iconDeserialised = JSON.parse(this.icon);
    }

    getColor() {
        return this.color ? this.color : 'black';
    }
}
