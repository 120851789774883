import {Injectable} from '@angular/core';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IInvoice, Invoice} from 'app/blocks/model/invoice.model';
import {DriverExpenses, IDriverExpenses} from 'app/blocks/model/driver-expenses';
import {InvoiceService} from 'app/blocks/service/api/invoice.service';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ENTITIES_EXPENSES_BASE_URL, ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {faMoneyCheck, faStar} from '@fortawesome/free-solid-svg-icons';
import {DriverExpensesService} from 'app/blocks/service/api/driver-expenses.service';

@Injectable({
    providedIn: 'root'
})
export class DriverExpenseResource extends AbstractEntityResource<IDriverExpenses> {
    constructor(driverExpenseService: DriverExpensesService, router: Router) {
        super(
            Resource.EXPENSES,
            driverExpenseService,
            new EntityNavigator(ENTITIES_EXPENSES_BASE_URL, Resource.EXPENSES, router),
            new EntityUrlProvider(ENTITIES_EXPENSES_BASE_URL, Resource.EXPENSES),
            (entity?) => new DriverExpenses(entity),
            (entity) => entity.id,
            faMoneyCheck
        );
    }
}
