import {ISurcharge} from 'app/blocks/model/invoice-surcharge.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IInvoiceLine, InvoiceLine} from 'app/blocks/model/invoice-line.model';
import {IDiscount} from 'app/blocks/model/discount.model';
import {DiDate} from 'app/blocks/util/di-date';
import {AdHocLine} from 'app/blocks/model/ad-hoc-line.model';
import {IInvoiceSubcontractLine, InvoiceSubcontractLine} from 'app/blocks/model/invoice-subcontract-line.model';
import {IInvoiceExtraLine, InvoiceExtraLine} from 'app/blocks/model/invoice-extra-line.model';

export enum InvoiceType {
    NORMAL,
    SELF_BILL,
    ONE_LINER,
    SUB_CONTRACT,
    SELF_BILL_RMD,
    EXTRA,
    CLIENT_INV,
    SC_SELF_BILL,
    SC_EXTRA,
    PF_HUB_CHRG,
    PF_DEPOT_CRHG,
    PF_SELF_BILL_DPT
}

export enum InvoiceStatus {
    NOT_PREPARED,
    PROFORMA,
    PRE_INVOICE,
    READY,
    INVOICED
}

export enum PaymentStatusType {
    PAID, // Paid
    UNPAID, // Unpaid
    PRT_PAID, // Partially paid
    CR_FWD // Carried forward
}

export interface IInvoice extends IBaseEntity {
    id?: number;
    invoiceNo?: string;
    invoiceType?: string;
    invoiceStatus?: string;
    invoiceDate?: any;
    dueDate?: DiDate;
    lastReminder?: string;
    reference?: string;
    poReference?: string;

    subTotal?: number;
    vatTypeId: any;
    vatRateId: any;
    totalAmount: any;
    tax?: number;
    discount?: IDiscount;
    surcharge?: ISurcharge;
    invoiceTotal: number;

    billToId: number;
    billTo: any;
    businessTypeId: number;
    businessTypeDetails: any;
    nominalCodeId: number;
    nominalCodeDetails: any;

    creationDate?: any;
    createdBy?: string;

    updateDate?: any;
    updatedBy?: any;

    invoiceLines?: IInvoiceLine[];
    invoiceSubcontractLines?: IInvoiceSubcontractLine[];
    invoiceExtraLines?: IInvoiceExtraLine[];

    adHocLines?: any[];
    notes?: any;

    paymentAllocation?: any;
    creditNoteAllocation?: any[];

    balance: number;
    paid: number;
    linkedOrderIds: number[];
    printHeadered: boolean;
    isPDFAvailable?: any;
}

export class Invoice extends BaseEntity implements IInvoice {
    public id?: number;
    public invoiceNo?: string;
    public invoiceType?: string;
    public invoiceStatus?: string;
    public invoiceDate?: any;
    public dueDate?: DiDate;
    public lastReminder?: string;
    public reference?: string;
    public poReference?: string;

    public subTotal: number;
    public vatTypeId: any;
    public vatRateId: any;

    public totalAmount: number;
    public tax: number;
    public discount: any;
    public surcharge: any;
    public invoiceTotal: number;

    public billToId: number;
    public billTo: any;
    public businessTypeId: number;
    public businessTypeDetails: any;
    public nominalCodeId: number;
    public nominalCodeDetails: any;

    public creationDate?: any;
    public createdBy?: string;

    public updateDate?: any;
    public updatedBy?: any;

    public invoiceLines?: IInvoiceLine[] = [];
    public invoiceSubcontractLines?: IInvoiceSubcontractLine[] = [];
    public invoiceExtraLines?: IInvoiceExtraLine[] = [];
    public adHocLines?: any[] = [];

    public notes?: any;

    public balance: number;
    public paid: number;

    public paymentAllocation?: any;
    public creditNoteAllocation?: any[];

    public linkedOrderIds: number[];
    public printHeadered: boolean;
    public isPDFAvailable?: any;

    constructor(invoice?: any) {
        super();
        if (invoice) {
            this.id = invoice.id;
            this.invoiceNo = invoice.invoiceNo;
            this.invoiceType = invoice.invoiceType;
            this.invoiceStatus = invoice.invoiceStatus;
            this.invoiceDate = invoice.invoiceDate;
            this.dueDate = invoice.dueDate;
            this.lastReminder = invoice.lastReminder;
            this.reference = invoice.reference;
            this.poReference = invoice.poReference;

            this.subTotal = invoice.subTotal;
            this.vatTypeId = invoice.vatTypeId;
            this.vatRateId = invoice.vatRateId;

            this.totalAmount = invoice.totalAmount;
            this.tax = invoice.tax;
            this.discount = invoice.discount;
            this.surcharge = invoice.surcharge;
            this.invoiceTotal = invoice.invoiceTotal;

            this.billToId = invoice.billToId;
            this.billTo = invoice.billTo;
            this.businessTypeId = invoice.businessTypeId;
            this.businessTypeDetails = invoice.businessTypeDetails;

            this.creationDate = invoice.creationDate;
            this.createdBy = invoice.createdBy;

            this.updateDate = invoice.updateDate;
            this.updatedBy = invoice.updatedBy;

            invoice.invoiceLines.forEach((invoiceLine) => {
                this.invoiceLines.push(new InvoiceLine(invoiceLine));
            });

            invoice.invoiceSubcontractLines.forEach((subcontractLine) => {
                this.invoiceSubcontractLines.push(new InvoiceSubcontractLine(subcontractLine));
            });

            invoice.invoiceExtraLines.forEach((extraLine) => {
                this.invoiceExtraLines.push(new InvoiceExtraLine(extraLine));
            });

            invoice.adHocLines.forEach((adHocLine) => {
                this.adHocLines.push(new AdHocLine(adHocLine));
            });

            this.notes = invoice.notes;
            this.paymentAllocation = invoice.paymentAllocation;
            this.creditNoteAllocation = invoice.creditNoteAllocation;

            this.balance = invoice.balance;
            this.paid = invoice.paid;
            this.nominalCodeId = invoice.nominalCodeId;
            this.nominalCodeDetails = invoice.nominalCodeDetails;

            this.linkedOrderIds = invoice.linkedOrderIds;
            this.printHeadered = invoice.printHeadered;
            this.isPDFAvailable = invoice.isPDFAvailable;
        } else {
            this.invoiceStatus = InvoiceStatus[InvoiceStatus.READY];
            this.tax = 0;
            this.invoiceType = InvoiceType[InvoiceType.NORMAL];
            this.subTotal = 0;
            this.paymentAllocation = [];
            this.creditNoteAllocation = [];
            this.creationDate = DiDate.currentDate();
            this.updateDate = DiDate.currentDate();
            this.dueDate = DiDate.lastDayOfCurrentMonth();
            this.lastReminder = DiDate.lastDayOfCurrentMonth().addDays(7).toString();
            this.linkedOrderIds = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Invoice {
        return new Invoice(this);
    }
}
