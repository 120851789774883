import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITrailerType extends IBaseEntity {
    id?: number;
    name: string;
}

export class TrailerType extends BaseEntity implements ITrailerType {
    public id?: number;
    public name: string;

    constructor(trailerType?: any) {
        super();
        if (trailerType) {
            this.id = trailerType.id;
            this.name = trailerType.name;
            // Add other attributes
            // this.name = trailerType.name;
            // Whenever there is a time element, use DiTime
            // this.timeAttr = DiTime.newInstance(trailerType.timeAttr)
            // Whenever there is a date element, use DiDate
            // this.dateAttr = DiDate.newInstance(trailerType.dateAttr)
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TrailerType {
        return new TrailerType(this);
    }
}
