import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IRun, Run} from 'app/blocks/model/run.model';
import {ILeg} from 'app/blocks/model/leg.model';
import {ITrunk} from 'app/blocks/model/trunk.model';
import {RunCreationParameters} from 'app/blocks/model/run-creation-parameters.model';
import {RunCopyParameters} from 'app/blocks/model/run-copy-parameters.model';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ErrorSeverity, createRequestContext, createRequestOption} from 'app/blocks/util/request-util';
import {IBookingTimeUpdateParams} from 'app/blocks/model/booking-time-update-params.model';
import {ILegLocationChange} from 'app/blocks/model/leg-location-change.model';

@Injectable({
    providedIn: 'root'
})
export class RunService extends EntityCrudService<IRun> {
    constructor(http: HttpClient) {
        super(http, Resource.RUNS, (obj?) => new Run(obj));
    }

    rebuildRun = (params: RunCreationParameters): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/rebuild-run';
        return this.http.post<any>(REQUEST_URI, params).toPromise();
    };
    updateBookedTimes(params: IBookingTimeUpdateParams[], alterPlannedTimesToMatch: boolean) {
        const REQUEST_URI = `${SERVER_API_URL}api/runs/update-booked-times`;
        const queryParams = new HttpParams().set('alterPlannedTimesToMatch', alterPlannedTimesToMatch);
        return this.http.post<any>(REQUEST_URI, params, {params: queryParams}).toPromise();
    }

    getRunsComingIn(
        legStatuses?: string,
        customerId?: number,
        orderNumber?: number,
        runId?: number,
        driverId?: number,
        vehicleId?: number,
        trailerId?: number,
        startDate?: string[],
        endDate?: string[],
        businessTypeIds?: string,
        trafficAreaIds?: string,
        errorSeverity = ErrorSeverity.ERROR
    ): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/coming-in';
        let REQUEST_PARAMS = new HttpParams().set('businessTypeIds', businessTypeIds).set('trafficAreaIds', trafficAreaIds);
        if (startDate && startDate.length === 2) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('startDateFrom', startDate[0]).set('startDateTo', startDate[1]);
        }
        if (endDate && endDate.length === 2) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('endDateFrom', endDate[0]).set('endDateTo', endDate[1]);
        }
        if (legStatuses) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('legStatuses', legStatuses);
        }
        if (customerId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('customer', customerId);
        }
        if (orderNumber) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('orderNumber', orderNumber);
        }
        if (runId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('runId', runId);
        }
        if (driverId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('driverId', driverId);
        }
        if (vehicleId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('vehicleId', vehicleId);
        }
        if (trailerId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('trailerId', trailerId);
        }
        return this.http
            .get<any>(REQUEST_URI, {
                params: REQUEST_PARAMS,
                context: createRequestContext({errorSeverity})
            })
            .toPromise();
    }

    addLegToRun = (leg: ILeg): Promise<IRun> => {
        return this.http.post<any>(`${SERVER_API_URL}api/runs/add-leg`, leg).toPromise();
    };

    addTrunkToRun = (trunk: ITrunk): Promise<IRun> => {
        return this.http.post<any>(`${SERVER_API_URL}api/runs/add-trunk`, trunk).toPromise();
    };

    removeLegFromRun = (legId: number): Promise<IRun> => {
        return this.http.post<any>(`${SERVER_API_URL}api/runs/remove-leg`, legId).toPromise();
    };

    copyRun = (params: RunCopyParameters): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/runs/copy-run';
        return this.http.post<any>(REQUEST_URI, params).toPromise();
    };

    getRunSiteVisits = (runId: number, errorSeverity?: ErrorSeverity): Promise<any> => {
        return this.http
            .get(SERVER_API_URL + `api/runs/${runId}/site-visits`, {
                context: createRequestContext({errorSeverity})
            })
            .toPromise();
    };

    getSiteVisitList = (siteVisitIds: number[], errorSeverity = ErrorSeverity.ERROR): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/runs/site-visit-list', siteVisitIds, {context: createRequestContext({errorSeverity})})
            .toPromise();
    };

    loadingList = (runId: number, preview = false): Promise<any> => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        return this.http
            .get<any>(`${SERVER_API_URL}api/runs/${runId}/loading-list`, {
                responseType: type as 'json'
            })
            .toPromise();
    };

    getEmailAudits = (runId: number): Promise<any> => {
        return this.http.get(SERVER_API_URL + `api/runs/${runId}/mail-audits`).toPromise();
    };

    getRunWithExceptionCatch = (runId: number): Promise<any> => {
        return this.http.get(SERVER_API_URL + `api/runs/${runId}/catch-exception`).toPromise();
    };

    getManifests = (runId: number): Promise<any> => {
        return this.http.get(SERVER_API_URL + `api/runs/${runId}/manifests`).toPromise();
    };

    getOrderDocuments = (runId: number): Promise<any> => {
        return this.http.get(SERVER_API_URL + `api/runs/${runId}/order-docs`).toPromise();
    };

    getWarnings = (runId: number): Promise<any> => {
        return this.http.get(SERVER_API_URL + `api/runs/${runId}/warnings`).toPromise();
    };

    reRateRun = (runId: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/runs/${runId}/refresh-rates`, {}).toPromise();
    };

    changeBusinessType = (businessTypeId: number, runId: number): Promise<any> => {
        return this.http.post<any>(`${SERVER_API_URL}api/runs/${runId}/business-type/${businessTypeId}`, null).toPromise();
    };

    createRunBatch = (runId: number): Promise<any> => {
        return this.http.post<any>(`${SERVER_API_URL}api/pre-invoice-batchs/run/${runId}/create`, null).toPromise();
    };

    markRunAsReadyToInvoice = (runId: number): Promise<any> => {
        return this.http.post<any>(`${SERVER_API_URL}api/runs/${runId}/ready-to-invoice`, null).toPromise();
    };

    getRunsByIds = (ids: number[]): Promise<any> => {
        const params = createRequestOption({ids: ids});
        return this.http.get(SERVER_API_URL + `api/runs/ids`, {params: params}).toPromise();
    };

    public updatePlanningInfo = (runId: number, req: any): Promise<HttpResponse<any[]>> => {
        return this.http.post<any>(`${SERVER_API_URL}api/runs/${runId}/planning-info`, req, {observe: 'response'}).toPromise();
    };

    public updateShiftType = (runId: number, req: any): Promise<HttpResponse<any[]>> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/runs/${runId}/shift-type`, req, {
                observe: 'response'
            })
            .toPromise();
    };

    previewRun = (params: RunCreationParameters): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/run/preview/route`, params).toPromise();
    };

    getRunDeletionWarnings = (ids: number[]): Promise<any[]> => {
        const params = createRequestOption({runIds: ids});
        return this.http
            .get<any[]>(SERVER_API_URL + `api/runs/can-delete`, {
                params: params
            })
            .toPromise();
    };

    bulkCommunicateRuns = (runIds: any[]) => {
        const queryParams = new HttpParams().append('runIds', runIds.join(','));
        return this.http.put(SERVER_API_URL + `api/runs/communicate/bulk`, {}, {params: queryParams}).toPromise();
    };

    bulkUncommunicateRuns = (runIds: any[]) => {
        const queryParams = new HttpParams().append('runIds', runIds.join(','));
        return this.http.put(SERVER_API_URL + `api/runs/uncommunicate/bulk`, {}, {params: queryParams}).toPromise();
    };

    bulkAutoCallInRuns = (runIds: any[]) => {
        const queryParams = new HttpParams().append('runIds', runIds.join(','));
        return this.http.put(SERVER_API_URL + `api/runs/call-in/bulk/auto`, {}, {params: queryParams}).toPromise();
    };

    bulkRemoveCallInRuns = (runIds: any[]) => {
        const queryParams = new HttpParams().append('runIds', runIds.join(','));
        return this.http.put(SERVER_API_URL + `api/runs/remove-call-in/bulk`, {}, {params: queryParams}).toPromise();
    };

    recalculateETAs = (runId): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/run/${runId}/re-calculate-etas`, null).toPromise();
    };

    getRunAssociatedOrders = (orderId: any): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/run/${orderId}/associated-orders`, {}).toPromise();
    };

    bulkUpdatePoints = (params: ILegLocationChange): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/run/update-legs-points';
        return this.http.post<IRun>(REQUEST_URI, params).toPromise();
    };

    bulkReRate = (ids: any): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/run/bulk-rerate/' + ids;
        return this.http.post(REQUEST_URI, {}).toPromise();
    };

    updateTrunkLocation = (runId, stopId, pointId): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/run/${runId}/stop/${stopId}`;
        return this.http.post<IRun>(REQUEST_URI, pointId).toPromise();
    };

    addMultiTrunk = (id, dateTime: Date, legId): Promise<any> => {
        const dateTimeParam = dateTime.toISOString();
        const REQUEST_URI = `${SERVER_API_URL}api/runs/add-multi-trunk/${id}/${legId}/${dateTimeParam}`;
        return this.http.post<IRun>(REQUEST_URI, {}).toPromise();
    };

    addMultiDestination = (id, dateTime: Date, runId): Promise<any> => {
        const dateTimeParam = dateTime.toISOString();
        const REQUEST_URI = `${SERVER_API_URL}api/runs/add-multi-destination/${id}/${runId}/${dateTimeParam}`;
        return this.http.post<IRun>(REQUEST_URI, {}).toPromise();
    };

    public generatePil = async (runIds: any[], preview = false): Promise<any> => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        const runIdsString = runIds.join(',');
        return await this.http
            .get<any>(`${SERVER_API_URL}api/runs/${runIdsString}/generate-pil`, {
                responseType: type as 'json'
            })
            .toPromise();
    };
}
