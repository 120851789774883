import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {DiSelectFormlessComponent} from 'app/common/di-forms/components/di-select-formless/di-select-formless.component';

@NgModule({
    declarations: [DiSelectFormlessComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NzSelectModule,
        NzFormModule,
        NzToolTipModule,
        NzIconModule
    ],
    exports: [DiSelectFormlessComponent],
    providers: []
})
export class DiSelectFormlessModule {}
