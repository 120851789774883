import {Person} from 'app/blocks/model/person.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IPerson} from 'app/blocks/model/person.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PersonService extends EntityCrudService<IPerson> {
    constructor(http: HttpClient) {
        super(http, Resource.PERSONS, (obj?) => new Person(obj));
    }
}
