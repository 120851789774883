import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {DiDate} from 'app/blocks/util/di-date';
import {ILeg} from 'app/blocks/model/leg.model';
import {OptimisedRunStatus} from 'app/blocks/model/enums/OptimisedRunStatus.enum';

export interface IRun extends IBaseEntity {
    id?: number;
    date: any;
    communicated: boolean;
    resourced: boolean;
    completed: boolean;
    runStatus: string;
    legs: ILeg[];
    firstPointDescription: string;
    lastPointDescription: string;
    customerNames: string[];
    customerRates: any[];
    businessTypeId: number;
    subContractorRates: any[];
    optimisedRunStatus: OptimisedRunStatus;
    subContractorInvoiceIds: any[];
    invoiceNo: any;
    invoiceId: any;
}

export class Run extends BaseEntity implements IRun {
    public id?: number;
    public date: DiDate;
    public communicated: boolean;
    public resourced: boolean;
    public completed: boolean;
    public runStatus: string;
    public legs: ILeg[];
    public firstPointDescription: string;
    public lastPointDescription: string;
    public customerNames: string[];
    public loadNumbers: string[];
    public customerRates: any[];
    public businessTypeId: number;
    public subContractorRates: any[];
    public optimisedRunStatus: OptimisedRunStatus;
    public subContractorInvoiceIds: any[];
    public invoiceNo: any;
    public invoiceId: any;

    constructor(run?: any) {
        super();
        if (run) {
            this.id = run.id;
            this.date = run.date;
            this.communicated = run.communicated;
            this.resourced = run.resourced;
            this.completed = run.completed;
            this.runStatus = run.runStatus;
            this.legs = run.legs;
            this.firstPointDescription = run.firstPointDescription;
            this.lastPointDescription = run.lastPointDescription;
            this.customerNames = run.customerNames;
            this.loadNumbers = run.loadNumbers;
            this.customerRates = run.customerRates;
            this.businessTypeId = run.businessTypeId;
            this.subContractorRates = run.subContractorRates;
            this.optimisedRunStatus = run.optimisedRunStatus;
            this.subContractorInvoiceIds = run.subContractorInvoiceIds;
            this.invoiceNo = run.invoiceNo;
            this.invoiceId = run.invoiceId;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Run {
        return new Run(this);
    }
}
