import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ILeg} from 'app/blocks/model/leg.model';

export interface IManifest extends IBaseEntity {
    id?: number;
    manifestTitle: string;
    date: Date;
    fileIsGenerated: boolean;
    manifestOptions?: IManifestOptions;
    manifestRun?: any;
    createDate?: Date;
    createdBy?: string;
    lastUpdate?: Date;
    updatedBy?: string;
    drivers?: string[];
    run?: any;
}

export class Manifest extends BaseEntity implements IManifest {
    id?: number;
    manifestTitle: string;
    date: Date;
    fileIsGenerated: boolean;
    manifestOptions?: any;
    manifestRun?: any;
    createDate?: Date;
    createdBy?: string;
    lastUpdate?: Date;
    updatedBy?: string;
    drivers?: string[];
    run?: any;

    constructor(manifest?: any) {
        super();
        if (manifest) {
            this.id = manifest.id;
            this.manifestTitle = manifest.manifestTitle;
            this.date = manifest.date;
            this.fileIsGenerated = manifest.fileIsGenerated;
            this.manifestOptions = manifest.manifestOptions;
            this.manifestRun = manifest.manifestRun;
            this.createDate = manifest.createDate;
            this.createdBy = manifest.createdBy;
            this.lastUpdate = manifest.lastUpdate;
            this.updatedBy = manifest.updatedBy;
            this.drivers = manifest.drivers ?? [];
            this.run = manifest.run;
        }
        this.drivers = [];
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Manifest {
        return new Manifest(this);
    }
}

export interface IManifestOptions {
    id: number;
    runIds: any[];
    excludeFirstRow: boolean;
    showFullAddress: boolean;
    noBlankRows: number;
}
