import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {INominalCode, NominalCode} from 'app/blocks/model/nominal-code.model';
import {IBusinessType} from 'app/blocks/model/business-type.model';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class NominalCodeService extends EntityCrudService<INominalCode> {
    constructor(http: HttpClient) {
        super(http, Resource.NOMINAL_CODES, (obj?) => new NominalCode(obj));
    }

    getBusinessTypesForNominalCode = (id: number): Promise<any[]> => {
        return this.http.get<IBusinessType[]>(`${SERVER_API_URL}api/nominal-codes/${id}/business-types`).toPromise();
    };
}
