import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from 'app/app.constants';
import {Account} from 'app/core/user/account.model';
import {SuperUserService} from 'app/core/auth/super-user.service';

@Injectable()
export class AccountService {
    isSuperuser = false;
    constructor(
        private http: HttpClient,
        private superUserService: SuperUserService
    ) {}

    get(): Observable<HttpResponse<Account>> {
        this.isSuperuser = this.superUserService.getIsSuperUser();
        return this.http.get<Account>(SERVER_API_URL + 'api' + (this.isSuperuser ? '/super-admin' : '') + '/account', {
            observe: 'response'
        });
    }

    save(account: any): Observable<HttpResponse<any>> {
        this.isSuperuser = this.superUserService.getIsSuperUser();
        return this.http.post(SERVER_API_URL + 'api' + (this.isSuperuser ? '/super-admin' : '') + '/account', account, {
            observe: 'response'
        });
    }

    requestPasswordReset(username: string, mail: string, tenant: string): Observable<HttpResponse<any>> {
        const data = {
            username: username,
            tenant: tenant,
            mail: mail
        };
        this.isSuperuser = this.superUserService.getIsSuperUser();
        return this.http.post(SERVER_API_URL + 'api' + (this.isSuperuser ? '/super-admin' : '') + '/account/reset-password/init', data, {
            observe: 'response'
        });
    }

    finishPasswordReset(tenant: string, key: string, newPassword: string): Observable<any> {
        const data = {
            tenant: tenant,
            key: key,
            newPassword: newPassword
        };
        this.isSuperuser = this.superUserService.getIsSuperUser();
        return this.http.post(SERVER_API_URL + 'api' + (this.isSuperuser ? '/super-admin' : '') + '/account/reset-password/finish', data, {
            observe: 'response'
        });
    }
}
