import {Directive, ElementRef, Input, OnInit} from '@angular/core';

export interface DiFormConfig {
    name: string;
}

@Directive({
    selector: '[diFormConfig]'
})
export class DiFormConfigDirective implements OnInit {
    @Input('diFormConfig') formConfig: DiFormConfig;

    private _name: string;

    constructor(private elRef: ElementRef) {}

    ngOnInit(): void {
        if (this.formConfig && this.formConfig.name) {
            this._name = this.formConfig.name;
        } else if (this.elRef.nativeElement instanceof HTMLFormElement) {
            const form: HTMLFormElement = this.elRef.nativeElement as HTMLFormElement;
            this._name = form.name;
        }
    }

    get name(): string {
        return this._name;
    }
}
