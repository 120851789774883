import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CountryService} from 'app/blocks/service/api/country.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICountry} from 'app/blocks/model/country.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Country} from 'app/blocks/model/country.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CountryResource extends AbstractEntityResource<ICountry> {
    constructor(countryService: CountryService, router: Router) {
        super(
            Resource.COUNTRIES,
            countryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.COUNTRIES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.COUNTRIES),
            (entity?) => new Country(entity),
            (entity) => entity.code
        );
    }
}
