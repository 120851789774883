import {DEFAULT_ENTITY_NAMED_LIST} from 'app/app.constants';

export interface PartialEntityStateModel<T> {
    partialEntity: {
        [name: string]: {
            list: T[];
            loaded: boolean;
        };
    };
}

export const defaultPartialEntityState = () => {
    return {
        partialEntity: {
            [DEFAULT_ENTITY_NAMED_LIST]: {
                list: [],
                loaded: false
            }
        }
    };
};
