export interface IContainerType {
    id?: number;
    type?: string;
}

export class ContainerType implements IContainerType {
    public id?: number;
    public type?: string;

    constructor(containerType?: any) {
        if (containerType) {
            this.id = containerType.id;
            this.type = containerType.type;
        } else {
            this.id = 0;
            this.type = '';
        }
    }

    clone(): ContainerType {
        return new ContainerType(this);
    }
}
