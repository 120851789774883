import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {IClausedDeliveryReason, ClausedDeliveryReason} from 'app/blocks/model/claused-delivery-reason.model';

@Injectable({
    providedIn: 'root'
})
export class ClausedDeliveryReasonService extends EntityCrudService<IClausedDeliveryReason> {
    constructor(http: HttpClient) {
        super(http, Resource.CLAUSED_DELIVERY_REASONS, (obj?) => new ClausedDeliveryReason(obj));
    }

    public getLite = (): Promise<ClausedDeliveryReason[]> => {
        return this.http.get<any[]>(`${SERVER_API_URL}api/claused-delivery-reasons-lite`).toPromise();
    };
}
