// SERVER API
// export const SERVER_API_URL = 'https://demo.routemagic.co.uk:8443/';

import {environment} from 'environments/environment';
import {TourPlanningMode} from 'app/blocks/model/tour-plan-parameters';

export const SERVER_API_URL = environment.serverApiUrl;

export const TRACKING_API_URL = environment.trackingApiUrl;

// export const POD_SERVER_API_URL = 'http://localhost:5001/';

export const POD_SERVER_API_URL = environment.podServerApiUrl; // use this if there's no POD server

// PAGE SETTINGS
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];

// DATE FORMAT (to be used with DatePickers)
export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

// API
export const DEFAULT_ENTITY_NAMED_LIST = 'primary';

// SECTION URLs
export const ENTITIES_SETUP_BASE_URL = '/setup';
export const ENTITIES_LEG_PLANNING_BASE_URL = '/leg-planning';
export const ENTITIES_TARIFFS_BASE_URL = '/tariff';
export const ENTITIES_ORDERS_BASE_URL = '/orders';
export const ENTITIES_RUNS_BASE_URL = '/runs';
export const ENTITIES_MANIFEST_BASE_URL = '/manifests';
export const ENTITIES_VEHICLE_INSPECTIONS_BASE_URL = '/vehicle-inspections';
export const LOGIN_API_URL = 'login';
export const HOME_URL = ['home'];
export const ENTITIES_EXPENSES_BASE_URL = '/expense';
export const NUMBER_OF_ITEMS_TO_ACCEPT_FROM_OBSERVER = 1;
export const ENTITIES_SUPER_ADMIN_SETUP_BASE_URL = '/super-admin-setup';

// DEBUG FLAGS
export const DEBUG_FORMS = false;

export const DEBUG_PAGE_PARAMS = false;
export const DEBUG_ROLE_KEYS = false;

export const DECIMAL_PLACES = 2;
export const TRANSLATION = 'translation';
export const COMPANY_SETTINGS = 'companySettings';
export const ASYNC_TASKS = 'asyncTasks';
export const ROLE_SETTINGS = 'roleSettings';
export const TENANT_SETTINGS = 'tenantSettings';
export const AUTO_FILL_ENTITY_COUNT = 10;
export const S3_TEST_BUCKET = 'chiltern-test-di';
export const S3_BUCKET_PREFIX = 'hauliermagic-tenant-';
export const S3_BUCKET_SUFFIX = '.s3.amazonaws.com/';

// HERE MAPS TOUR PLANNING API CREDENTIALS
export const HERE_MAPS_TOUR_PLANNING_APP_ID = environment.heremapsTourPlanningCredentials.appId;
export const HERE_MAPS_TOUR_PLANNING_API_KEY = environment.heremapsTourPlanningCredentials.apiKey;

export const DEFAULT_TOUR_PLANNING_MODE = TourPlanningMode.REAL_TIME;
export const ENV = environment.env;
export const DEFAULT_ENTITY_SOURCE = 'ERP';
export const ENTITY_SOURCE_EXCEL_IMPORT = 'EXCEL_IMPORT';
export const ENTITY_SROUCE_ENTITY_IMPORT = 'ENTITY_IMPORT';

export const PROJECTION_FULL = 'full';

export const REGEX_GOOGLE_SHEET_URL = new RegExp('https://docs.google.com/spreadsheets/d/[^/]+/*');
