import {IOrganisation} from 'app/blocks/model/organisation.model';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ActionMeta, IEntityAction} from 'app/blocks/store/actions/entity-actions';

export interface ICreateOrganisation extends IEntityAction {
    entity?: IOrganisation;
    logo?: Blob;
    width?: number;
    height?: number;
}

export interface IUpdateOrganisation extends IEntityAction {
    entity?: IOrganisation;
    logo?: Blob;
    width?: number;
    height?: number;
}

export class CreateOrganisation implements ICreateOrganisation {
    static readonly type = '[Organisation] Create Organisation';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IOrganisation,
        meta?: ActionMeta
    ) {}
}

export class UpdateOrganisation implements IUpdateOrganisation {
    static readonly type = '[Organisation] Update Organisation';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IOrganisation,
        meta?: ActionMeta
    ) {}
}
