import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IMetric extends IBaseEntity {
    id?: number;
    description?: string;
}

export class Metric extends BaseEntity implements IMetric {
    public id?: number;
    public description: string;

    constructor(metric?: any) {
        super();
        if (metric) {
            this.id = metric.id;
            this.description = metric.description;
        }
    }

    get discriminator(): any {
        return this.description;
    }

    clone(): Metric {
        return new Metric(this);
    }
}
