import {FuseNavigation} from '@fuse/types';

export const superAdminNavigation: FuseNavigation[] = [
    {
        id: 'tenant-management',
        title: 'Tenant Management',
        translate: 'NAV.TENANT_MANAGEMENT.TITLE',
        type: 'group',
        children: [
            {
                id: 'tenants',
                title: 'Tenants',
                translate: 'NAV.TENANTS.TITLE',
                type: 'item',
                icon: 'group',
                url: '/super-admin-setup/tenants'
            },
            {
                id: 'profiles',
                title: 'Profiles',
                translate: 'NAV.PROFILES.TITLE',
                type: 'item',
                icon: 'group',
                url: '/super-admin-setup/profiles'
            },
            {
                id: 'backups',
                title: 'Backups',
                translate: 'NAV.BACKUPS.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/backups'
            },
            {
                id: 'restores',
                title: 'Restores',
                translate: 'NAV.RESTORES.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/restores'
            },
            {
                id: 'super-config',
                title: 'Super Config',
                translate: 'NAV.SUPER_CONFIG.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/super-config'
            }
        ]
    },
    {
        id: 'database-management',
        title: 'Database Management',
        translate: 'NAV.DATABASE_MANAGEMENT.TITLE',
        type: 'group',
        children: [
            {
                id: 'dbuser',
                title: 'DB Users',
                translate: 'NAV.DB_USER.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/dbuser'
            },
            {
                id: 'advance-queries',
                title: 'Advance Queries',
                translate: 'NAV.ADVANCE_QUERIES.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/advance-queries'
            },
            {
                id: 'custom-queries',
                title: 'Custom Queries',
                translate: 'NAV.CUSTOM_QUERIES.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/custom-queries'
            }
        ]
    },
    {
        id: 'app-management',
        title: 'App Management',
        translate: 'NAV.APP_MANAGEMENT.TITLE',
        type: 'group',
        children: [
            {
                id: 'app-version',
                title: 'App Versions',
                translate: 'NAV.APP_VERSION.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/app-version'
            }
        ]
    },
    {
        id: 'integration-management',
        title: 'Integration Management',
        translate: 'NAV.INTEGRATION_MANAGEMENT.TITLE',
        type: 'group',
        children: [
            {
                id: 'integration-mappings',
                title: 'Integration Mappings',
                translate: 'NAV.INTEGRATION_MAPPINGS.TITLE',
                type: 'item',
                icon: 'share',
                url: '/super-admin-setup/integration-mappings'
            }
        ]
    }
];
