import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {AttemptedDelivery, IAttemptedDelivery} from 'app/blocks/model/attempted-delivery-model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';

@Injectable({
    providedIn: 'root'
})
export class AttemptedDeliveryService extends EntityCrudService<IAttemptedDelivery> {
    constructor(http: HttpClient) {
        super(http, Resource.ATTEMPTED_DELIVERY, (obj?) => new AttemptedDelivery(obj));
    }

    public saveAttemptedDelivery = (attemptedDelivery: AttemptedDelivery): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/attempted-deliveries`;
        return this.http.post<any>(REQUEST_URI, attemptedDelivery, {observe: 'response'}).toPromise();
    };

    public updateAttemptedDelivery = (attemptedDelivery: AttemptedDelivery): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/attempted-deliveries`;
        return this.http.put<any>(REQUEST_URI, attemptedDelivery, {observe: 'response'}).toPromise();
    };

    public getAttemptedDeliveries = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/attempted-deliveries/run/${id}`;
        return this.http.get<any>(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public getAttemptedDeliveriesForOrder = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/attempted-deliveries/order/${id}`;
        return this.http.get<any>(REQUEST_URI, {observe: 'response'}).toPromise();
    };
    public getAttemptedDeliveriesById = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/attempted-deliveries/${id}`;
        return this.http.get<any>(REQUEST_URI, {observe: 'response'}).toPromise();
    };
}
