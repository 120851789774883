import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {DiDate} from 'app/blocks/util/di-date';
import {Stop} from 'app/blocks/model/stop.model';
import {ITpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';
import {ResourceIcon} from 'app/blocks/model/resource-icon.model';

export class DriverLite {
    id: number;
    payrollNumber: string;
    name: string;
}

export interface IDriver extends IBaseEntity {
    id?: number;
    payrollNo: string;
    agencyDriver: boolean;
    supplierDriver: boolean;
    subcontractorDriver: boolean;
    startDate?: any;
    defaultVehicleId?: number;
    defaultVehicleName?: string;
    now?: Stop[];
    next?: Stop[];
    tomorrow?: Stop[];
    agencyId?: number;
    agencyName?: string;
    supplierId?: number;
    supplierName?: string;
    subcontractorId?: number;
    subcontractorName?: string;
    firstName: string;
    lastName: string;
    displayName?: string;
    fullName?: string;
    name?: string;
    dob: any;
    deleted: boolean;
    contactInfo?: IContactInfo;
    password?: string;
    login?: string;
    registerUser?: boolean;
    driverTypeId?: number;
    driverTypeName?: string;
    authorities?: any[];
    travelNotes?: string;
    perHourCost?: number;
    perDayCost?: number;
    payPerDay?: boolean;
    userId?: number;
    driverSubTypeId?: number;
    driverSubType?: any;
    defaultDepotId?: number;
    handheldProfileId?: number;
    gpsUnit: string;
    tachoCard: string;
    tp_driver_skills?: ITpVehicleSkill[];
    resourceIcons?: ResourceIcon[];
    emergencyContact?: IContactInfo;
    showEmergencyContact?: boolean;
    controlAreaId?: number;
    trafficAreaId?: number;
    driverDepotId: any;
    plannerId: any;
    roles: any;
    defaultTrailerId?: number;
    defaultTrailerRef?: string;
    startTime?: any;
    endTime?: any;
    lastLocationOrganisationId?: number;
    lastLocationOrganisationName?: string;
    lastLocationId?: number;
    lastLocationName?: string;
    strikes?: any[];
}

export class Driver extends BaseEntity implements IDriver {
    public id?: number;
    public payrollNo: string;
    public agencyDriver: boolean;
    public supplierDriver: boolean;
    public subcontractorDriver: boolean;
    public startDate?: any;
    public defaultVehicleId?: number;
    public driverTypeName?: string;
    public firstName: string;
    public lastName: string;
    public displayName?: string;
    public fullName?: string;
    public name?: string;
    public dob: any;
    public contactInfo?: IContactInfo;
    public agencyId?: number;
    public agencyName?: string;
    public defaultVehicleName?: string;
    public now: Stop[];
    public next: Stop[];
    public tomorrow: Stop[];
    public password?: string;
    public login?: string;
    public deleted: boolean;
    public registerUser?: boolean;
    public driverTypeId?: number;
    public authorities?: any[];
    public travelNotes?: string;
    public perHourCost?: number;
    public perDayCost?: number;
    public payPerDay?: boolean;
    public userId?: number;
    public driverSubTypeId?: number;
    public driverSubType?: any;
    public defaultDepotId?: number;
    public handheldProfileId?: number;
    public gpsUnit: string;
    public tachoCard: string;
    public tp_driver_skills?: ITpVehicleSkill[];
    public resourceIcons?: ResourceIcon[];
    public emergencyContact?: IContactInfo;
    public showEmergencyContact?: boolean;
    public controlAreaId?: number;
    public trafficAreaId?: number;
    public driverDepotId: any;
    public plannerId: any;
    public roles: any;
    public defaultTrailerId?: number;
    public defaultTrailerRef?: string;
    public tachoCardExpiryDate?: any;
    public cpcInfo?: string;
    public startTime?: any;
    public endTime?: any;
    public subcontractorId?: number;
    public subcontractorName?: string;
    public supplierId?: number;
    public supplierName?: string;
    public lastLocationOrganisationId?: number;
    public lastLocationOrganisationName?: string;
    public lastLocationId?: number;
    public lastLocationName?: string;
    public strikes?: any[];

    constructor(driver?: any) {
        super();
        if (driver) {
            this.id = driver.id;
            this.payrollNo = driver.payrollNo;
            this.agencyDriver = driver.agencyDriver;
            this.startDate = DiDate.newInstance(driver.startDate);
            this.defaultVehicleId = driver.defaultVehicleId;
            this.defaultVehicleName = driver.defaultVehicleName;
            this.firstName = driver.firstName;
            this.lastName = driver.lastName;
            this.fullName = driver.fullName;
            this.displayName = driver.displayName ? driver.displayName : driver.firstName + ' ' + driver.lastName;
            this.name = driver.name;
            this.dob = DiDate.newInstance(driver.dob);
            this.deleted = driver.deleted;
            this.agencyId = driver.agencyId;
            this.agencyName = driver.agencyName;
            this.contactInfo = driver.contactInfo;
            this.now = driver.now;
            this.next = driver.next;
            this.tomorrow = driver.tomorrow;
            this.password = driver.password;
            this.login = driver.login;
            this.registerUser = driver.registerUser;
            this.driverTypeId = driver.driverTypeId;
            this.driverTypeName = driver.driverTypeName;
            this.authorities = driver.authorities;
            this.travelNotes = driver.travelNotes;
            this.perHourCost = driver.perHourCost;
            this.perDayCost = driver.perDayCost;
            this.payPerDay = driver.payPerDay;
            this.userId = driver.userId;
            this.driverSubTypeId = driver.driverSubTypeId;
            this.driverSubType = driver.driverSubType;
            this.defaultDepotId = driver.defaultDepotId;
            this.handheldProfileId = driver.handheldProfileId;
            this.gpsUnit = driver.gpsUnit;
            this.tachoCard = driver.tachoCard;
            this.tp_driver_skills = driver.tp_driver_skills;
            this.emergencyContact = driver.emergencyContact;
            this.showEmergencyContact = driver.showEmergencyContact;
            this.controlAreaId = driver.controlAreaId;
            this.trafficAreaId = driver.trafficAreaId;
            this.driverDepotId = driver.driverDepotId;
            this.plannerId = driver.plannerId;
            this.roles = driver.roles;
            this.defaultTrailerId = driver.defaultTrailerId;
            this.defaultTrailerRef = driver.defaultTrailerRef;
            this.cpcInfo = driver.cpcInfo;
            this.tachoCardExpiryDate = driver.tachoCardExpiryDate;
            this.startTime = driver.startTime;
            this.endTime = driver.endTime;
            this.subcontractorDriver = driver.subcontractorDriver;
            this.supplierDriver = driver.supplierDriver;
            this.subcontractorId = driver.subcontractorId;
            this.supplierId = driver.supplierId;
            this.supplierName = driver.supplierName;
            this.subcontractorName = driver.subcontractorName;
            this.lastLocationOrganisationId = driver.lastLocationOrganisationId;
            this.lastLocationOrganisationName = driver.lastLocationOrganisationName;
            this.lastLocationId = driver.lastLocationId;
            this.lastLocationName = driver.lastLocationName;
            this.resourceIcons = driver.resourceIcons;
            this.strikes = driver.strikes;
        } else {
            this.contactInfo = new ContactInfo();
            this.tp_driver_skills = [];
            this.strikes = [];
        }
    }

    get discriminator(): any {
        return this.firstName;
    }

    clone(): Driver {
        return new Driver(this);
    }
}
