import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IAppVersion extends IBaseEntity {
    id?: number;
    version?: string;
    depreciatedAndroid?: boolean;
    depreciatedIos?: boolean;
}

export class AppVersion extends BaseEntity implements IAppVersion {
    id?: number;
    version?: string;
    depreciatedAndroid?: boolean;
    depreciatedIos?: boolean;

    constructor(appVersion?) {
        super();
        if (appVersion) {
            this.id = appVersion.id;
            this.version = appVersion.version;
            this.depreciatedAndroid = appVersion.depreciatedAndroid;
            this.depreciatedIos = appVersion.depreciatedIos;
        }
    }

    clone(): AppVersion {
        return new AppVersion(this);
    }

    get discriminator(): any {
        return this.id;
    }
}
