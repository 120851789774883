import {DiDate} from 'app/blocks/util/di-date';
import {DiTime} from 'app/blocks/util/di-time';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITpAssetProfile extends IBaseEntity {
    id?: number;
    profile_name: string;
    profile_type: string;
    departure_time?: any;
    truck_options?: TpTruckOption;
    avoidFeatures?: string[];
}

export class TpAssetProfile extends BaseEntity implements ITpAssetProfile {
    public id?: number;
    public profile_name: string;
    public profile_type: string;
    public departure_time?: any;
    public truck_options?: TpTruckOption;
    public avoidFeatures?: string[];

    constructor(tpAssetProfile?: any) {
        super();
        if (tpAssetProfile) {
            this.id = tpAssetProfile.id;
            this.profile_name = tpAssetProfile.profile_name;
            this.profile_type = tpAssetProfile.profile_type;
            this.departure_time = DiTime.newInstance(tpAssetProfile.departure_time);
            this.truck_options = tpAssetProfile.truck_options;
            this.avoidFeatures = tpAssetProfile.avoidFeatures;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TpAssetProfile {
        return new TpAssetProfile(this);
    }
}

export interface ITpTruckOption extends IBaseEntity {
    id?: number;
    grossWeight: number;
    weightPerAxle: number;
    height: number;
    width: number;
    length: number;
    tunnelCategory: string;
    shippedHazardousGoods?: string[];
}

export class TpTruckOption extends BaseEntity implements ITpTruckOption {
    public id?: number;
    public grossWeight: number;
    public weightPerAxle: number;
    public height: number;
    public width: number;
    public length: number;
    public tunnelCategory: string;
    public shippedHazardousGoods?: string[];

    constructor(tpTruckOption?: any) {
        super();
        if (tpTruckOption) {
            this.id = tpTruckOption.id;
            this.grossWeight = tpTruckOption.grossWeight;
            this.weightPerAxle = tpTruckOption.weightPerAxle;
            this.height = tpTruckOption.height;
            this.width = tpTruckOption.width;
            this.length = tpTruckOption.length;
            this.tunnelCategory = tpTruckOption.tunnelCategory;
            this.shippedHazardousGoods = tpTruckOption.shippedHazardousGoods;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TpAssetProfile {
        return new TpAssetProfile(this);
    }
}
