import {Resource} from 'app/constants/resource';
import {IPayment, Payment} from 'app/blocks/model/payment.model';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {Router} from '@angular/router';
import {PaymentService} from 'app/blocks/service/api/payment.service';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {faMoneyBillWave} from '@fortawesome/free-solid-svg-icons';

@Injectable({
    providedIn: 'root'
})
export class PaymentResource extends AbstractEntityResource<IPayment> {
    constructor(paymentService: PaymentService, router: Router) {
        super(
            Resource.PAYMENTS,
            paymentService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PAYMENTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PAYMENTS),
            (entity?) => new Payment(entity),
            (entity) => entity.id,
            faMoneyBillWave
        );
    }
}
