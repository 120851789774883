import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewEncapsulation,
    Optional,
    ViewChild,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';

import {DiFormElement} from 'app/common/di-forms/shared/di-form-element';
import {DiFormConfigDirective} from 'app/common/di-forms/directives/di-form.directive';
import {Sign} from 'app/blocks/model/sign.model';
import {forwardRef, HostBinding, ElementRef} from '@angular/core';
import {debounce, round} from 'lodash';
import {RoleService} from 'app/blocks/service/api/role.service';
import {LogService} from 'app/blocks/service/log.service';

export const formElementProvider: any = {
    provide: DiFormElement,
    useExisting: forwardRef(() => DiInputComponent)
};

@Component({
    selector: 'di-input',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{width: width, 'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span [ngClass]="field">
                        <span [ngClass]="field" [innerHTML]="label ? label : (fieldLabel$ | async)"></span>
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-input-group [nzSuffix]="suffix" [nzPrefix]="prefix">
                        <input
                            nz-input
                            #inputElement
                            id="{{ 'di-input-' + field + idSuffix }}"
                            [ngStyle]="{width: width, 'max-width': width}"
                            name="{{ field }}"
                            formControlName="{{ field }}"
                            type="{{ type }}"
                            [min]="min"
                            [max]="max"
                            maxlength="{{ maxlength }}"
                            [required]="fieldIsRequired"
                            [readonly]="!!readonly"
                            [placeholder]="placeholder"
                            [nzStepperless]="type !== 'number'"
                            (keydown.Tab)="onTab($event)"
                            (input)="onInput($event)"
                            (blur)="onBlur()" />
                    </nz-input-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./di-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiInputComponent extends DiFormElement implements OnInit, OnChanges {
    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        private renderer: Renderer2,
        fgDirective: FormGroupDirective,
        @Optional() diForm: DiFormConfigDirective,
        translateService: TranslateService,
        private _logService: LogService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, diForm);
    }

    @ViewChild('inputElement', {static: true})
    inputElement: ElementRef<HTMLElement>;

    faInfoIcon = faInfoCircle;
    prefix = null;
    suffix = null;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() min: number | null;
    @Input() max: number | null;
    @Input() maxlength: number | null;
    @Input() placeholder?: string = '';

    @Input() field: string | null;
    @Input() idSuffix = '';
    @Input() suppressLabel = false;
    @Input() label: string | null;
    @Input() integersOnly = false;
    @Input() suppressError = false;
    @Input() type = 'text';
    @Input() errorVariantMap: {[errorKey: string]: string};
    @Input() readonly = false;
    @Input() symbol: Sign = null;
    @Input() roleDependent = false;
    @Input() showField = true;
    @Input() roleSettingSuffix = '';
    @Input() enableTracking = false;

    // tslint:disable-next-line:no-output-native
    @Output() input = new EventEmitter<any>();
    @Input() onTab: (event) => any = (event) => {};
    @Output() blur = new EventEmitter<void>();

    ngOnInit(): void {
        super.ngOnInit();
        this.setSymbol(this.symbol);
        if (this.focus) {
            this.focusNow();
        }
    }

    focusNow(): void {
        setTimeout(() => this.inputElement.nativeElement.focus(), 0);
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Input Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onInput($event: any): any {
        if (this.integersOnly) {
            $event.target.value = parseInt(String(round($event.target.value)));
        }
        this.input.emit($event);
        if (this.enableTracking) {
            this.debouncedLog($event.target.value);
        }
    }

    onBlur(): void {
        this.blur.emit();
    }

    // get runChangeDetection(): string {
    // testing multi project release
    //     console.log(`Change detection cycle: ${++this.changecounter} ${this.field}`);
    //     return '';
    // }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.symbol) {
            this.setSymbol(changes.symbol.currentValue);
        }
    }

    setSymbol(symbol: Sign): void {
        this.symbol = symbol;
        if (this.symbol) {
            if (this.symbol.isPrefix()) {
                this.prefix = this.symbol.getCode();
                this.suffix = null;
            } else {
                this.suffix = this.symbol.getCode();
                this.prefix = null;
            }
        }
    }
}
