import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive()
export abstract class UnsubscribeDirective implements OnDestroy {
    protected _destroyed$ = new Subject<void>();

    constructor() {}

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
