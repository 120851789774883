// google autocomplete results will be limited to the area within these coordinates (Europe and Middle East)
export const autocompleteSouthWestBoundLat = -20.764558;
export const autocompleteSouthWestBoundLng = -20.853498;
export const autocompleteNorthEastBoundLat = 63.391522;
export const autocompleteNorthEastBoundLng = 85.957716;

export const googlePlacesCountryMapping = {
    GB: 'UK',
    MU: 'MA'
};
