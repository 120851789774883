import {ModuleWithProviders, NgModule} from '@angular/core';
import {DiConfirmDialogComponent} from 'app/common/dialog/confirm-dialog/confirm-dialog.component';
import {DiMessageDialogComponent} from 'app/common/dialog/message-dialog/message-dialog.component';
import {DialogService} from 'app/common/dialog/dialog-service';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DiFormsModule} from 'app/common/di-forms/di-forms.module';

@NgModule({
    declarations: [DiConfirmDialogComponent, DiMessageDialogComponent],
    imports: [NzModalModule, NzButtonModule, FormsModule, CommonModule, DiFormsModule, ReactiveFormsModule]
})
export class DialogModule {
    static forRoot(): ModuleWithProviders<DialogModule> {
        return {
            ngModule: DialogModule,
            providers: [DialogService]
        };
    }
}
