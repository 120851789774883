import {DiInlineEntityActionMenuModule} from 'app/common/di-inline-entity-action-menu/di-inline-entity-action-menu.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {DiErrorNotificationModule} from 'app/common/di-error-notification/di-error-notification.module';
import {DiInlineListPageHeaderModule} from 'app/common/di-inline-list-page-header/di-inline-list-page-header.module';
import {DiPageTotalItemsModule} from 'app/common/di-page-total-items/di-page-total-items.module';
import {DiFormsModule} from 'app/common/di-forms/di-forms.module';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzTableModule} from 'ng-zorro-antd/table';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DiInlineListPageHeaderModule,
        DiInlineEntityActionMenuModule,
        DiPageTotalItemsModule,
        DiErrorNotificationModule,
        PerfectScrollbarModule,
        FontAwesomeModule,
        DiFormsModule,
        NzTableModule,
        NzPaginationModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DiInlineListPageHeaderModule,
        DiInlineEntityActionMenuModule,
        DiPageTotalItemsModule,
        DiErrorNotificationModule,
        PerfectScrollbarModule,
        FontAwesomeModule,
        DiFormsModule,
        NzTableModule,
        NzPaginationModule,
        FlexLayoutModule
    ],
    providers: []
})
export class DiInlineEntityLibsModule {}
