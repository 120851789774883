import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ICountry} from 'app/blocks/model/country.model';
import {IApplyPaymentDTO, IPayment, Payment} from 'app/blocks/model/payment.model';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends EntityCrudService<IPayment> {
    constructor(http: HttpClient) {
        super(http, Resource.PAYMENTS, (obj?) => new Payment(obj));
    }

    public bulkUpdatePaymentStatus = (ids: string, status: string): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/payments/mark-as/' + status, ids, {
                observe: 'response'
            })
            .toPromise();
    };

    public getExportSettings = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/export-settings/payments`;
        return this.http.get(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public saveExportSettings = (exportSettings: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/export-settings/payments`;
        return this.http.put<any>(REQUEST_URI, exportSettings, {observe: 'response'}).toPromise();
    };

    applyPaymentToInvoices = (paymentId: string, invoices: IApplyPaymentDTO[]): any => {
        return this.http.post<any>(`${SERVER_API_URL}api/payments/${paymentId}/allocate`, invoices, {observe: 'response'}).toPromise();
    };

    public unAllocatePayment = (paymentAllocationId: any): Promise<any> => {
        const requestParams = new HttpParams().set('paymentAllocationId', paymentAllocationId);
        return this.http
            .post<any>(SERVER_API_URL + 'api/payments/un-allocate', null, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };
}
