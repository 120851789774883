import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalOptions, NzModalRef, NzModalService, NzModalState} from 'ng-zorro-antd/modal';
import {tap} from 'rxjs/operators';

import {DiConfirmDialogComponent} from 'app/common/dialog/confirm-dialog/confirm-dialog.component';
import {DiMessageDialogComponent} from 'app/common/dialog/message-dialog/message-dialog.component';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private modalService: NzModalService,
        private _translateService: TranslateService
    ) {}

    async showConfirm(dialogData: any = {}, params: any = {}, options?: any): Promise<Observable<any>> {
        const translatedData = await this.translateData(dialogData, params);
        let modal = this.modalService.create({
            nzContent: DiConfirmDialogComponent,
            nzData: {
                data: translatedData,
                includeNotes: options?.includeNotes
            },
            nzFooter: null,
            nzClosable: false,
            ...options
        });

        return modal.afterClose.pipe(
            tap(() => {
                modal = null;
            })
        );
    }

    async translateData(messageKeys: any, params: any): Promise<any> {
        const translatedData: any = {};
        const keys = Object.keys(messageKeys);
        const values = keys.map((key) => messageKeys[key]).filter((x) => x);

        const translations = await this._translateService.get(values, params).toPromise();
        for (let i = 0; i < keys.length; i++) {
            translatedData[keys[i]] = translations[values[i]];
        }
        return translatedData;
    }

    async showMessage(dialogData: any = {}, params: any = {}, options?: any, ref?: NzModalRef): Promise<NzModalRef> {
        const translatedData = await this.translateData(dialogData, params);
        const modalConfig: ModalOptions = {
            nzContent: DiMessageDialogComponent,
            nzData: {
                data: translatedData
            },
            nzFooter: null,
            nzClosable: false,
            ...options
        };

        if (ref?.state === NzModalState.OPEN) {
            ref.updateConfig(modalConfig);
            console.log('updating in same ref');
            return ref;
        }

        let modal = this.modalService.create(modalConfig);

        modal.afterClose.pipe(
            tap(() => {
                modal = null;
            })
        );

        return modal;
    }
}
