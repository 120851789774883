import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IControlArea, ControlArea} from 'app/blocks/model/control-area.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ControlAreaService extends EntityCrudService<IControlArea> {
    constructor(http: HttpClient) {
        super(http, Resource.CONTROL_AREAS, (obj?) => new ControlArea(obj));
    }

    applyControlAreaToOrganisations = (id: any, organisations: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/control-areas/apply/' + id, organisations, {observe: 'response'}).toPromise();
    };
}
