import {format} from 'date-fns';

export class DiTime {
    constructor(timeObj?: {hour: number; min: number}) {
        this.hour = timeObj.hour;
        this.min = timeObj.min;
    }
    hour: number;
    min: number;

    static getLocalDateTimeString(date: Date): string {
        date = new Date(date);
        console.log(date.toLocaleString());
        return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            '-' +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            'T' +
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
            ':' +
            (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        );
    }

    static getLocalDateTimeStringFromBoth(date: Date, time: Date): string {
        date = new Date(date);
        console.log(date.toLocaleString());
        return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            '-' +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            'T' +
            (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) +
            ':' +
            (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) +
            ':' +
            (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds())
        );
    }

    static getLocalDateTimeStartOfDayString(date: Date): string {
        date = new Date(date);
        console.log(date.toLocaleString());
        return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            '-' +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            'T00:00:00'
        );
    }

    static getLocalDateTimeEndOfDayString(date: Date): string {
        date = new Date(date);
        console.log(date.toLocaleString());
        return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            '-' +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            'T23:59:00'
        );
    }

    static getLocalDateString(date: Date): string {
        date = new Date(date);
        console.log(date.toLocaleString());
        return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            '-' +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
        );
    }

    static newInstance(obj: any): DiTime {
        let instance = null;
        if (obj) {
            if (typeof obj === 'string') {
                const timeString = obj.split(':');
                instance = new DiTime({
                    hour: +timeString[0],
                    min: +timeString[1]
                });
            } else if (obj instanceof Date) {
                instance = new DiTime({
                    hour: obj.getHours(),
                    min: obj.getMinutes()
                });
            } else if (obj instanceof DiTime) {
                instance = obj;
            }
        }
        return instance;
    }

    static currentTime(): DiTime {
        const now = new Date();
        return new DiTime({hour: now.getHours(), min: now.getMinutes()});
    }

    static compare(time1: DiTime, time2: DiTime): number {
        let result = 0;
        if (time1 && time2) {
            result = time1.hour - time2.hour;
            if (result == 0) {
                result = time1.min - time2.min;
            }
        }
        return result;
    }

    toString(): string {
        return `${this.padLeftZero(this.hour, 2)}:${this.padLeftZero(this.min, 2)}`;
    }

    toJSON(): string {
        return this.toString();
    }

    asDate(): Date {
        const timeAsDate = new Date();
        timeAsDate.setHours(this.hour, this.min);
        return timeAsDate;
    }

    private padLeftZero(numberToPad: number, noOfDigits: number): string {
        const str = '' + numberToPad;
        return ('0'.repeat(noOfDigits) + str).substring(str.length);
    }
}
