import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {ErrorSeverity, createRequestContext} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient) {}

    getNotifications = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/notifications`;
        return this.http
            .get(REQUEST_URI, {
                observe: 'response',
                context: createRequestContext({
                    errorSeverity: ErrorSeverity.IGNORE
                })
            })
            .toPromise();
    };

    markNotificationsAsRead = (): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/notifications/mark-all-as-seen';
        return this.http.post<any>(REQUEST_URI, null).toPromise();
    };
}
