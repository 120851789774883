import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DiRadioGroupComponent} from 'app/common/di-radio-group/di-radio-group.component';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzRadioModule} from 'ng-zorro-antd/radio';

@NgModule({
    declarations: [DiRadioGroupComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NzRadioModule,
        NzFormModule,
        NzToolTipModule,
        NzIconModule
    ],
    exports: [DiRadioGroupComponent],
    providers: []
})
export class DiRadioGroupModule {}
