import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TaxCategoryService} from 'app/blocks/service/api/tax-category.service';
import {Resource} from 'app/constants/resource';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ITaxCategory, TaxCategory} from 'app/blocks/model/tax-category.model';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';

@Injectable({
    providedIn: 'root'
})
export class TaxCategoryResource extends AbstractEntityResource<ITaxCategory> {
    constructor(taxCategoryService: TaxCategoryService, router: Router) {
        super(
            Resource.UK_VAT_RATES,
            taxCategoryService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.UK_VAT_RATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.UK_VAT_RATES),
            (entity?) => new TaxCategory(entity),
            (entity) => entity.code,
            'attach_money'
        );
    }
}
