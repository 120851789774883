import {Injectable} from '@angular/core';
import {DialogService} from 'app/common/dialog/dialog-service';
import {FuseProgressBarService} from '@fuse/components/progress-bar/progress-bar.service';
import {DiDate} from 'app/blocks/util/di-date';
import {format} from 'date-fns';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class CommonUtilsService {
    constructor(
        private _dialogService: DialogService,
        private _fuseProgressBarService: FuseProgressBarService,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService
    ) {}

    async showMessage(dialogData, params?): Promise<any> {
        await this._dialogService.showMessage(dialogData, params);
    }

    async showConfirm(dialogData): Promise<any> {
        return this._dialogService.showConfirm(dialogData);
    }

    hideFuseProgressbar(): void {
        // Hide the progress bar
        this._fuseProgressBarService.hide();
    }

    formatDate(date: string | DiDate | Date): string {
        if (date) {
            const diDate: DiDate = DiDate.newInstance(date);
            return format(diDate.asDate(), 'PP');
        }
        return '';
    }

    formatDateTime(date: string | Date): string {
        if (date) {
            const dateObj = new Date(date);
            return format(dateObj, 'dd/MM/yyyy p');
        }
        return '';
    }

    getToken(): string {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    }
}
