import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faPallet} from '@fortawesome/free-solid-svg-icons';
import {ENTITIES_ORDERS_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {ILeg, Leg} from 'app/blocks/model/leg.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {LegService} from 'app/blocks/service/api/leg-service';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class LegResource extends AbstractEntityResource<ILeg> {
    [x: string]: any;

    constructor(legService: LegService, router: Router) {
        super(
            Resource.HAULIER_ORDERS,
            legService,
            new EntityNavigator(ENTITIES_ORDERS_BASE_URL, Resource.LEGS, router),
            new EntityUrlProvider(ENTITIES_ORDERS_BASE_URL, Resource.LEGS),
            (entity?) => new Leg(entity),
            (entity) => entity.orderNo,
            faPallet
        );
    }
}
