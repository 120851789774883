import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {Observable, Subject} from 'rxjs';

import {DiFormConfigDirective} from 'app/common/di-forms/directives/di-form.directive';
import {DiFormElement} from 'app/common/di-forms/shared/di-form-element';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: DiFormElement,
    useExisting: forwardRef(() => DiRadioGroupComponent)
};

@Component({
    selector: 'di-radio-group',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="validationError$ | async">
                    <nz-radio-group formControlName="{{ field }}">
                        <label
                            *ngIf="addNullValue"
                            nz-radio
                            id="{{ 'di-radio-group-' + getValue(nullValueObject) + idSuffix }}"
                            [nzValue]="null"
                            [nzDisabled]="disabled"
                            (click)="selectOption(nullValueObject)">
                            {{ getLabel(nullValueObject) }}
                        </label>
                        <label
                            nz-radio
                            id="{{ 'di-radio-group-' + getValue(option) + idSuffix }}"
                            [nzValue]="getValue(option)"
                            [nzDisabled]="disabled"
                            *ngFor="let option of options$ | async"
                            (click)="selectOption(option)">
                            {{ getLabel(option) }}
                        </label>
                    </nz-radio-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./di-radio-group.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DiRadioGroupComponent extends DiFormElement implements OnInit {
    faInfoIcon = faInfoCircle;

    @Input() field: string;
    @Input() displayAttribute = 'label';
    @Input() valueAttribute = 'value';
    @Input() options$: Observable<any>;
    @Input() suppressLabel = false;
    @Input() compareFn: (item1, item2) => boolean;
    @Input() width = '100%';
    @Input() position: 'start' | 'center' | 'end' = 'start';
    @Input() idSuffix = '';
    @Input() disabled = false;
    @Input() addNullValue = false;
    @Input() nullValueObject = {label: 'Any', value: null};
    @Input() showField = true;
    @Input() roleSettingSuffix = '';

    @Output() selectionChange = new EventEmitter<any>();

    loading = false;
    userInput$ = new Subject<string>();

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() diForm: DiFormConfigDirective,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, diForm);
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.position !== 'center') {
            return 'flex-' + this.position;
        }
        return this.position;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.compareFn) {
            this.compareFn = this.defaultCompareFn;
        }
    }

    getLabel(option: any): string {
        if (option && this.displayAttribute) {
            return option[this.displayAttribute];
        }
        return option;
    }

    getValue(option: any): string {
        if (option && this.valueAttribute) {
            return option[this.valueAttribute];
        }
        return option;
    }

    defaultCompareFn = (c1, c2) => {
        if (c1 && c2) {
            return this.valueAttribute ? c1[this.valueAttribute] === c2[this.valueAttribute] : c1 === c2;
        } else {
            return c1 === c2;
        }
    };

    selectOption(option: any): void {
        if (option) {
            this.selectionChange.emit(this.getValue(option));
        }
    }
}
