import {User} from 'app/core/user/user.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ResourceModel} from 'app/blocks/model/resource.model';

export interface ITeam extends IBaseEntity {
    id?: number;
    shortName?: string;
    description?: string;
    resources?: ResourceModel[];
    users?: User[];
}

export class Team extends BaseEntity implements ITeam {
    public id?: number;
    public shortName?: string;
    public description?: string;
    public resources?: ResourceModel[];
    public users?: User[];

    constructor(team?: any) {
        super();
        if (team) {
            this.id = team.id;
            this.shortName = team.shortName;
            this.description = team.description;
            this.users = team.users;
            this.resources = team.resources;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Team {
        return new Team(this);
    }
}
