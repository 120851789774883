import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {ICurrency} from 'app/blocks/model/currency.model';
import {ITimeWindow, TimeWindow} from 'app/blocks/model/time-window.model';
import {IImage} from 'app/blocks/model/image.model';

export interface ICompany extends IBaseEntity {
    id?: number;
    name?: string;
    website?: string;
    vatRegNo?: string;
    registrationNo?: string;
    contactInfo?: IContactInfo;
    currency?: ICurrency;
    companyCode?: string;
    operationHours?: ITimeWindow[];
    writeSettings?: any;
    readSettings?: any[];
    companyLogo?: IImage;
}

export class Company extends BaseEntity implements ICompany {
    public id?: number;
    public name?: string;
    public website?: string;
    public vatRegNo?: string;
    public registrationNo?: string;
    public contactInfo?: IContactInfo;
    public currency?: ICurrency;
    public companyCode?: string;
    public operationHours?: ITimeWindow[];
    public writeSettings: any;
    public readSettings: any[];
    public companyLogo?: IImage;

    constructor(company?) {
        super();
        if (company) {
            this.id = company.id;
            this.name = company.name;
            this.website = company.website;
            this.vatRegNo = company.vatRegNo;
            this.registrationNo = company.registrationNo;
            this.contactInfo = new ContactInfo(company.contactInfo);
            this.currency = company.currency;
            this.companyCode = company.companyCode;
            this.operationHours = company.operationHours ? TimeWindow.processArray(company.operationHours) : [];
            this.writeSettings = company.writeSettings;
            this.readSettings = company.readSettings || [];
            this.companyLogo = company.companyLogo;
        } else {
            this.contactInfo = new ContactInfo();
            this.operationHours = [];
            this.readSettings = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Company {
        return new Company(this);
    }
}
