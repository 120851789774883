import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IScale, Scale} from 'app/blocks/model/scale.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ScaleService extends EntityCrudService<IScale> {
    constructor(http: HttpClient) {
        super(http, Resource.SCALES, (obj?) => new Scale(obj));
    }
}
