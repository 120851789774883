import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SuperUserService {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    setIsSuperUser(isSuperUser: boolean): void {
        window.localStorage.setItem('isSuperUser', String(isSuperUser));
    }

    getIsSuperUser(): boolean {
        if (window.localStorage.getItem('isSuperUser') != null) {
            return window.localStorage.getItem('isSuperUser') === 'true';
        }
        return false;
    }

    setDifferentUser(userId: number | null): void {
        if (userId == null) {
            window.localStorage.removeItem('differentUser');
            return;
        } else {
            window.localStorage.setItem('differentUser', String(userId));
            this.setNavigationForReload();
        }
    }

    setNavigationForReload(): void {
        window.localStorage.setItem('navigationReload', String(true));
    }

    navigationReload(reload: boolean = true) {
        if (window.localStorage.getItem('navigationReload') != null) {
            window.localStorage.removeItem('navigationReload');
            if (reload) window.location.reload();
        }
    }

    getDifferentUser(): number {
        if (window.localStorage.getItem('differentUser') != null) {
            return Number(window.localStorage.getItem('differentUser'));
        }
        return null;
    }
}
