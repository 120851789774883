import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AsyncRequest, IAsyncRequest} from 'app/blocks/model/async_request.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AsyncRequestService extends EntityCrudService<IAsyncRequest> {
    constructor(http: HttpClient) {
        super(http, Resource.ASYNC_REQUESTS, (obj?) => new AsyncRequest(obj));
    }

    public getInProgessAsyncRequest = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/async-requests/in-progress`;
        return this.http
            .get(REQUEST_URI, {
                observe: 'response'
            })
            .toPromise();
    };

    public retryAsyncRequest = (requestId): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/async-requests/retry/${requestId}`;
        return this.http
            .get(REQUEST_URI, {
                observe: 'response'
            })
            .toPromise();
    };
}
