import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {Zone} from 'app/blocks/model/zone.model';

export interface IZoneMap extends IBaseEntity {
    id?: number;
    zoneTypeId?: number;
    description?: string;
    isEnabled?: boolean;
    zones?: Zone[]; // the list of zones represents the X-axis on the physical rate table
}

export class ZoneMap extends BaseEntity implements IZoneMap {
    public id?: number;
    public zoneTypeId?: number;
    public description?: string;
    public isEnabled?: boolean;
    public zones?: Zone[];

    constructor(zoneMap?: any) {
        super();
        if (zoneMap) {
            this.id = zoneMap.id;
            this.zoneTypeId = zoneMap.zoneTypeId;
            this.description = zoneMap.description;
            this.isEnabled = zoneMap.isEnabled;
            this.zones = zoneMap.zones || [];
        }
    }

    get descriminator(): any {
        return this.description;
    }

    clone(): ZoneMap {
        return new ZoneMap(this);
    }
}
