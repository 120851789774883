export interface IUrlProvider {
    forHome(): string;
}

export interface IEntityUrlProvider extends IUrlProvider {
    forList(): string;
    forView(id: number): string;
    forEdit(id: number): string;
    forNew(): string;
}

export class EntityUrlProvider implements IEntityUrlProvider {
    private _resourcePath: string;

    constructor(
        private _basePath: string,
        private _resourceName: string
    ) {
        this._resourcePath = this.camelCaseToDashed(this._resourceName);
    }

    forHome(): string {
        return this.forList();
    }

    forList(): string {
        return this._basePath + '/' + this._resourcePath;
    }

    forView(id: number): string {
        return this._basePath + '/' + this._resourcePath + '/' + id;
    }

    forEdit(id: number): string {
        return this._basePath + '/' + this._resourcePath + '/' + id + '/edit';
    }

    forNew(): string {
        return this._basePath + '/' + this._resourcePath + '/new';
    }

    camelCaseToDashed(str): string {
        return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }
}
