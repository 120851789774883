import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {CreditNote, IApplyCreditNoteDTO, ICreditNote} from 'app/blocks/model/credit-note.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CreditNoteService extends EntityCrudService<ICreditNote> {
    constructor(http: HttpClient) {
        super(http, Resource.CREDIT_NOTES, (obj?) => new CreditNote(obj));
    }

    generatePdf = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/credit-notes/' + id + '/generate-pdf', null, {observe: 'response'}).toPromise();
    };

    public approveCreditNote = (creditNoteId): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/credit-notes/' + creditNoteId + '/approve' + status, null, {observe: 'response'})
            .toPromise();
    };

    applyCreditNotesToInvoices = (creditNoteId: string, invoices: IApplyCreditNoteDTO[]): any => {
        const allocationInvoiceCreditNotes = invoices.map((allocation) => {
            return {invoiceId: allocation.invoice.id, amount: allocation.amount};
        });
        return this.http
            .post<any>(`${SERVER_API_URL}api/credit-notes/${creditNoteId}/allocate`, allocationInvoiceCreditNotes, {observe: 'response'})
            .toPromise();
    };

    public downloadPDF = (creditNoteId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/credit-notes/${creditNoteId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    public downloadMergedCreditNotes = (creditNoteIds: number[]): Promise<any> => {
        const requestParams = new HttpParams().set('ids', creditNoteIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/credit-notes/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    public saveCreditNote(creditNote: any) {
        return this.http
            .post<any>(SERVER_API_URL + 'api/credit-notes/', creditNote, {
                observe: 'response'
            })
            .toPromise();
    }

    public postCreditNote = (id: number): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/credit-notes/${id}/post`, null, {
                observe: 'response'
            })
            .toPromise();
    };

    public getInvoicesAppliedTo = (id: number): Promise<any[]> => {
        return this.http.get<any[]>(SERVER_API_URL + `api/credit-notes/${id}/invoices`).toPromise();
    };

    public async markAsUnexported(ids): Promise<void> {
        await this.http.put(SERVER_API_URL + 'api/credit-notes/mark-unexported', null, {params: {ids: ids}}).toPromise();
    }

    public async markAsNotPosted(ids): Promise<void> {
        await this.http.put(SERVER_API_URL + 'api/credit-notes/mark-as-not-posted', null, {params: {ids: ids}}).toPromise();
    }
}
