import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {DeviationReason, IDeviationReason} from 'app/blocks/model/deviation-reason.model';
import {Resource} from 'app/constants/resource';

@Injectable({
    providedIn: 'root'
})
export class DeviationReasonService extends EntityCrudService<IDeviationReason> {
    constructor(http: HttpClient) {
        super(http, Resource.DEVIATION_REASON, (obj?) => new DeviationReason(obj));
    }
}
