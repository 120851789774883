import {SERVER_API_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {createRequestOption} from 'app/blocks/util/request-util';
import {IUser} from 'app/core/user/user.model';

@Injectable()
export class UserService {
    private resourceUrl = SERVER_API_URL + 'api/users';

    constructor(private http: HttpClient) {}

    create(user: IUser): Observable<HttpResponse<IUser>> {
        return this.http.post<IUser>(this.resourceUrl, user, {
            observe: 'response'
        });
    }

    update(user: IUser): Observable<HttpResponse<IUser>> {
        return this.http.put<IUser>(this.resourceUrl, user, {
            observe: 'response'
        });
    }

    find(login: string): Observable<HttpResponse<IUser>> {
        return this.http.get<IUser>(`${this.resourceUrl}/${login}`, {
            observe: 'response'
        });
    }

    query(req?: any): Observable<HttpResponse<IUser[]>> {
        const options = createRequestOption(req);
        return this.http.get<IUser[]>(this.resourceUrl, {
            params: options,
            observe: 'response'
        });
    }

    delete(login: string): Observable<HttpResponse<any>> {
        return this.http.delete(`${this.resourceUrl}/${login}`, {
            observe: 'response'
        });
    }

    search = (req?: any): Promise<HttpResponse<IUser[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<IUser[]>(`${SERVER_API_URL}api/_search/users`, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    authorities(): Observable<string[]> {
        return this.http.get<string[]>(SERVER_API_URL + 'api/users/authorities');
    }

    openUser(id: string): void {
        if (id) {
            window.open('/setup/users/' + id, '_blank');
        }
    }
}
