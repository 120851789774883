export interface IGetPlanningOrders {
    filters: any;
    primaryFilter: string;
    collectionsDateFilterOn: string;
    deliveriesDateFilterOn: string;
}

export class GetPlanningOrders {
    static readonly type = 'Get Orders for Planning';
    constructor(
        public filters?: any,
        public searchTerm?: any,
        public primaryFilter: string = 'COLLECTIONS_FOR_PLANNING',
        public forceRefresh: boolean = false,
        public collectionsDateFilterOn = 'COLLECTIONS_FOR_PLANNING',
        public deliveriesDateFilterOn = 'DELIVERIES_FOR_PLANNING'
    ) {}
}

export class UpdatePlanningOrdersSelectedIds {
    static readonly type = 'Update selected IDs for Planning Orders';
    constructor(public ids?: number[]) {}
}
