import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {Component} from '@angular/core';

import {Toast, ToastrService, ToastPackage, IndividualConfig} from 'ngx-toastr';

@Component({
    selector: '[toast-component]',
    templateUrl: './alert-toast.component.html',
    styleUrls: ['./alert-toast.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({opacity: 0})),
            state('active', style({opacity: 1})),
            state('removed', style({opacity: 0})),
            transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
            transition('active => removed', animate('{{ easeTime }}ms {{ easing }}'))
        ])
    ],
    preserveWhitespaces: false
})
export class AlertToastComponent extends Toast {
    options: IndividualConfig & {retryButton?: boolean};

    // constructor is only necessary when not using AoT
    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    action(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    }
}
