import {IUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {INavigator} from 'app/blocks/routing/navigator';

export abstract class AbstractResource<T> {
    constructor(
        public readonly name: Resource,
        public readonly api: EntityCrudService<T>,
        protected _navigator: INavigator,
        protected _urlProvider: IUrlProvider
    ) {}

    get navigate(): INavigator {
        return this._navigator;
    }

    get url(): IUrlProvider {
        return this._urlProvider;
    }
}
