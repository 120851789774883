import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ISystemSurcharge extends IBaseEntity {
    id: number;
    percentage: number;
    effectiveFromDate: any;
}

export class SystemSurcharge extends BaseEntity implements ISystemSurcharge {
    public id: number;
    public percentage: number;
    public effectiveFromDate: any;

    constructor(systemSurcharge?: any) {
        super();
        if (systemSurcharge) {
            this.id = systemSurcharge.id;
            this.percentage = systemSurcharge.percentage;
            this.effectiveFromDate = systemSurcharge.effectiveFromDate;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): SystemSurcharge {
        return new SystemSurcharge(this);
    }
}
