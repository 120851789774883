import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IVehicleClass extends IBaseEntity {
    id?: number;
    name?: string;
}

export class VehicleClass extends BaseEntity implements IVehicleClass {
    public id?: number;
    public name?: string;

    constructor(vehicleClass?: any) {
        super();
        if (vehicleClass) {
            this.id = vehicleClass.id;
            this.name = vehicleClass.name;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): VehicleClass {
        return new VehicleClass(this);
    }
}
