export enum Shape {
    CIRCLE = 1,
    SQUARE,
    DIAMOND,
    TRIANGLE,
    UPSIDEDOWN_TRIANGLE,
    STAR,
}

export function getShapeEntities(){
    let names = Object
        .keys(Shape)
        .filter(s => Number.isNaN(parseInt(s)));

    //Convert to human readable format
    names = names.map(name => {
        let retVal = '';

        name.split('_')
            .forEach(
                namePart => retVal += namePart.replace(
                    /(\w)(\w*)/g, 
                    (g0,g1,g2) => g1.toUpperCase() + g2.toLowerCase()) + " "
            );

        return retVal.trimEnd();
    })

    const ids = Object
        .values(Shape)
        .map(s => parseInt(s.toString()))
        .filter(s => !Number.isNaN(s));
        
    const shapeEntities: ShapeEntity[] = [];

    for(let i = 0; i < names.length; i++){
        shapeEntities.push(new ShapeEntity(names[i], ids[i]))
    }

    return shapeEntities;
}

export class ShapeEntity{
    constructor(name: string, id: number){
        this.name = name;
        this.id = id;
    }

    name: string;
    id: number;
}