import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {DriverRequestType, IDriverRequestType} from 'app/blocks/model/driver-request-type.model';

@Injectable({
    providedIn: 'root'
})
export class DriverRequestTypeService extends EntityCrudService<IDriverRequestType> {
    constructor(http: HttpClient) {
        super(http, Resource.DRIVER_REQUEST_TYPES, (obj?) => new DriverRequestType(obj));
    }
}
