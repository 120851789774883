import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ContainerType, IContainerType} from 'app/blocks/model/container-type.model';

@Injectable({
    providedIn: 'root'
})
export class ContainerTypeService extends EntityCrudService<IContainerType> {
    constructor(http: HttpClient) {
        super(http, Resource.CONTAINER_TYPES, (obj?) => new ContainerType(obj));
    }
}
