import {DraggableDirective} from 'app/blocks/util/drag-and-drop/draggable-utils/draggable.directive';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [DraggableDirective],
    imports: [CommonModule],
    exports: [DraggableDirective]
})
export class DraggableUtilsModule {}
