import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {Driver, IDriver} from 'app/blocks/model/driver.model';

export interface IPackageReturns extends IBaseEntity {
    id: number;
    driver: IDriver;
    vehicleName: string;
    deliveryDate: string;
    customerName: string;
    deliveryCustomerName: string;
    deliveryPointName: string;
    itemCount: number;
    description: string;
    packagingCollectReason: string;
    packagingDeliveryReason: string;
}

export class PackageReturns extends BaseEntity implements IPackageReturns {
    public id: number;
    public customerName: string;
    public deliveryCustomerName: string;
    public deliveryDate: string;
    public deliveryPointName: string;
    public driver: Driver;
    public itemCount: number;
    public packagingCollectReason: string;
    public packagingDeliveryReason: string;
    public description: string;
    public vehicleName: string;

    constructor(packageReturns?: any) {
        super();
        if (packageReturns) {
            this.id = packageReturns.id;
            this.customerName = packageReturns.customerName;
            this.deliveryCustomerName = packageReturns.deliveryCustomerName;
            this.deliveryDate = packageReturns.deliveryDate;
            this.deliveryPointName = packageReturns.deliveryPointName;
            this.driver = packageReturns.driverName;
            this.itemCount = packageReturns.itemCount;
            this.packagingCollectReason = packageReturns.packagingCollectReason;
            this.packagingDeliveryReason = packageReturns.packagingDeliveryReason;
            this.vehicleName = packageReturns.vehicleName;
            this.description = packageReturns.description;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): PackageReturns {
        return new PackageReturns(this);
    }
}
