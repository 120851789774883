import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export class DriverSubTypeLite {
    public id?: number;
    public shortCode?: string;
    public name?: string;
}

export interface IDriverSubType extends IBaseEntity {
    id?: number;
    shortCode?: string;
    name?: string;
}

export class DriverSubType extends BaseEntity implements IDriverSubType {
    public id?: number;
    public shortCode?: string;
    public name?: string;

    constructor(DriverSubTypeLite?: any) {
        super();
        if (DriverSubTypeLite) {
            this.id = DriverSubTypeLite.id;
            this.shortCode = DriverSubTypeLite.shortCode;
            this.name = DriverSubTypeLite.name;
        }
    }

    get discriminator(): any {
        return this.shortCode;
    }

    clone(): DriverSubType {
        return new DriverSubType(this);
    }
}
