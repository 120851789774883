import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export class LateReasonLite {
    public id?: number;
    public name?: string;
    public description?: string;
}

export interface ILateReason extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
}

export class LateReason extends BaseEntity implements ILateReason {
    public id?: number;
    public name?: string;
    public description?: string;

    constructor(lateReason?: any) {
        super();
        if (lateReason) {
            this.id = lateReason.id;
            this.name = lateReason.name;
            this.description = lateReason.description;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): LateReason {
        return new LateReason(this);
    }
}
