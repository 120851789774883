import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ILoadingSheet extends IBaseEntity {
    id?: number;
}

export class LoadingSheet extends BaseEntity implements ILoadingSheet {
    id: number;

    constructor(loadingSheet?) {
        super();
        if (loadingSheet) {
            this.id = loadingSheet.id;
        }
    }

    clone(): LoadingSheet {
        return new LoadingSheet(this);
    }

    get discriminator(): any {
        return this.id;
    }
}
