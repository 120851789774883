import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ITpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';

export enum GoodsType {
    PALLET = 'PALLET',
    PALLET_ONLY = 'PALLET_ONLY',
    PALLET_FORCE = 'PALLET_FORCE',
    LIQUID = 'LIQUID',
    CAR = 'CAR',
    DRY_BULK = 'DRY_BULK',
    CONTAINER = 'CONTAINER',
    MANUFACTURED_PRODUCT = 'MANUFACTURED_PRODUCT',
    GENERIC_ITEMS = 'GENERIC_ITEMS'
}

export interface ICustomGoodsType extends IBaseEntity {
    id?: number;
    name?: string;
    shortCode?: string;
    defaultGoodsType?: boolean;
    baseGoodsType?: GoodsType;
    hazardous?: boolean;
    chilled?: boolean;
    tracked?: boolean;
    defaultSpaces?: number;
    mass?: number;
    volume?: number;
    tp_required_vehicle_skills?: ITpVehicleSkill[];
    max?: number;
    splitOnCreation?: boolean;
    deliveredIndependently?: boolean;
}

export class CustomGoodsType extends BaseEntity implements ICustomGoodsType {
    public id?: number;
    public name?: string;
    public shortCode?: string;
    public defaultGoodsType?: boolean;
    public baseGoodsType?: GoodsType;
    public hazardous?: boolean;
    public chilled?: boolean;
    public tracked?: boolean;
    public defaultSpaces?: number;
    public mass?: number;
    public volume?: number;
    public tp_required_vehicle_skills?: ITpVehicleSkill[];
    public max?: number;
    public splitOnCreation?: boolean;
    public deliveredIndependently?: boolean;

    constructor(customGoodsType?: any) {
        super();
        if (customGoodsType) {
            this.id = customGoodsType.id;
            this.name = customGoodsType.name;
            this.shortCode = customGoodsType.shortCode;
            this.defaultGoodsType = customGoodsType.defaultGoodsType;
            this.baseGoodsType = customGoodsType.baseGoodsType;
            this.hazardous = customGoodsType.hazardous;
            this.chilled = customGoodsType.chilled;
            this.tracked = customGoodsType.tracked;
            this.defaultSpaces = customGoodsType.defaultSpaces;
            this.mass = customGoodsType.mass;
            this.volume = customGoodsType.volume;
            this.tp_required_vehicle_skills = customGoodsType.tp_required_vehicle_skills;
            this.max = customGoodsType.max;
            this.splitOnCreation = customGoodsType.splitOnCreation;
            this.deliveredIndependently = customGoodsType.deliveredIndependently;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): CustomGoodsType {
        return new CustomGoodsType(this);
    }
}
