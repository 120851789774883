import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';

import {TranslateModule} from '@ngx-translate/core';

import {FuseNavigationComponent} from '@fuse/components/navigation/navigation.component';
import {FuseNavVerticalItemComponent} from '@fuse/components/navigation/vertical/item/item.component';
import {FuseNavVerticalCollapsableComponent} from '@fuse/components/navigation/vertical/collapsable/collapsable.component';
import {FuseNavVerticalGroupComponent} from '@fuse/components/navigation/vertical/group/group.component';
import {FuseNavHorizontalItemComponent} from '@fuse/components/navigation/horizontal/item/item.component';
import {FuseNavHorizontalCollapsableComponent} from '@fuse/components/navigation/horizontal/collapsable/collapsable.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, MatRippleModule, TranslateModule.forChild(), FontAwesomeModule],
    exports: [FuseNavigationComponent],
    declarations: [
        FuseNavigationComponent,
        FuseNavVerticalGroupComponent,
        FuseNavVerticalItemComponent,
        FuseNavVerticalCollapsableComponent,
        FuseNavHorizontalItemComponent,
        FuseNavHorizontalCollapsableComponent
    ]
})
export class FuseNavigationModule {}
