import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {ITrafficArea, TrafficArea} from 'app/blocks/model/traffic-area.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {TrafficAreaService} from 'app/blocks/service/api/traffic-area.service';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class TrafficAreaResource extends AbstractEntityResource<ITrafficArea> {
    constructor(trafficAreaService: TrafficAreaService, router: Router) {
        super(
            Resource.TRAFFIC_AREAS,
            trafficAreaService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.TRAFFIC_AREAS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.TRAFFIC_AREAS),
            (entity?) => new TrafficArea(entity),
            (entity) => entity.id
        );
    }
}
