import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {Driver, IDriver} from 'app/blocks/model/driver.model';

export interface IVehicleInspectionReport extends IBaseEntity {
    id?: number;
    inspectionDate: string;
    vehicleName?: string;
    driver: IDriver;
    reportStatus?: string;
    pdfAvailable?: boolean;
    inspectionTime?: string;
    vehicleId?: number;
    trailerId?: number;
    trailerName?: string;
}

export class VehicleInspectionReport extends BaseEntity implements IVehicleInspectionReport {
    public id?: number;
    public inspectionDate: string;
    public vehicleName?: string;
    public driver: Driver;
    public reportStatus?: string;
    public pdfAvailable?: boolean;
    public inspectionTime?: string;
    public vehicleId?: number;
    public trailerId?: number;
    public trailerName?: string;

    constructor(vehicleInspectionReport?: any) {
        super();
        if (vehicleInspectionReport) {
            this.id = vehicleInspectionReport.id;
            this.driver = vehicleInspectionReport.driver;
            this.inspectionDate = vehicleInspectionReport.inspectionDate;
            this.vehicleName = vehicleInspectionReport.vehicleName;
            this.reportStatus = vehicleInspectionReport.reportStatus;
            this.pdfAvailable = vehicleInspectionReport.pdfAvailable;
            this.inspectionTime = vehicleInspectionReport.inspectionTime;
            this.vehicleId = vehicleInspectionReport.vehicleId;
            this.trailerId = vehicleInspectionReport.trailerId;
            this.trailerName = vehicleInspectionReport.trailerName;
        }
    }
    get discriminator(): any {
        return this.id;
    }

    clone(): IVehicleInspectionReport {
        return new VehicleInspectionReport(this);
    }
}
