import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {Resource} from 'app/constants/resource';

import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IShiftType, ShiftType} from 'app/blocks/model/shift-type.model';
import {ShiftTypeService} from 'app/blocks/service/api/shift-type.service';

@Injectable({
    providedIn: 'root'
})
export class ShiftTypeResource extends AbstractEntityResource<IShiftType> {
    constructor(shiftTypeService: ShiftTypeService, router: Router) {
        super(
            Resource.SHIFT_TYPES,
            shiftTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SHIFT_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SHIFT_TYPES),
            (entity?) => new ShiftType(entity),
            (entity) => entity.name,
            faStar
        );
    }
}
