import {Component, Inject, Input, OnInit} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';

@Component({
    selector: 'di-blocking-dialog',
    templateUrl: './blocking-modal.component.html',
    styleUrls: ['./blocking-modal.component.scss']
})
export class DiBlockingDialogComponent implements OnInit {
    data: any;

    dialogParams: any = {
        message: 'Please Wait while the operation finishes',
        title: 'In-Progress'
    };

    constructor(
        @Inject(NZ_MODAL_DATA) public modalData: any,
        public modal: NzModalRef
    ) {
        this.data = modalData.data;
    }

    ngOnInit(): void {
        if (this.data.message) {
            this.dialogParams.message = this.data.message;
        }
    }
}
