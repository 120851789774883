import {ENTITIES_TARIFFS_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {NominalCodeService} from 'app/blocks/service/api/nominal-code.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {INominalCode, NominalCode} from 'app/blocks/model/nominal-code.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class NominalCodeResource extends AbstractEntityResource<INominalCode> {
    constructor(nominalCodeService: NominalCodeService, router: Router) {
        super(
            Resource.NOMINAL_CODES,
            nominalCodeService,
            new EntityNavigator(ENTITIES_TARIFFS_BASE_URL, Resource.NOMINAL_CODES, router),
            new EntityUrlProvider(ENTITIES_TARIFFS_BASE_URL, Resource.NOMINAL_CODES),
            (entity?) => new NominalCode(entity),
            (entity) => entity.name,
            faStar
        );
    }
}
