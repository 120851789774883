import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IPriceList, PriceList} from 'app/blocks/model/price-list.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class PriceListService extends EntityCrudService<IPriceList> {
    constructor(http: HttpClient) {
        super(http, Resource.PRICE_LISTS, (obj?) => new PriceList(obj));
    }

    checkPriceListsInUse = (priceListIds: number[]): Promise<boolean> => {
        const REQUEST_URI = SERVER_API_URL + 'api/price-lists/check-in-use';
        return this.http.post<boolean>(REQUEST_URI, priceListIds).toPromise();
    };

    reAssignAndDelete = (body): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/price-lists/reassign-and-delete';
        return this.http.post(REQUEST_URI, body).toPromise();
    };
}
