import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IHmFile} from 'app/blocks/model/hm-file.model';
import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';

export interface ICreateHaulierOrder extends IEntityAction {
    entity?: IHaulierOrder;
    uploadDocuments?: IHmFile[];
    deletedDocuments?: IHmFile[];
    optimizeRun: boolean;
}

export interface ICreateMultipleHaulierOrder extends IEntityAction {
    entitys?: IHaulierOrder[];
    uploadDocuments?: IHmFile[];
    deletedDocuments?: IHmFile[];
    optimizeRun: boolean;
}

export interface IUpdateHaulierOrder extends IEntityAction {
    entity?: IHaulierOrder;
    uploadDocuments?: IHmFile[];
    deletedDocuments?: IHmFile[];
}

export class CreateHaulierOrder implements ICreateHaulierOrder {
    static readonly type = '[Haulier Order] Create Haulier Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IHaulierOrder,
        public uploadDocuments?: IHmFile[],
        public optimizeRun = false,
        meta?: ActionMeta
    ) {}
}

export class CreateMultipleHaulierOrder implements ICreateMultipleHaulierOrder {
    static readonly type = '[Haulier Order] Create Multi Haulier Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entitys: IHaulierOrder[],
        public uploadDocuments?: IHmFile[],
        public optimizeRun = false,
        meta?: ActionMeta
    ) {}
}

export class UpdateHaulierOrder implements IUpdateHaulierOrder {
    static readonly type = '[Haulier Order] Update Haulier Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IHaulierOrder,
        public uploadDocuments?: IHmFile[],
        public deletedDocuments?: IHmFile[],
        meta?: ActionMeta
    ) {}
}
