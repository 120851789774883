import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faShapes, faTruck} from '@fortawesome/free-solid-svg-icons';
import {DriverService} from 'app/blocks/service/api/driver.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from './abstract-entity-resource';
import { IResourceIcon, ResourceIcon } from '../model/resource-icon.model';
import { ResourceIconService } from '../service/api/resource-icon.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceIconResource extends AbstractEntityResource<IResourceIcon> {
    constructor(resourceIconService: ResourceIconService, router: Router) {
        super(
            Resource.RESOURCE_ICONS,
            resourceIconService,
            new EntityNavigator(
                ENTITIES_SETUP_BASE_URL,
                Resource.RESOURCE_ICONS,
                router
            ),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.RESOURCE_ICONS),
            (entity?) => new ResourceIcon(entity),
            (entity) => entity.id,
            faShapes
        );
    }
}
