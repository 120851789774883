import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {Currency, ICurrency} from 'app/blocks/model/currency.model';

export interface ICountry extends IBaseEntity {
    id?: number;
    code?: string;
    name?: string;
    defaultCurrency?: ICurrency;
}

export class Country extends BaseEntity implements ICountry {
    public id?: number;
    public code?: string;
    public name?: string;
    public defaultCurrency?: Currency;

    constructor(country?) {
        super();
        if (country) {
            this.id = country.id;
            this.code = country.code;
            this.name = country.name;
            this.defaultCurrency = country.defaultCurrency ? new Currency(country.defaultCurrency) : null;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): Country {
        return new Country(this);
    }
}
