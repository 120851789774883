import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IDriver} from 'app/blocks/model/driver.model';
import {IExpenseType} from 'app/blocks/model/expense-type.model';
import {IExpensePaymentMethod} from 'app/blocks/model/expense-payment-method';
import {IVehicle} from 'app/blocks/model/vehicle.model';

export interface IDriverExpenses extends IBaseEntity {
    id?: number;
    driver?: IDriver;
    vehicle?: IVehicle;
    value?: number;
    vat?: number;
    notes?: string;
    expenseDate?: any;
    numberOfImages?: number;
    expenseType?: IExpenseType;
    expensePaymentMethod?: IExpensePaymentMethod;
}

export class DriverExpenses extends BaseEntity implements IDriverExpenses {
    public id: number;
    driver?: IDriver;
    vehicle?: IVehicle;
    public value?: number;
    public vat?: number;
    public notes?: string;
    public expenseDate?: any;
    public numberOfImages?: number;
    expenseType?: IExpenseType;
    expensePaymentMethod?: IExpensePaymentMethod;

    constructor(driverExpense?: any) {
        super();
        if (driverExpense) {
            this.id = driverExpense.id;
            this.driver = driverExpense.driver;
            this.vehicle = driverExpense.vehicle;
            this.value = driverExpense.value;
            this.vat = driverExpense.vat;
            this.notes = driverExpense.notes;
            this.expenseDate = driverExpense.expenseDate;
            this.numberOfImages = driverExpense.numberOfImages;
            this.expenseType = driverExpense.expenseType;
            this.expensePaymentMethod = driverExpense.expensePaymentMethod;
        }
    }

    clone(): DriverExpenses {
        return new DriverExpenses(this);
    }
}
