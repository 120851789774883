import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ITpAssetProfile, TpAssetProfile} from 'app/blocks/model/tp-asset-profile.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class TpAssetProfileService extends EntityCrudService<ITpAssetProfile> {
    constructor(http: HttpClient) {
        super(http, Resource.TP_ASSET_PROFILES, (obj?) => new TpAssetProfile(obj));
    }
}
