import {Currency} from 'app/blocks/model/currency.model';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICurrency} from 'app/blocks/model/currency.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Select, Store} from '@ngxs/store';
import {Observable, BehaviorSubject, Subject, of} from 'rxjs';
import {Sign} from 'app/blocks/model/sign.model';
import {take, takeLast, takeUntil} from 'rxjs/operators';
import {DisplayUtilsService} from 'app/common/display-utils-service';
import {SERVER_API_URL} from 'app/app.constants';
import {ServerEventService} from 'app/blocks/service/server-event.service';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {Principal} from 'app/core/auth/principal.service';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService extends EntityCrudService<ICurrency> implements OnDestroy {
    public _currencySubject = new BehaviorSubject<any>(null);
    public currency$ = this._currencySubject.asObservable();

    private currencyMap = new BehaviorSubject<Record<number, number>>({});
    protected _unsubscribeAll: Subject<void> = new Subject();

    constructor(
        http: HttpClient,
        private _displayUtilsService: DisplayUtilsService,
        private _superUserService: SuperUserService,
        private _principal: Principal
    ) {
        super(http, Resource.CURRENCIES, (obj?) => new Currency(obj));
        if (!this._superUserService.getIsSuperUser()) {
            this._principal
                .getAuthenticationState()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((user) => {
                    if (this._principal.isAuthenticated()) {
                        this.getCurrencyMap();
                        this.getTenantCurrency();
                    }
                });
        }
    }

    public getTenantCurrency() {
        this.http
            .get<any>(SERVER_API_URL + 'api/currencies/tenant')
            .pipe(take(1))
            .subscribe((currency) => {
                this._currencySubject.next(currency);
            });
    }

    public getCurrencyMap() {
        this.http
            .get<any>(SERVER_API_URL + 'api/exchange-rates/conversion-chart')
            .pipe(take(1))
            .subscribe((currMap) => {
                this.currencyMap.next(currMap);
            });
    }

    public getMultiplier(currencyId) {
        return this.currencyMap.getValue()[currencyId] ? this.currencyMap.getValue()[currencyId] : 1;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
