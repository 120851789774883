import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class CheckRouteService {
    constructor(private http: HttpClient) {}

    public checkRoute = (req): Promise<any> => {
        const requestParams = createRequestOption(req);
        const REQUEST_URI = `${SERVER_API_URL}api/check-route`;
        return this.http.get(REQUEST_URI, {params: requestParams, observe: 'response'}).toPromise();
    };
}
