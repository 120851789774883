import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faMapPin} from '@fortawesome/free-solid-svg-icons';
import {LocationPointService} from 'app/blocks/service/api/location-point.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ILocationPoint, LocationPoint} from 'app/blocks/model/location-point.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class LocationPointResource extends AbstractEntityResource<ILocationPoint> {
    constructor(locationPointService: LocationPointService, router: Router) {
        super(
            Resource.LOCATION_POINTS,
            locationPointService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.LOCATION_POINTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.LOCATION_POINTS),
            (entity?) => new LocationPoint(entity),
            (entity) => entity.id,
            faMapPin
        );
    }
}
