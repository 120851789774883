<div>
    <h2>{{ dialogParams.title }}</h2>
    <div class="di-text">
        {{ dialogParams.message }}
    </div>
    <div *ngIf="includeNotes" [ngStyle]="{width: '100%', 'max-width': '100%', paddingTop: '10px'}" rows="2">
        <textarea [(ngModel)]="notes" [ngStyle]="{width: '100%', 'max-width': '100%'}" placeholder="Enter notes here..."></textarea>
    </div>
    <div class="di-footer pt-24">
        <button
            id="button-show-confirm-ok"
            nz-button
            [nzType]="'primary'"
            class="mr-16"
            (click)="includeNotes ? confirmAction() : modal.destroy(true)">
            {{ dialogParams.confirmText }}
        </button>
        <button id="button-show-confirm-cancel" nz-button [nzType]="'default'" (click)="includeNotes ? cancelAction() : modal.destroy(false)">
            {{ dialogParams.cancelText }}
        </button>
    </div>
</div>
