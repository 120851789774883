import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IStop} from 'app/blocks/model/stop.model';
import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';
import {IDriver} from 'app/blocks/model/driver.model';
import {IVehicle} from 'app/blocks/model/vehicle.model';
import {ITrailer} from 'app/blocks/model/trailer.model';
import {ILegRoute, LegRoute} from 'app/blocks/model/leg-route.model';
import {IShiftType} from 'app/blocks/model/shift-type.model';

export enum LegStatus {
    BOOKED = 'BOOKED',
    PLANNED = 'PLANNED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    COULD_NOT_COMPLETE = 'COULD_NOT_COMPLETE',
    CANCELLED = 'CANCELLED'
}

export interface ILeg extends IBaseEntity {
    requestError: any;
    id?: number;
    orderId?: number;
    plannedStartTime?: any;
    plannedEndTime?: any;
    legStatus: string;
    sequence: number;
    resourced: boolean;
    communicated: boolean;
    completed: boolean;
    tracking: boolean;
    fromStop: IStop;
    toStop: IStop;
    driverId?: number;
    vehicleId?: number;
    trailerId?: number;
    shiftTypeId?: number;
    customerTrailerRef?: string;
    subContractorId?: number;
    subcontractorDetailsId?: number;
    orders: IHaulierOrder[];
    runId?: number;
    driver?: IDriver;
    vehicle?: IVehicle;
    trailer?: ITrailer;
    legRoute?: ILegRoute;
    shiftType?: IShiftType;
    middleStart?: boolean;
    trafficAreaId?: number;
    trafficAreaName?: string;
    controlAreaId?: number;
    subContractorRate?: any;
    subContractorCurrency?: any;
    subContractMechanism?: any;
    liveETA?: Date;
}

export class Leg extends BaseEntity implements ILeg {
    constructor(leg?: any) {
        super();
        if (leg) {
            this.id = leg.id;
            this.orderId = leg.orderId;
            this.plannedStartTime = leg.plannedStartTime;
            this.plannedEndTime = leg.plannedEndTime;
            this.legStatus = leg.legStatus;
            this.sequence = leg.sequence;
            this.resourced = leg.resourced;
            this.communicated = leg.communicated;
            this.completed = leg.completed;
            this.tracking = leg.tracking;
            this.fromStop = leg.fromStop;
            this.toStop = leg.toStop;
            this.driverId = leg.driverId;
            this.vehicleId = leg.vehicleId;
            this.trailerId = leg.trailerId;
            this.shiftTypeId = leg.shiftTypeId;
            this.customerTrailerRef = leg.customerTrailerRef;
            this.subContractorId = leg.subContractorId;
            this.subcontractorDetailsId = leg.subcontractorDetailsId;
            this.orders = leg.orders;
            this.runId = leg.runId;
            this.legRoute = new LegRoute(leg.legRoute);
            this.middleStart = leg.middleStart;
            this.trafficAreaId = leg.trafficAreaId;
            this.trafficAreaName = leg.trafficAreaName;
            this.controlAreaId = leg.controlAreaId;
            this.shiftType = leg.shiftType;
            this.subContractorRate = leg.subContractorRate;
            this.subContractorCurrency = leg.subContractorCurrency;
            this.subContractMechanism = leg.subContractMechanism;
            this.liveETA = leg.liveETA;
        }
    }

    get discriminator(): any {
        return this.id;
    }
    public id?: number;
    public orderId?: number;
    public plannedStartTime?: any;
    public plannedEndTime?: any;
    public legStatus: string;
    public sequence: number;
    public resourced: boolean;
    public communicated: boolean;
    public completed: boolean;
    public tracking: boolean;
    public fromStop: IStop;
    public toStop: IStop;
    public driverId?: number;
    public vehicleId?: number;
    public trailerId?: number;
    public shiftTypeId?: number;
    public customerTrailerRef?: string;
    public subContractorId?: number;
    public subcontractorDetailsId?: number;
    public orders: IHaulierOrder[];
    public runId?: number;
    public legRoute?: ILegRoute;
    public shiftType?: IShiftType;
    public middleStart?: boolean;
    public trafficAreaId?: number;
    public trafficAreaName?: string;
    public controlAreaId?: number;
    public subContractorRate?: any;
    public subContractorCurrency?: any;
    public subContractMechanism?: any;
    public liveETA?: Date;

    requestError: any;

    clone(): Leg {
        return new Leg(this);
    }
}
