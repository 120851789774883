export interface IFilterSetting {
    page: string;
    filter: string;
    value: string;
}

export class FilterSetting implements IFilterSetting {
    public id?: string;
    public page: string;
    public filter: string;
    public value: string;

    constructor(filterSetting?) {
        if (filterSetting) {
            this.id = filterSetting.id;
            this.page = filterSetting.page;
            this.filter = filterSetting.filter;
            this.value = filterSetting.value;
        }
    }
}
