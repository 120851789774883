import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch} from '@angular/common/http';
import {NgModule, DoBootstrap, Injector, ApplicationRef} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {FuseSidebarModule, FuseThemeOptionsModule, FuseProgressBarModule} from '@fuse/components';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {NgxsModule} from '@ngxs/store';
import {AppComponent} from 'app/app.component';
import {appStates} from 'app/blocks/store/states/app-state';
import {AlertService} from 'app/common/alert/alert.service';
import {UserRouteAccessService} from 'app/core/auth/user-route-access-service';
import {LoginService} from 'app/core/login/login.service';

import {fuseConfig} from 'app/fuse-config';
import {LayoutModule} from 'app/layout/layout.module';
import {ToastrModule} from 'ngx-toastr';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {NgxWebstorageModule} from 'ngx-webstorage';

import {AuthExpiredInterceptor} from 'app/blocks/interceptor/auth-expired.interceptor';
import {AuthInterceptor} from 'app/blocks/interceptor/auth.interceptor';
import {ErrorHandlerInterceptor} from 'app/blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from 'app/blocks/interceptor/notification.interceptor';
import {DialogModule} from 'app/common/dialog/dialog.module';
import {DiCoreModule} from 'app/core/core.module';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {CanDeactivateGuard} from 'app/common/can-deactivate-guard.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ResetPasswordComponent} from 'app/main/public/authentication/reset-password/reset-password.component';
import {NZ_I18N, NZ_DATE_LOCALE, en_GB, NZ_DATE_CONFIG, NzDateConfig} from 'ng-zorro-antd/i18n';
import {NzConfig, NZ_CONFIG} from 'ng-zorro-antd/core/config';

import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {PERFECT_SCROLLBAR_CONFIG} from 'perfect-scrollbar-angular';
import {PerfectScrollbarConfigInterface} from 'perfect-scrollbar-angular';
import {FlexLayoutModule} from '@angular/flex-layout';
import {enGB} from 'date-fns/locale';
import {FusePipesModule} from '@fuse/pipes/pipes.module';
import {AlertToastComponent} from 'app/common/alert/alert-toast.component';
import {BlockerDialogModule} from 'app/common/blocking-message/blocker-dialog.module';
import {createCustomElement} from '@angular/elements';
import {LegPlannerIconComponent} from 'app/main/private/leg-planning/leg-planner-icon/leg-planner-icon.component';
import {ResourceIconBarComponent} from 'app/main/private/common/resource-icons/bar/resource-icons-bar.component';
import {environment} from 'environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {ALL_ACCESS, SUPER_ADMIN_ACCESS} from 'app/constants/access-levels';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {ServiceWorkerModule} from '@angular/service-worker';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const ngZorroConfig: NzConfig = {
    // message: { nzTop: 120 },
    // notification: { nzTop: 240 }
    form: {nzNoColon: true}
};

const nzDateConfig: NzDateConfig = {
    firstDayOfWeek: 0
};

const appRoutes: Routes = [
    {
        path: 'login',
        loadComponent: () => import('./main/public/authentication/login/login.component').then((m) => m.LoginComponent)
    },
    {
        path: 'create-account',
        loadComponent: () => import('./main/public/authentication/create-account/create-account.component').then((m) => m.CreateAccountComponent)
    },
    {
        path: 'forgot-password',
        loadComponent: () => import('./main/public/authentication/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent)
    },
    {
        path: 'super-admin/login',
        loadComponent: () => import('./main/public/sa-authentication/sa-login/sa-login.component').then((m) => m.SaLoginComponent)
    },
    {
        path: 'super-admin-setup',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: SUPER_ADMIN_ACCESS
        },
        loadChildren: () => import('./main/private/super-admin-setup/super-admin-setup.module').then((m) => m.SuperAdminSetupModule)
    },
    {
        path: 'vehicle-inspections',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/vehicle-inspections/vehicle-inspections.module').then((m) => m.VehicleInspectionsModule)
    },
    {
        path: 'orders',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/orders/orders.module').then((m) => m.OrdersModule)
    },
    {
        path: 'resource-planning',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/resource-planning/resource-planning.module').then((m) => m.ResourcePlanningModule)
    },
    {
        path: 'order-planning',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/resource-planning/resource-planning.module').then((m) => m.ResourcePlanningModule)
    },
    {
        path: 'leg-planning',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/leg-planning/leg-planning.module').then((m) => m.LegPlanningModule)
    },
    {
        path: 'runs',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/runs/runs.module').then((m) => m.RunsModule)
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'history',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/history/history.module').then((m) => m.HistoryModule)
    },
    {
        path: '',
        loadChildren: () => import('./main/private/welcome/welcome.module').then((m) => m.WelcomeModule),
        pathMatch: 'full',
        canActivate: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'analytics',
        loadChildren: () => import('./main/private/analytics/analytics.module').then((m) => m.AnalyticsModule),
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        }
    },
    {
        path: 'setup',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/setup/setup.module').then((m) => m.SetupModule)
    },
    {
        path: 'tariff',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/tariff/tariff.module').then((m) => m.TariffModule)
    },
    {
        path: 'tour-planners',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/tour-planners/tour-planners.module').then((m) => m.TourPlannersModule)
    },
    {
        path: 'traffic-sheet',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/new-traffic-sheet/new-traffic-sheet.module').then((m) => m.NewTrafficSheetModule)
    },
    {
        path: 'manifests',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/manifest/manifest.module').then((m) => m.ManifestModule)
    },
    {
        path: 'loading-sheet',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/setup/loading-sheet/loading-sheet.module').then((m) => m.LoadingSheetModule)
    },
    {
        path: 'expense',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/expenses/expenses.module').then((m) => m.ExpensesModule)
    },
    {
        path: 'LocalClients',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/LocalClients/LocalClients.module').then((m) => m.LocalClientsModule)
    },
    {
        path: 'fleetviews',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/fleetviews/fleetviews.module').then((m) => m.FleetviewsModule)
    },
    {
        path: 'card-planner',
        canActivate: [UserRouteAccessService],
        canActivateChild: [UserRouteAccessService],
        data: {
            authorities: ALL_ACCESS
        },
        loadChildren: () => import('./main/private/card-planner/card-planner.module').then((m) => m.CardPlannerModule)
    }
];

// Noop handler for factory function (for loading AppInitializer at start)
export function appInitializerLoadingBait(): any {
    return (): any => {};
}

@NgModule({
    declarations: [AppComponent, AlertToastComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {}),
        TranslateModule.forRoot(),

        NzNotificationModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FusePipesModule,

        // App modules
        LayoutModule,

        // DI newly added
        ToastrModule.forRoot({
            onActivateTick: true,
            toastComponent: AlertToastComponent
        }),
        DialogModule.forRoot(),
        BlockerDialogModule.forRoot(),
        FlexLayoutModule,
        NgxWebstorageModule.forRoot({prefix: 'di', separator: '-'}),
        DiCoreModule.forRoot(),
        NgSelectModule,
        NgxsModule.forRoot(appStates),
        FontAwesomeModule,
        PerfectScrollbarModule,
        NgxsReduxDevtoolsPluginModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [LocalStorageService, SessionStorageService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [LoginService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        },
        {
            provide: NZ_I18N,
            useValue: en_GB
        },
        {
            provide: NZ_CONFIG,
            useValue: ngZorroConfig
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {provide: NZ_DATE_LOCALE, useValue: enGB},
        {provide: NZ_DATE_CONFIG, useValue: nzDateConfig},
        {provide: BrowserAgent, useValue: environment.production ? new BrowserAgent(environment.newrelic) : null},
        AlertService,
        CanDeactivateGuard,
        provideHttpClient(withFetch())
    ]
})
export class AppModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(AppComponent);
        this.createWebComponents();
    }

    // Creates native-to-browser WebComponents from Angular components.
    // This is required as some components are added to the DOM via strings inputted into a third-party library (such as DXHTML Scheduler); these
    // components would not be detected by Angular otherwise.
    private createWebComponents() {
        const barComponentElement = createCustomElement(ResourceIconBarComponent, {injector: this.injector});
        customElements.define('resource-icons-bar-webcomponent', barComponentElement);

        const iconComponent = createCustomElement(LegPlannerIconComponent, {injector: this.injector});
        customElements.define('leg-planner-icon', iconComponent);
    }
}
