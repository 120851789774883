<div [ngClass]="positionAbsolutely === 'true' ? 'position-absolute' : 'position-default'" class="resource-icon-bar">
    <div *ngIf="isVehicleDriverVersion" class="vehicle-driver-skill-icons-bar">
        <div class="driver-skills-container" *ngIf="driverSkillIcons && driverSkillIcons.length">
            <mat-icon title="Driver Skills" class="icon-type-icon">people</mat-icon>
            <resource-icon *ngFor="let driverIcon of driverSkillIcons" [data]="driverIcon" class="driver-skills"> </resource-icon>
        </div>
        <div class="vehicle-skills-container" *ngIf="vehicleSkillIcons && vehicleSkillIcons.length">
            <mat-icon title="Vehicle Skills" class="icon-type-icon">directions_bus</mat-icon>
            <resource-icon *ngFor="let vehicleIcon of vehicleSkillIcons" [data]="vehicleIcon" class="vehicle-skills"> </resource-icon>
        </div>
    </div>
    <div *ngIf="!isVehicleDriverVersion" class="skills-icon-bar">
        <div class="skills-container">
            <resource-icon *ngFor="let skillIcon of skillIcons" [data]="skillIcon" class="skills"> </resource-icon>
        </div>
    </div>
</div>
