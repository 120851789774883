import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {Leg} from 'app/blocks/model/leg.model';

export interface ISubContractorLeg extends IBaseEntity {
    id?: number;
    name?: string;
    sendEmialsTo?: string;
    subContractorRate?: number;
    useSubContractorTrailer?: boolean;
    footerContactNumber?: string;
    specialInstructionsNotes?: string;
    pricingMechanism?: number;
    forceRemovalResource?: boolean;
    leg?: Leg;
    subContractorId?: number;
}

export class SubContractor extends BaseEntity implements ISubContractorLeg {
    public id?: number;
    public name?: string;
    public sendEmialsTo?: string;
    public subContractorRate?: number;
    public useSubContractorTrailer?: boolean;
    public footerContactNumber?: string;
    public specialInstructionsNotes?: string;
    public pricingMechanism?: number;
    public forceRemovalResource?: boolean;
    public leg?: Leg;
    public subContractorId?: number;

    constructor(subContractor?: any) {
        super();
        if (subContractor) {
            this.id = subContractor.id;
            this.name = subContractor.name;
            this.sendEmialsTo = subContractor.sendEmialsTo;
            this.subContractorRate = subContractor.subContractorRate;
            this.useSubContractorTrailer = subContractor.useSubContractorTrailer;
            this.footerContactNumber = subContractor.footerContactNumber;
            this.specialInstructionsNotes = subContractor.specialInstructionsNotes;
            this.pricingMechanism = subContractor.pricingMechanism;
            this.forceRemovalResource = subContractor.forceRemovalResource;
            this.leg = subContractor.leg;
            this.subContractorId;
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): IBaseEntity {
        return new SubContractor(this);
    }
}
