export function metresToMiles(metres: number): number {
    return metres > 0 ? Math.round(metres / 1609.344) : 0;
}

export function metresToKilometres(metres: number): number {
    return metres > 0 ? Math.round(metres / 1000) : 0;
}

export function roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
}
