import {DiDate} from 'app/blocks/util/di-date';
import {IAddress, Address} from 'app/blocks/model/address.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IHmFile} from 'app/blocks/model/hm-file.model';

export interface IContactInfo extends IBaseEntity {
    id?: number;
    name?: string;
    phone?: string;
    phoneExt?: string;
    workPhone?: string;
    workPhoneExt?: string;
    fax?: string;
    email?: string;
    address?: IAddress;
    meetingLogsNotes?: string;
    attachment?: any;
    license?: string;
    licenseDate?: any;
    licenseExpiryDate: any;
    training: boolean;
    trainingDate?: any;
    renewalDate?: any;
    driverAttachment?: IHmFile;
}

export class ContactInfo extends BaseEntity implements IContactInfo {
    id: number;
    name: string;
    phone: string;
    phoneExt: string;
    workPhone: string;
    workPhoneExt: string;
    fax: string;
    email: string;
    address: Address;
    meetingLogsNotes: string;
    attachment: any;
    license: string;
    licenseDate: any;
    licenseExpiryDate: any;
    training: boolean;
    trainingDate: any;
    renewalDate: any;
    driverAttachment: IHmFile;

    constructor(contactInfo?) {
        super();
        if (contactInfo) {
            this.id = contactInfo.id;
            this.name = contactInfo.name;
            this.phone = contactInfo.phone;
            this.phoneExt = contactInfo.phoneExt;
            this.workPhone = contactInfo.workPhone;
            this.workPhoneExt = contactInfo.workPhoneExt;
            this.fax = contactInfo.fax;
            this.email = contactInfo.email;
            this.address = contactInfo.address;
            this.meetingLogsNotes = contactInfo.meetingLogsNotes;
            this.attachment = contactInfo.attachment;
            this.license = contactInfo.license;
            this.licenseDate = DiDate.newInstance(contactInfo.licenseDate);
            this.licenseExpiryDate = DiDate.newInstance(contactInfo.licenseExpiryDate);
            this.training = contactInfo.training;
            this.trainingDate = DiDate.newInstance(contactInfo.trainingDate);
            this.renewalDate = DiDate.newInstance(contactInfo.renewalDate);
            this.driverAttachment = contactInfo.driverAttachment;
        } else {
            this.address = new Address();
        }
    }

    clone(): ContactInfo {
        return new ContactInfo(this);
    }

    get discriminator(): any {
        return this.name;
    }
}
