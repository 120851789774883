export const environment = {
    production: process.env.NODE_ENV === 'production',
    hmr: false,
    serverApiUrl: 'https://staging-api.hauliermagic.co.uk/',
    heremapsTourPlanningCredentials: {
        appId: 'nA5sOc6N2Q9HpxzPc99v',
        apiKey: 'XAAFROElAbAQcUVngboUNPnS9XGD3DMKB74rdk1_G8w'
    },
    trackingApiUrl: 'https://es51gnfgwk.execute-api.eu-west-1.amazonaws.com/Prod/',
    podServerApiUrl: 'https://staging-pod.hauliermagic.co.uk/',
    env: 'staging',
    firebaseConfig: {
        apiKey: 'AIzaSyBk3RCjOeechP8nPetWNLBt4awh6Bpdlx0',
        authDomain: 'hauliermagic-test.firebaseapp.com',
        databaseURL: 'https://hauliermagic-test-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'hauliermagic-test',
        storageBucket: 'hauliermagic-test.appspot.com',
        messagingSenderId: '1064323182776',
        appId: '1:1064323182776:web:9e2a01372dea94a3a2c44d',
        env: 'staging'
    },
    dwtKey: 'f0070uQAAAOA4uoBNDIp1wUrrr4ml/H0SabSvaOZ7r9mggMD67scTz04sYCBJT433QHGdRQH3c+sAwikQeWmywweXCGnPm+Mkgw==',
    logUrl: 'https://lwk2e2mbqwaefp46ubrywqsxx40iombm.lambda-url.eu-west-1.on.aws/',
    newrelic: {
        init: {distributed_tracing: {enabled: true}, privacy: {cookies_enabled: true}},
        loader_config: {
            accountID: '3458937',
            trustKey: '3458937',
            agentID: '538505474',
            licenseKey: 'NRJS-5eb9afcf1921f2210b6',
            applicationID: '523411785'
        },
        info: {
            beacon: 'bam.eu01.nr-data.net',
            errorBeacon: 'bam.eu01.nr-data.net',
            licenseKey: 'NRJS-5eb9afcf1921f2210b6',
            applicationID: '523411785',
            sa: 1
        }
    },
    //make sure when you add a new api key, you add it to the csvBoxKeys object accross env.ts, env.prod.ts, env.staging.ts and ensure that it is whitelisted on the CSVbox admin panel
    csvBoxKeys: {
        haulierOrdersGtCar: {
            csvBoxLicenceKey: 'ndXpPeNI3C63t3HBuZKx9LR4g7q02j'
        },
        haulierOrdersGtLiquid: {
            csvBoxLicenceKey: 'Z83zwMy22s205XRpjDQtlV9EEFaVaw'
        },
        haulierOrdersGtDryBulk: {
            csvBoxLicenceKey: 'U9uajLxlxfR7EUkvxTTZobVb8DWvtt'
        },
        haulierOrdersGtPallet: {
            csvBoxLicenceKey: 'HMn2ixyNZtrz90xtzLDKtmK1syXU4Z'
        },
        haulierOrdersGtPalletForce: {
            csvBoxLicenceKey: 'oNoC42Nqx9JO7k5RhIKuZsO92tyQcW'
        },
        haulierOrdersGtContainer: {
            csvBoxLicenceKey: 'xr1BHnKgjECYxgmiWN8jkbry2ncMu3'
        },
        driver: {
            csvBoxLicenceKey: 'Uy3l5pHeFOJlIVTZqWyqtaPPnEe4iK'
        },
        locationPoint: {
            csvBoxLicenceKey: 'jbXu6A9Ly8kJAYNXQWGoEVpUNApXFZ'
        },
        exchangeRate: {
            csvBoxLicenceKey: 'oA4kWQsUR2uKzVvDMu6FUeqn6zauxm'
        },
        organisation: {
            csvBoxLicenceKey: 'baxXD0sA9vsum8r1x4e4XQgTNLeFug'
        },
        serviceLevel: {
            csvBoxLicenceKey: 'Gq34nrSmUBXiLgoBVGtTkmhUFPRm0H'
        },
        trafficArea: {
            csvBoxLicenceKey: 'oK2HRnvFEJMnFuG3IJr2rRDvljbwHF'
        },
        trailerType: {
            csvBoxLicenceKey: 'XyXyoFEIUsPEPS3wP6QBURH81KSn4K'
        },
        trailer: {
            csvBoxLicenceKey: 'XXYSKjkazV7hcTeiCVxxKhCDwQepFb'
        },
        vehicleClass: {
            csvBoxLicenceKey: 'OyjwSszlmQ0NQhXmrgtJRq5foEaKtt'
        },
        vehicle: {
            csvBoxLicenceKey: '77QMETMbDuCZz6gTaquV0LVFgJW5f7'
        },
        nominalCode: {
            csvBoxLicenceKey: 'EICJ58CvSajgxSVjreH9IbGXzftZKT'
        },
        controlArea: {
            csvBoxLicenceKey: 'RP52wLGAgbgDn4XmCpkQQp8hDxZuyH'
        },
        customGoodsType: {
            csvBoxLicenceKey: 'h5upswgk0cj5PkN45Qzvrl4nWUoexq'
        },
        businessType: {
            csvBoxLicenceKey: 'QLityOVtzvwacJLLMpK0rZtT9PNDdi'
        },
        fuelSurchargeGroup: {
            csvBoxLicenceKey: 'f7OKmdBIGynnDZJdQwTr4H8rgCsjnL'
        },
        person: {
            csvBoxLicenceKey: 'm4JJHohzd4dXH534zZZwtQ9Dif0Jy2'
        },
        driverType: {
            csvBoxLicenceKey: 'vI7WT79L49gQpRlfzrIpjFyRfIb9LA'
        },
        importBshOrder: {
            csvBoxLicenceKey: 'rItaBAHTHF1MS3nYGiwiiV4t31WzhI'
        },
        importPallexOrder: {
            csvBoxLicenceKey: '4PAmGO2WAo5UjpP3TLKzPiX75HUPLu'
        }
    }
};
