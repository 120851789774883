import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IFuelSurcharge, FuelSurcharge} from 'app/blocks/model/fuel-surcharge.model';

export interface IFuelSurchargeGroup extends IBaseEntity {
    id?: number;
    name: string;
    percentage: number;
    fuelSurcharges: IFuelSurcharge[];
}

export class FuelSurchargeGroup extends BaseEntity implements IFuelSurchargeGroup {
    public id?: number;
    public name: string;
    public percentage: number;
    public fuelSurcharges: IFuelSurcharge[];

    constructor(fuelSurchargeGroup?: any) {
        super();
        if (fuelSurchargeGroup) {
            this.id = fuelSurchargeGroup.id;
            this.name = fuelSurchargeGroup.name;
            this.percentage = fuelSurchargeGroup.percentage;
            this.fuelSurcharges = fuelSurchargeGroup.fuelSurcharges?.map?.((fuelSurcharge) => new FuelSurcharge(fuelSurcharge));
            // Add other attributes
            // this.name = fuelSurchargeGroup.name;
            // Whenever there is a time element, use DiTime
            // this.timeAttr = DiTime.newInstance(fuelSurchargeGroup.timeAttr)
            // Whenever there is a date element, use DiDate
            // this.dateAttr = DiDate.newInstance(fuelSurchargeGroup.dateAttr)
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): FuelSurchargeGroup {
        return new FuelSurchargeGroup(this);
    }
}
