import {HandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IHandheldProfile} from 'app/blocks/model/handheld-profile.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HandheldProfileService extends EntityCrudService<IHandheldProfile> {
    constructor(http: HttpClient) {
        super(http, Resource.HANDHELD_PROFILES, (obj?) => new HandheldProfile(obj));
    }

    findBooleanKeyValue = (id: number, key: string, category: string): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('id', id).set('key', key).set('category', category);
        const REQUEST_URI = SERVER_API_URL + 'api/handheld-profiles/get-key-value';
        return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS}).toPromise();
    };
}
