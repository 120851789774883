import {Injectable} from '@angular/core';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ExpensePaymentMethod, IExpensePaymentMethod} from 'app/blocks/model/expense-payment-method';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';

@Injectable({
    providedIn: 'root'
})
export class ExpensePaymentMethodService extends EntityCrudService<IExpensePaymentMethod> {
    constructor(http: HttpClient) {
        super(http, Resource.EXPENSE_PAYMENT_METHOD, (obj?) => new ExpensePaymentMethod(obj));
    }
}
