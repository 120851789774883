import {CallIn} from 'app/blocks/model/call-in.model';
import {LegResource} from 'app/blocks/model/leg-resource.model';
import {IResourceItem, ISubContractItem} from 'app/blocks/model/traffic-sheet-legs.model';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {EntityView} from 'app/blocks/util/entity-view';
import {ListViewParams} from 'app/blocks/util/list-view-params';

export interface ActionMeta {
    currentPage?: string;
}

export interface IAction {
    suppressMessages?: boolean;
}

export interface IAction {
    id?: number;
}

export interface IEntityAction extends IAction {
    resource: AbstractEntityResource<any>;
    currentPage?: EntityView;
}

export interface ITrafficSheetList extends IEntityAction {
    viewParams?: ListViewParams;
}

export interface IChangeLegTrafficSheetViewParams extends IEntityAction {
    viewParams?: ListViewParams;
}

export interface ILegActions {
    resources?: IResourceItem[];
}

export interface ILegActions {
    resource?: any;
}

export interface ILegActions {
    ids?: any[];
    sendCancellationEmail?: boolean;
}
export interface ITravelNotes {
    driverId?: number;
    travelNotes?: string;
}

export interface ICallInLeg {
    callIn: CallIn;
}

export class ResourceLegs {
    static readonly type = '[Leg] Resource Legs';
    constructor(public resources: IResourceItem[]) {}
}

export class UnResourceLegs implements ILegActions {
    static readonly type = '[Leg] Un-Resource Legs';

    constructor(
        public ids: number[],
        public sendCancellationEmail: boolean
    ) {}
}

export class UnSubContractLegs implements ILegActions {
    static readonly type = '[Leg] Un-Subcontract Legs';

    constructor(
        public ids: number[],
        public sendCancellationEmail: boolean
    ) {}
}

export class Communicate implements ILegActions {
    static readonly type = '[Leg] Communicate Legs';

    constructor(public ids: number[]) {}
}

export class UnCommunicate implements ILegActions {
    static readonly type = '[Leg] Un-Communicate Legs';

    constructor(public ids: number[]) {}
}

export class SubContract {
    static readonly type = '[Leg] SubContract Legs';

    constructor(public resources: ISubContractItem[]) {}
}

export class DropResourceOnLeg implements ILegActions {
    static readonly type = '[LegTrafficSheet] Updating  LegTrafficSheet on Drop';

    constructor(public resource: LegResource) {}
}

export class SetTravelNotes implements ITravelNotes {
    static readonly type = '[Driver] Updating  Travel Notes';

    constructor(
        public driverId: number,
        public travelNotes: string
    ) {}
}

export class CallInLeg implements ICallInLeg {
    static readonly type = '[Leg] calling in';

    constructor(public callIn: CallIn) {}
}

export class UnselectAllLegTrafficSheets {
    static readonly type = '[TrafficSheet] unSelected all LegsTrafficSheet';

    constructor(public resource: AbstractEntityResource<any>) {}
}

export class SelectAllLegTrafficSheets {
    static readonly type = '[TrafficSheet] Selected all LegsTrafficSheet';

    constructor(public resource: AbstractEntityResource<any>) {}
}
