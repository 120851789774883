export const locale = {
    lang: 'en',
    data: {
        error: {
            validation: 'Validation error',
            userexists: 'Employee already exists',
            null: ''
        },
        common: {
            search: 'Search',
            view: 'View',
            confirmation: 'Confirmation',
            yes: 'Yes',
            no: 'No',
            error: 'Error',
            backgroundError: 'Sync failed',
            networkError: 'Network Error',
            sorry: 'Sorry',
            ok: 'OK',
            success: 'Success',
            cancel: 'Cancel',
            save: 'Save',
            createRun: 'Create Run',
            assigned: 'Assigned',
            unassigned: 'Unassigned',
            assign: 'Assign',
            unassign: 'Unassign',
            errorMessage: 'Requested operation failed. Please contact the administrator.',
            backgroundErrorMessage: 'Please try again or reload the page.',
            networkErrorMessage: 'Please check your internet connection.',
            duplicateEntity: `Duplicate entity "{{ discriminator }}" already exists.`,
            deleteFailedMessage: 'This entity cannot be deleted as it is referenced by other entities.',
            confirmDelete: {
                message: 'Are you sure you want to delete {{ discriminator }}?'
            },
            sentPdfEmails: 'PDF Emails have been sent to recipients.',
            listActions: 'Actions',
            noSelectedColumn: 'Please select at least one column to be displayed.',
            noSelectedOrder: 'Please, select at least one order before creating a Run',
            haulierOrderDatesError: 'Delivery window is set before Collection Window',
            forms: {
                validationMessages: {
                    required: {
                        default: '{{_fieldName}} is required'
                    },
                    floatRange: {
                        default: '{{_fieldName}} should be between {{min}} and {{max}}'
                    },
                    maxlength: {
                        default: '{{_fieldName}} can have a maximum of {{requiredLength}} characters'
                    },
                    minlength: {
                        default: '{{_fieldName}} should have a minimum of {{requiredLength}} characters'
                    },
                    min: {
                        default: '{{_fieldName}} should be more than or equal to {{min}}'
                    },
                    max: {
                        default: '{{_fieldName}} should be at most {{max}}'
                    },
                    notEqualTo: {
                        default: '{{_fieldName}} should not be equal To {{value}}'
                    },
                    forbiddenRoles: {
                        default: 'User cannot have customer, and admin role at the same time'
                    },
                    formValid: {
                        default: 'Please fill in all the required fields before attempting to save data'
                    },
                    errorFields: {
                        default: 'There are errors in the following fields - please correct before attempting to save: {{_fieldList}}'
                    },
                    latitudeLongitudenotEqualToZero: {
                        default: 'Latitude and Longitude must not be equal to zero simultaneously'
                    },
                    collectionTimeShouldBeBeforeDeliveryTime: {
                        default: 'Collection End Time should be before Delivery Start Time'
                    },
                    nullEntity: {
                        default: 'Please fill in this required field'
                    },
                    confirmPassword: {
                        default: 'Confirm password and password not equal'
                    },
                    email: {
                        default: 'Invalid email'
                    },
                    emails: {
                        default: 'Invalid Email'
                    },
                    timeRange: {
                        default: 'Invalid time window'
                    },
                    dateTimePast: {
                        default: 'Date-time is in the past'
                    },
                    maxWeight: {
                        default: 'Invalid weight'
                    },
                    pattern: {
                        default: '{{_fieldName}} is not valid'
                    },
                    pastDate: {
                        default: "Date can't be set on the past"
                    },
                    sameCurrency: {
                        default: 'Currencies cannot be the same'
                    },
                    rate: {
                        default: 'Rate cannot be blank or Zero if Tariff Override is not set'
                    },
                    noMatchError: {
                        default: "Password doesn't match."
                    },
                    driverUnavailable: {
                        default: 'This driver is unavailable during the selected times'
                    },
                    vehicleUnavailable: {
                        default: 'This vehicle is unavailable during the selected times'
                    },
                    trailerUnavailable: {
                        default: 'This trailer contains refused goods'
                    },
                    collectionDateInThePast: {
                        default: "Collection date can't be set in the past"
                    },
                    collectionTimeInThePast: {
                        default: "Collection time can't be set in the past"
                    },
                    deliverBeforeCollection: {
                        default: "Order can't be delivered before collected"
                    }
                },
                unsavedChangesWarning: {
                    default: 'You have unsaved changes, are you sure you want to continue?'
                }
            },
            fields: {
                name: 'Name',
                accountNo: 'Account Number',
                vatRegNo: 'VAT Registration Number',
                website: 'Website URL',
                registrationNo: 'Registration Number',
                currencyId: 'Currency',
                invoiceTemplateId: 'Invoice Template',
                line1: 'Line 1',
                townCity: 'Town',
                countyState: 'County',
                postCodeZip: 'Post Code'
            },
            selection: {
                selectAll: 'All',
                selectNone: 'None'
            },
            genderWords: {
                m: {
                    _a: '',
                    _the: ''
                },
                f: {
                    _a: '',
                    _the: ''
                }
            }
        }
    }
};
