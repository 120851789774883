import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {DriverRequestType, IDriverRequestType} from 'app/blocks/model/driver-request-type.model';
import {DriverRequestTypeService} from 'app/blocks/service/api/driver-request-type.service';

@Injectable({
    providedIn: 'root'
})
export class DriverRequestTypeResource extends AbstractEntityResource<IDriverRequestType> {
    constructor(driverRequestTypeService: DriverRequestTypeService, router: Router) {
        super(
            Resource.DRIVER_REQUEST_TYPES,
            driverRequestTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DRIVER_REQUEST_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DRIVER_REQUEST_TYPES),
            (entity?) => new DriverRequestType(entity),
            (entity) => entity.id,
            faStar
        );
    }
}
