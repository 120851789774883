import {TariffVersion} from 'app/blocks/model/tariff-version.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IPriceList extends IBaseEntity {
    id?: number;
    description?: string;
    isForSubcontractor?: boolean;
    zoneTypeId?: number;
    multiplyByOrderValue?: boolean;
    additionalMultiplier?: number;
    ignoreAdditionalCollectsFromADeliveryPoint?: boolean;
    groupedOrdersRateIndividually?: boolean;
    rateCombinedWherePointsMatch?: boolean;
    tariffVersions?: TariffVersion[];
    priceListType: string;
    multiDropRate?: number;
    useGreatestRateForPrimaryRate?: boolean;
    multiCollectRate?: number;
    collectDeviation?: number;
    collectDeviationRate?: number;
    dropDeviation?: number;
    dropDeviationRate?: number;
    skillRates?: any[];
    customKey?: string;
}

export class PriceList extends BaseEntity implements IPriceList {
    public id?: number;
    public description?: string;
    public isForSubcontractor?: boolean;
    public zoneTypeId?: number;
    public multiplyByOrderValue?: boolean;
    public additionalMultiplier?: number;
    public ignoreAdditionalCollectsFromADeliveryPoint?: boolean;
    public groupedOrdersRateIndividually?: boolean;
    public rateCombinedWherePointsMatch?: boolean;
    public tariffVersions?: TariffVersion[];
    public priceListType: string;
    public multiDropRate?: number;
    public useGreatestRateForPrimaryRate?: boolean;
    public multiCollectRate?: number;
    public collectDeviation?: number;
    public collectDeviationRate?: number;
    public dropDeviation?: number;
    public dropDeviationRate?: number;
    public skillRates?: any[];
    public customKey?: string;

    constructor(priceList?: any) {
        super();
        if (priceList) {
            this.id = priceList.id;
            this.description = priceList.description;
            this.isForSubcontractor = priceList.isForSubcontractor;
            this.zoneTypeId = priceList.zoneTypeId;
            this.multiplyByOrderValue = priceList.multiplyByOrderValue;
            this.additionalMultiplier = priceList.additionalMultiplier;
            this.ignoreAdditionalCollectsFromADeliveryPoint = priceList.ignoreAdditionalCollectsFromADeliveryPoint;
            this.groupedOrdersRateIndividually = priceList.groupedOrdersRateIndividually;
            this.rateCombinedWherePointsMatch = priceList.rateCombinedWherePointsMatch;
            this.tariffVersions = priceList.tariffVersions;
            this.priceListType = priceList.priceListType;
            this.multiDropRate = priceList.multiDropRate;
            this.multiCollectRate = priceList.multiCollectRate;
            this.useGreatestRateForPrimaryRate = priceList.useGreatestRateForPrimaryRate;
            this.collectDeviation = priceList.collectDeviation;
            this.collectDeviationRate = priceList.collectDeviationRate;
            this.dropDeviation = priceList.dropDeviation;
            this.dropDeviationRate = priceList.dropDeviationRate;
            this.skillRates = priceList.skillRates || [];
            this.customKey = priceList.customKey;
        } else {
            this.skillRates = [];
        }
    }

    get discriminator(): any {
        return this.description;
    }

    clone(): PriceList {
        return new PriceList(this);
    }
}
