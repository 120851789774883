import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {SERVER_API_URL, TENANT_SETTINGS} from 'app/app.constants';
import {DataType} from 'app/blocks/model/enums/data-type.enum';
import {ITenantSettings, TenantSetting} from 'app/blocks/model/tenant-setting.model';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {debounceTime, filter, firstValueFrom, lastValueFrom, map, Observable, share, shareReplay} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    constructor(
        private http: HttpClient,
        private superUserService: SuperUserService,
        private _store: Store
    ) {
        _store
            .select((state) => state.common)
            .subscribe((commonState) => {
                this.debugLog('Subscription update: TenantSettings');
                const tenantSettings = commonState ? <ITenantSettings>commonState[TENANT_SETTINGS] : null;

                if (!tenantSettings?.loaded) {
                    this.debugLog('TenantSettings NOT loaded.');
                    return;
                }

                this.debugLog('TenantSettings loaded!!');
                this._tenantSettingsLoaded = tenantSettings.loaded;
            });
    }

    private readonly _maxTicks = 30;
    private readonly _tickDurationMS = 200;
    private _tenantSettingsLoaded = false;

    getAllTenantSettingsFromServer = (): Promise<TenantSetting[]> => {
        return this.http.get<any>(SERVER_API_URL + 'api/tenant-settings/all').toPromise();
    };

    getTenantSettingFromServer = (key): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/tenant-settings/${key}`).toPromise();
    };

    async waitForTenantSettingsToLoad(): Promise<void> {
        if (this._tenantSettingsLoaded) {
            return;
        }

        let ticks = 0;
        while (ticks < this._maxTicks) {
            ticks++;

            if (this._tenantSettingsLoaded) {
                break;
            }
            this.debugLog('Attempt ' + ticks + ': TenantSettings not loaded. Retrying in ' + this._tickDurationMS + 'ms');

            //wait for it...
            await new Promise((f) => setTimeout(f, this._tickDurationMS));
        }
    }

    getTenantSettingsObs = (settingKey: string): Observable<any> => {
        return this._store
            .select((state) => state?.common?.[TENANT_SETTINGS])
            .pipe(
                debounceTime(300),
                filter((tenantSettings: ITenantSettings) => tenantSettings?.loaded),
                map((tenantSettings: ITenantSettings) => {
                    if (!tenantSettings?.tenantSettings?.length) {
                        return null;
                    }

                    const tenantSetting = tenantSettings.tenantSettings.find((ts) => ts.key === settingKey);

                    if (!tenantSetting) {
                        return null;
                    }

                    if (tenantSetting.dataType.toString() === DataType[DataType.BOOL]) {
                        return tenantSetting.value.toLowerCase() === 'true';
                    } else if (tenantSetting.dataType.toString() === DataType[DataType.INT]) {
                        return parseInt(tenantSetting.value);
                    } else if (tenantSetting.dataType.toString() === DataType[DataType.DEC]) {
                        return parseFloat(tenantSetting.value);
                    } else if (tenantSetting.dataType.toString() === DataType[DataType.STR]) {
                        return tenantSetting.value.toString();
                    }
                    //it's a string or an object
                    return tenantSetting.value;
                })
            );
    };

    getTenantSetting = async (settingKey: string): Promise<any> => {
        if (this.superUserService.getIsSuperUser()) {
            return;
        }

        if (!this._tenantSettingsLoaded) {
            await this.waitForTenantSettingsToLoad();
        }

        return await firstValueFrom(this.getTenantSettingsObs(settingKey));
    };

    getTenantTextSetting = async (settingKey): Promise<any> => {
        const tenantSetting = await this.getTenantSetting(settingKey);
        return tenantSetting?.toString();
    };

    // ----- DEBUG LOGGING -----/
    private readonly _debugLoggingOn = false;

    private debugLog(log: string) {
        if (!this._debugLoggingOn) {
            return;
        }
        console.debug('[SETTINGS SERVICE] --- ' + log);
    }
}
