import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ILastCallIn, LastCallIn} from 'app/blocks/model/last-call-in.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class LastCallInService extends EntityCrudService<ILastCallIn> {
    constructor(http: HttpClient) {
        super(http, Resource.LAST_CALL_IN, (obj?) => new LastCallIn(obj));
    }
}
