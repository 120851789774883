import {UntypedFormControl, UntypedFormGroup, UntypedFormArray, AbstractControl} from '@angular/forms';
import {DiDate} from 'app/blocks/util/di-date';
import {combineToIsoLocalDateTimeString} from 'app/common/utils/date-utils';
import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';
import {IOrderGroup} from 'app/blocks/model/order-group.model';

export function updateFormValidity(control: UntypedFormGroup | UntypedFormArray | UntypedFormControl | AbstractControl): void {
    if (control) {
        // setErrors emits a validation event without triggering valueChanges listeners.
        if (control instanceof UntypedFormControl) {
            control.markAsTouched();
            control.setErrors(control.errors);
        } else if (control instanceof UntypedFormGroup) {
            Object.keys(control.controls).forEach((key) => {
                updateFormValidity(control.get(key));
            });
            control.markAsTouched();
            control.setErrors(control.errors);
        } else if (control instanceof UntypedFormArray) {
            control.controls.forEach((ctrl) => {
                updateFormValidity(ctrl);
            });
            control.markAsTouched();
            control.setErrors(control.errors);
        }
    }
}

const clearError = (group: any, fieldName: string, errorName: string): void => {
    const errors = group.controls[fieldName].errors;
    if (errors) {
        if (Object.keys(errors).length > 1) {
            const newErrors = {...errors};
            delete newErrors[errorName];
            group.controls[fieldName].setErrors(newErrors);
        } else {
            const firstErrorKey = Object.keys(errors)[0];
            if (firstErrorKey == errorName) {
                group.controls[fieldName].setErrors(null);
            }
        }
    }
};

const addError = (group: any, fieldName: string, errorKey: string, errorValue: any): void => {
    const errors = group.controls[fieldName].errors;
    if (errors) {
        const newErrors = {...errors};
        newErrors[errorKey] = errorValue;
        group.controls[fieldName].setErrors(newErrors);
    } else {
        group.controls[fieldName].setErrors({[errorKey]: errorValue});
    }
};

const newDate = (hour: string, min: string): Date => {
    const date = new Date();
    date.setHours(+hour);
    date.setMinutes(+min);
    return date;
};

export const floatRangeValidator = (min: number, max: number) => {
    return (control: UntypedFormControl): any => {
        const value = control.value;
        if (value && (isNaN(value) || value < min || value > max)) {
            return {
                floatRange: {
                    min,
                    max,
                    actualValue: value
                }
            };
        }
        return null;
    };
};

export function confirmPasswordValidator(group: UntypedFormGroup): any {
    const password = group.controls.password.value;
    const confirmPassword = group.controls.confirmPassword.value;

    const validationResult = password === confirmPassword ? null : {confirmPassword: true};
    group.controls.confirmPassword.setErrors(validationResult);

    return validationResult;
}

export const entitySelectionValidator = (fields: string[]): any => {
    return (group: UntypedFormGroup): any => {
        if (fields) {
            for (const field of fields) {
                const fieldValue = group.controls[field].value;
                if (fieldValue <= 0) {
                    const nullEntity = {nullEntity: true};
                    group.controls[field].setErrors(nullEntity);
                } else {
                    group.controls[field].setErrors(null);
                }
            }
        }
        return null;
    };
};

export const pastDateTimeValidator = (dateField: string, timeField?: string): any => {
    return (group: UntypedFormGroup): any => {
        const dateTimePast = 'dateTimePast';
        const checkDateElem = group.controls[dateField];
        const checkDate = checkDateElem.value;

        if (!checkDate) {
            if (checkDateElem.hasError(dateTimePast)) {
                clearError(group, dateField, dateTimePast);
            }
            return null;
        }

        let checkTime = null;
        if (timeField) {
            checkTime = group.controls[timeField].value;
        }

        const currentDate = new Date();
        const formDate = DiDate.newInstance(checkDate).asDate();
        if (checkTime) {
            // const timePatternMatches = /^([2][0-3]|[01]?[0-9]):([0-5][0-9])?$/.test(checkTime);
            // if (timePatternMatches) {
            // const [ hours, mins ] = checkTime.split(':');
            if (checkTime instanceof Date) {
                const hours = checkTime.getHours();
                const mins = checkTime.getMinutes();
                formDate.setHours(hours, mins, 0, 0);
                currentDate.setSeconds(0, 0);
            } else {
                return {invalidTime: true};
            }
        } else {
            currentDate.setHours(0, 0, 0, 0);
        }
        if (formDate < currentDate) {
            addError(group, dateField, dateTimePast, true);
            return {dateTimePast: true};
        } else {
            clearError(group, dateField, dateTimePast);
        }

        return null;
    };
};

export const startEndTimeValidator = (startTimeField: string, endTimeField: string) => {
    return (group: UntypedFormGroup): any => {
        const startTimeElem = group.controls[startTimeField];
        const startTime = startTimeElem.value;
        const endTimeElem = group.controls[endTimeField];
        const endTime = endTimeElem.value;
        const timeRange = 'timeRange';

        if (!startTime && !endTime) {
            if (startTimeElem.hasError(timeRange)) {
                clearError(group, startTimeField, timeRange);
            }
            if (endTimeElem.hasError(timeRange)) {
                clearError(group, endTimeField, timeRange);
            }
            return null;
        }

        let timeRangeError = {timeRange: true};

        if (startTime && !endTime) {
            addError(group, endTimeField, timeRange, true);
        } else if (!startTime && endTime) {
            addError(group, startTimeField, timeRange, true);
        } else {
            const startDateTime = startTime;
            const endDateTime = endTime;

            if (endDateTime < startDateTime) {
                addError(group, endTimeField, timeRange, true);
            } else {
                clearError(group, startTimeField, timeRange);
                clearError(group, endTimeField, timeRange);
                timeRangeError = null;
            }
        }
        return timeRangeError;
    };
};
export function latitudeLongitudeValidator(latitudeField: string, longitudeField: string, latLongRequired = true) {
    return function (group: UntypedFormGroup) {
        let latitude = group.get(latitudeField).value;
        let longitude = group.get(longitudeField).value;
        if (latitude == 0 && longitude == 0 && latLongRequired) {
            addError(group, latitudeField, 'latitudeLongitudenotEqualToZero', true);
        } else {
            clearError(group, latitudeField, 'latitudeLongitudenotEqualToZero');
        }
        return null;
    };
}
const extractDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth();
    const day = dateObject.getDate();
    const date = new Date(0);
    date.setFullYear(year);
    date.setMonth(month);
    date.setDate(day);

    return date;
};
const extractTime = (dateString) => {
    const dateObject = new Date(dateString);
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();

    const timeDate = new Date(0);
    timeDate.setHours(hours);
    timeDate.setMinutes(minutes);
    timeDate.setSeconds(seconds);

    return timeDate;
};
export function collectionAndDeliveryWindowValidator(collectionField: string, deliveryField: string) {
    return function (group: UntypedFormGroup) {
        let collection = group.get(collectionField).value;
        let delivery = group.get(deliveryField).value;
        let collectionDate = extractDate(collection.endDate);
        let collectionTime = extractTime(collection.endTime);
        let deliveryDate = extractDate(delivery.startDate);
        let deliveryTime = extractTime(delivery.startTime);
        if (deliveryDate < collectionDate) {
            addError(group.get(collectionField), 'endTime', 'collectionTimeShouldBeBeforeDeliveryTime', true);
        } else if (deliveryDate.getTime() === collectionDate.getTime() && deliveryTime < collectionTime) {
            addError(group.get(collectionField), 'endTime', 'collectionTimeShouldBeBeforeDeliveryTime', true);
        } else {
            clearError(group.get(collectionField), 'endTime', 'collectionTimeShouldBeBeforeDeliveryTime');
        }
        return null;
    };
}

export const startEndDateTimeValidator = (startDateField: string, startTimeField: string, endDateField: string, endTimeField: string) => {
    return (group: UntypedFormGroup): any => {
        const startDateElem = group.controls[startDateField];
        const startDate = startDateElem.value;
        const startTimeElem = group.controls[startTimeField];
        const startTime = startTimeElem.value;
        const endDateElem = group.controls[endDateField];
        const endDate = endDateElem.value;
        const endTimeElem = group.controls[endTimeField];
        const endTime = endTimeElem.value;
        const timeRange = 'timeRange';

        if (startDate && startTime && endDate && endTime) {
            if (startDate instanceof Date && endDate instanceof Date && startTime instanceof Date && endTime instanceof Date) {
                const startDateTime: string = combineToIsoLocalDateTimeString(startDate, startTime);
                const endDateTime: string = combineToIsoLocalDateTimeString(endDate, endTime);

                if (endDateTime < startDateTime) {
                    const timeRangeError = {timeRange: true};

                    addError(group, startDateField, timeRange, true);
                    addError(group, startTimeField, timeRange, true);
                    addError(group, endDateField, timeRange, true);
                    addError(group, endTimeField, timeRange, true);

                    return timeRangeError;
                }
            }
        }

        if (startDateElem.hasError(timeRange)) {
            clearError(group, startDateField, timeRange);
        }
        if (startTimeElem.hasError(timeRange)) {
            clearError(group, startTimeField, timeRange);
        }
        if (endDateElem.hasError(timeRange)) {
            clearError(group, endDateField, timeRange);
        }
        if (endTimeElem.hasError(timeRange)) {
            clearError(group, endTimeField, timeRange);
        }

        return null;
    };
};

export const timeWindowArrayValidator = (startTimeField: string, endTimeField: string): any => {
    return (formArray: UntypedFormArray): any => {
        if (formArray) {
            for (let i = 0; i < formArray.length - 1; i++) {
                const firstTimeWindow = formArray.at(i) as UntypedFormGroup;
                for (let j = i + 1; j < formArray.length; j++) {
                    const secondTimeWindow = formArray.at(j) as UntypedFormGroup;
                    const validationError = checkTimeWindowOverlap(firstTimeWindow, secondTimeWindow, startTimeField, endTimeField);
                    if (validationError) {
                        return validationError;
                    }
                }
            }
        }
        return null;
    };
};

export const maxAndEmptyWeightValidator = (group: UntypedFormGroup): any => {
    const maxWeight = group.controls.maxWeight.value;
    const emptyWeight = group.controls.emptyWeight.value;

    if ((maxWeight === null || maxWeight === '') && (emptyWeight === null || emptyWeight === '')) {
        group.controls.maxWeight.setErrors(null);
        group.controls.emptyWeight.setErrors(null);
        return null;
    }
    let weightError = {maxWeight: true};
    if (maxWeight === null || maxWeight === '') {
        group.controls.maxWeight.setErrors(weightError);
    } else if (emptyWeight === null || emptyWeight === '') {
        group.controls.emptyWeight.setErrors(weightError);
    } else if (+maxWeight <= +emptyWeight) {
        group.controls.maxWeight.setErrors(weightError);
    } else {
        group.controls.maxWeight.setErrors(null);
        group.controls.emptyWeight.setErrors(null);
        weightError = null;
    }
    return weightError;
};

const checkTimeWindowOverlap = (
    firstTimeWindow: UntypedFormGroup,
    secondTimeWindow: UntypedFormGroup,
    startTimeField: string,
    endTimeField: string
): any => {
    if (
        firstTimeWindow.controls[startTimeField] &&
        firstTimeWindow.controls[endTimeField] &&
        secondTimeWindow.controls[startTimeField] &&
        secondTimeWindow.controls[endTimeField]
    ) {
        const firstStartTime = firstTimeWindow.controls[startTimeField].value;
        const firstEndTime = firstTimeWindow.controls[endTimeField].value;
        const secondStartTime = secondTimeWindow.controls[startTimeField].value;
        const secondEndTime = secondTimeWindow.controls[endTimeField].value;

        if (firstEndTime && secondStartTime && firstStartTime && secondEndTime) {
            if (
                (firstStartTime <= secondStartTime && secondStartTime < firstEndTime) ||
                (secondStartTime <= firstStartTime && firstStartTime < secondEndTime)
            ) {
                return {timeWindowOverlap: true};
            }
        }
    }
    return null;
};

export const fromToExchangeRateValidator = (fromCurrencyField: string, toCurrencyField: string): any => {
    return (group: UntypedFormGroup): any => {
        const fromCurrencyControl = group.controls[fromCurrencyField];
        const fromCurrencyId = fromCurrencyControl.value;
        const toCurrencyControl = group.controls[toCurrencyField];
        const toCurrencyId = toCurrencyControl.value;
        const sameCurrency = 'sameCurrency';

        if (fromCurrencyId === toCurrencyId) {
            const sameCurrencyError = {sameCurrency: true};
            addError(group, toCurrencyField, sameCurrency, true);
            return sameCurrencyError;
        }

        if (toCurrencyControl.hasError(sameCurrency)) {
            clearError(group, toCurrencyField, sameCurrency);
        }
        return null;
    };
};

export const rateValidator = (): any => {
    return (group: UntypedFormGroup): any => {
        const rate = group.controls.rate.value;
        const tariffOverride = group.controls.tariffOverride.value;
        const weightError = {rate: true};
        if (tariffOverride == true) {
            group.controls.rate.setErrors(null);
            return;
        }
        if (rate == null || rate == '' || rate <= 0) {
            group.controls.rate.setErrors(weightError);
        }
    };
};

export const orderGroupValidator = (): any => {
    return (group: UntypedFormGroup): any => {
        const selectedOrders = group.controls.orders.value as IHaulierOrder[];
        let orderValidationFails = false;
        const fails = {};
        if (selectedOrders) {
            selectedOrders.forEach((order) => {
                if (isInvalidOrder(order.orderGroup, group.controls.id.value)) {
                    orderValidationFails = true;
                    fails[order.orderNo] = true;
                }
            });
            if (orderValidationFails) {
                group.controls.orderIds.setErrors(fails);
                return;
            }
        }
    };
};

export const validatePassword = (): any => {
    return (group: UntypedFormGroup): any => {
        const password = group.controls.password.value;
        const confirmPassword = group.controls.confirmPassword.value;
        const noMatchError = 'noMatch';
        if (password === confirmPassword) {
            group.controls.password.setErrors(null);
            return;
        } else {
            group.controls.password.setErrors({noMatchError});
            return;
        }
    };
};

const isInvalidOrder = (orderGroup: IOrderGroup, groupId): boolean => {
    if (orderGroup == null) {
        return false;
    } else if (groupId == orderGroup.id) {
        return false;
    } else {
        return true;
    }
};

export const availableDriverValidator = (driverField: any, unavailableDriversField: any) => {
    return (group: UntypedFormGroup): any => {
        const driverId: number = group.controls[driverField].value;
        const unavailableDriverIds: number[] = group.controls[unavailableDriversField].value;

        if (unavailableDriverIds && unavailableDriverIds.includes(driverId)) {
            addError(group, driverField, 'driverUnavailable', true);
            return {driverUnavailable: true};
        } else {
            clearError(group, driverField, 'driverUnavailable');
            return null;
        }
    };
};

export const availableVehicleValidator = (vehicleField: any, unavailableVehiclesField: any) => {
    return (group: UntypedFormGroup): any => {
        const driverId: number = group.controls[vehicleField].value;
        const unavailableDriverIds: number[] = group.controls[unavailableVehiclesField].value;

        if (unavailableDriverIds && unavailableDriverIds.includes(driverId)) {
            addError(group, vehicleField, 'vehicleUnavailable', true);
            return {driverUnavailable: true};
        } else {
            clearError(group, vehicleField, 'vehicleUnavailable');
            return null;
        }
    };
};

export const availableTrailerValidator = (trailerField: any, unavailableTrailersField: any) => {
    return (group: UntypedFormGroup): any => {
        const trailerId: number = group.controls[trailerField].value;
        const unavailableTrailerIds: number[] = group.controls[unavailableTrailersField].value;
        if (unavailableTrailerIds && unavailableTrailerIds.includes(trailerId)) {
            addError(group, trailerField, 'trailerUnavailable', true);
            return {trailerUnavailable: true};
        } else {
            clearError(group, trailerField, 'trailerUnavailable');
            return null;
        }
    };
};

const setTimeOfDay = (date: Date, time: Date) => {
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());
    date.setMilliseconds(time.getMilliseconds());
};

const isSameDay = (date1: Date, date2: Date) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const deliveryDateTimeWindowValidator = (): any => {
    return (group: UntypedFormGroup): any => {
        if (group.get('id').value || group.get('deliveryBookingType').value === 'ANYTIME') {
            return;
        }

        const collectionWindow = group.controls.collectionWindow as UntypedFormGroup;
        const deliveryWindow = group.controls.deliveryWindow as UntypedFormGroup;

        const formCollectioEndDate = new Date(collectionWindow.get('endDate').value);
        const formCollectioEndTime = new Date(collectionWindow.get('endTime').value);
        const collectionEndDate = new Date(formCollectioEndDate);
        setTimeOfDay(collectionEndDate, formCollectioEndTime);

        if (deliveryWindow.value.startDate && deliveryWindow.value.endDate && deliveryWindow.value.startTime && deliveryWindow.value.endTime) {
            const startDate = new Date(deliveryWindow.value.startDate);
            const endDate = new Date(deliveryWindow.value.endDate);
            setTimeOfDay(startDate, deliveryWindow.value.startTime);
            setTimeOfDay(endDate, deliveryWindow.value.endTime);

            if (startDate < collectionEndDate) {
                if (isSameDay(startDate, collectionEndDate)) {
                    deliveryWindow.controls['startTime'].setErrors({
                        deliverBeforeCollection: true
                    });
                    deliveryWindow.controls['startDate'].setErrors(null);
                } else {
                    deliveryWindow.controls['startDate'].setErrors({
                        deliverBeforeCollection: true
                    });
                    deliveryWindow.controls['startTime'].setErrors(null);
                }
            } else {
                deliveryWindow.controls['startDate'].setErrors(null);
                deliveryWindow.controls['startTime'].setErrors(null);
            }

            if (endDate < collectionEndDate) {
                if (isSameDay(endDate, collectionEndDate)) {
                    deliveryWindow.controls['endTime'].setErrors({
                        deliverBeforeCollection: true
                    });
                    deliveryWindow.controls['endDate'].setErrors(null);
                } else {
                    deliveryWindow.controls['endDate'].setErrors({
                        deliverBeforeCollection: true
                    });
                    deliveryWindow.controls['endTime'].setErrors(null);
                }
            } else {
                deliveryWindow.controls['endDate'].setErrors(null);
                deliveryWindow.controls['endTime'].setErrors(null);
            }
        }
    };
};

export const collectionDateTimeWindowValidator = (): any => {
    return (group: UntypedFormGroup): any => {
        if (group.get('id').value || group.get('enablePastOrderCreation').value || group.get('collectionBookingType').value === 'ANYTIME') {
            (group.controls.collectionWindow as UntypedFormGroup).controls['startDate'].setErrors(null);
            (group.controls.collectionWindow as UntypedFormGroup).controls['startTime'].setErrors(null);
            (group.controls.collectionWindow as UntypedFormGroup).controls['endDate'].setErrors(null);
            (group.controls.collectionWindow as UntypedFormGroup).controls['endTime'].setErrors(null);
            return;
        }

        const collectionWindow = group.controls.collectionWindow as UntypedFormGroup;

        if (
            collectionWindow.value.startDate &&
            collectionWindow.value.endDate &&
            collectionWindow.value.startTime &&
            collectionWindow.value.endTime
        ) {
            const now = new Date();
            const startDate = new Date(collectionWindow.value.startDate);
            const endDate = new Date(collectionWindow.value.endDate);

            setTimeOfDay(startDate, collectionWindow.value.startTime);
            setTimeOfDay(endDate, collectionWindow.value.endTime);

            if (startDate < now) {
                if (isSameDay(startDate, now)) {
                    collectionWindow.controls['startTime'].setErrors({
                        collectionTimeInThePast: true
                    });
                    collectionWindow.controls['startDate'].setErrors(null);
                } else {
                    collectionWindow.controls['startDate'].setErrors({
                        collectionDateInThePast: true
                    });
                    collectionWindow.controls['startTime'].setErrors(null);
                }
            } else {
                collectionWindow.controls['startDate'].setErrors(null);
                collectionWindow.controls['startTime'].setErrors(null);
            }

            if (endDate < now) {
                if (isSameDay(endDate, now)) {
                    collectionWindow.controls['endTime'].setErrors({
                        collectionTimeInThePast: true
                    });
                    collectionWindow.controls['endDate'].setErrors(null);
                } else {
                    collectionWindow.controls['endDate'].setErrors({
                        collectionDateInThePast: true
                    });
                    collectionWindow.controls['endTime'].setErrors(null);
                }
            }
        }
    };
};
