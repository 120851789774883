import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ICustomGoodsType, CustomGoodsType} from 'app/blocks/model/custom-goods-type.model';
import {Resource} from 'app/constants/resource';
import {createRequestOption} from 'app/blocks/util/request-util';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class CustomGoodsTypeService extends EntityCrudService<ICustomGoodsType> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOM_GOODS_TYPES, (obj?) => new CustomGoodsType(obj));
    }

    /**
     * Only use this function from the priceList screen,
     * Appends "Any" onto the back of the returned results
     */
    getAllCustomGoodsTypeForPriceListScreen = (params: any): Promise<any> => {
        const options = createRequestOption(params);
        return this.http.get(`${SERVER_API_URL}api/custom-goods-types`, {params: options}).toPromise();
    };
}
