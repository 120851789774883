export enum DataObjectType {
    LEG_PLANNING
}

export interface IUserDefault {
    id?: number;
    userId?: number;
    userDescription?: string;
    dataObjectType?: DataObjectType;
    jsonSettings?: any;
}

export class UserDefault {
    public id?: number;
    public userId?: number;
    public userDescription?: string;
    public dataObjectType?: DataObjectType;
    public jsonSettings?: any;

    constructor(userDefault: any) {
        this.id = userDefault.id;
        this.userId = userDefault.userId;
        this.userDescription = userDefault.userDescription;
        this.dataObjectType = userDefault.dataObjectType;
        this.jsonSettings = userDefault.userSettings;
    }

    clone(): UserDefault {
        return new UserDefault(this);
    }
}
