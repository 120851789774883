import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITpVehicleSkill extends IBaseEntity {
    id?: number;
    skill_name?: string;
}

export class TpVehicleSkill extends BaseEntity implements ITpVehicleSkill {
    public id?: number;
    public skill_name?: string;

    constructor(tpVehicleSkill?: any) {
        super();
        if (tpVehicleSkill) {
            this.id = tpVehicleSkill.id;
            this.skill_name = tpVehicleSkill.skill_name;
            // Add other attributes
            // this.name = tpVehicleSkill.name;
            // Whenever there is a time element, use DiTime
            // this.timeAttr = DiTime.newInstance(tpVehicleSkill.timeAttr)
            // Whenever there is a date element, use DiDate
            // this.dateAttr = DiDate.newInstance(tpVehicleSkill.dateAttr)
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TpVehicleSkill {
        return new TpVehicleSkill(this);
    }
}
