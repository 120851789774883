import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faBoxOpen} from '@fortawesome/free-solid-svg-icons';
import {CustomGoodsTypeService} from 'app/blocks/service/api/custom-goods-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL, ENTITIES_TARIFFS_BASE_URL} from 'app/app.constants';
import {ICustomGoodsType, CustomGoodsType} from 'app/blocks/model/custom-goods-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CustomGoodsTypeResource extends AbstractEntityResource<ICustomGoodsType> {
    constructor(customGoodsTypeService: CustomGoodsTypeService, router: Router) {
        super(
            Resource.CUSTOM_GOODS_TYPES,
            customGoodsTypeService,
            new EntityNavigator(ENTITIES_TARIFFS_BASE_URL, Resource.CUSTOM_GOODS_TYPES, router),
            new EntityUrlProvider(ENTITIES_TARIFFS_BASE_URL, Resource.CUSTOM_GOODS_TYPES),
            (entity?) => new CustomGoodsType(entity),
            (entity) => entity.name,
            faBoxOpen
        );
    }
}
