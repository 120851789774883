import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IShiftType, ShiftType} from 'app/blocks/model/shift-type.model';

@Injectable({
    providedIn: 'root'
})
export class ShiftTypeService extends EntityCrudService<IShiftType> {
    constructor(http: HttpClient) {
        super(http, Resource.SHIFT_TYPES, (obj?) => new ShiftType(obj));
    }
}
