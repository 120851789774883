import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {DiGroupActionMenuComponent} from 'app/common/di-group-action-menu/di-group-action-menu.component';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';

@NgModule({
    declarations: [DiGroupActionMenuComponent],
    imports: [CommonModule, TranslateModule, NzIconModule, NzButtonModule, NzDropDownModule],
    exports: [DiGroupActionMenuComponent],
    providers: []
})
export class DiGroupActionMenuModule {}
