import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {TRACKING_API_URL} from 'app/app.constants';

import {ErrorSeverity, createRequestContext, createRequestOption} from 'app/blocks/util/request-util';
import {GPSRunRoute, LastPosition} from 'app/blocks/model/lastposition.model';
import {AppPosition} from 'app/blocks/model/app-position.model';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(private http: HttpClient) {}

    getLastPositionForGpsUnits = (gpsUnitIds?: string): Promise<LastPosition[]> => {
        let queryParams = new HttpParams();
        if (gpsUnitIds) {
            queryParams = queryParams.append('gpsUnitIds', gpsUnitIds);
        }
        const url = TRACKING_API_URL + 'api/fleetview/LastPositions';
        return this.http.get<LastPosition[]>(url, {params: queryParams}).toPromise();
    };

    q = (req?: any): Promise<HttpResponse<LastPosition[]>> => {
        const options = createRequestOption(req);
        const url = TRACKING_API_URL + 'api/fleetview/LastPositions';
        return this.http
            .get<LastPosition[]>(url, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    getRunRoute = (req: any[]): Promise<GPSRunRoute[]> => {
        const url = TRACKING_API_URL + 'api/fleetview/RunRoute';
        return this.http.post<LastPosition[]>(url, req).toPromise();
    };

    getHistory = (
        gpsUnitId: string,
        fromDate: string,
        toDate: string,
        errorSeverity = ErrorSeverity.ERROR
    ): Promise<HttpResponse<LastPosition[]>> => {
        const options = createRequestOption({fromDate, toDate});
        const url = TRACKING_API_URL + 'api/fleetview/DeviceHistory/' + gpsUnitId;
        return this.http
            .get<LastPosition[]>(url, {
                params: options,
                observe: 'response',
                context: createRequestContext({errorSeverity})
            })
            .toPromise();
    };

    getAppHistory = (
        vehicleId: string,
        fromDate: string,
        toDate: string,
        errorSeverity = ErrorSeverity.ERROR
    ): Promise<HttpResponse<AppPosition[]>> => {
        const options = createRequestOption({fromDate, toDate});
        const url = TRACKING_API_URL + 'api/AppGpsPositions/History/' + vehicleId;
        return this.http
            .get<AppPosition[]>(url, {
                params: options,
                observe: 'response',
                context: createRequestContext({errorSeverity})
            })
            .toPromise();
    };

    getLastAppPositions = (req?: AppPosition[]): Promise<AppPosition[]> => {
        const options = createRequestOption(req);
        const url = TRACKING_API_URL + 'api/AppGpsPositions';
        return this.http.get<AppPosition[]>(url, {params: options}).toPromise();
    };
}
