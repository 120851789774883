import {Component, Inject, Input, OnInit} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';

@Component({
    selector: 'di-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class DiConfirmDialogComponent implements OnInit {
    data: any;
    includeNotes = false;
    notes = '';

    dialogParams: any = {
        message: 'Please confirm you want to proceed...',
        title: 'Confirmation',
        confirmText: 'Confirm',
        cancelText: 'Cancel'
    };

    constructor(
        @Inject(NZ_MODAL_DATA) public modalData: any,
        public modal: NzModalRef
    ) {
        this.data = modalData.data;
        this.includeNotes = modalData.includeNotes;
    }

    ngOnInit(): void {
        this.dialogParams = {...this.dialogParams, ...this.data};
    }

    confirmAction(): void {
        this.modal.destroy({isConfirm: true, notes: this.notes});
    }

    cancelAction(): void {
        this.modal.destroy({isConfirm: false});
    }
}
