import {ICountry, Country} from 'app/blocks/model/country.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {TrafficArea} from 'app/blocks/model/traffic-area.model';

export interface IAddress extends IBaseEntity {
    id: number;
    line1: string;
    line2: string;
    line3: string;
    townCity: string;
    countyState?: string;
    postCodeZip: string;
    latitude: number;
    longitude: number;
    countryId: number;
    countryName: string;
    trafficArea?: TrafficArea;
}

export class Address extends BaseEntity implements IAddress {
    id: number;
    line1: string;
    line2: string;
    line3: string;
    townCity: string;
    countyState?: string;
    postCodeZip: string;
    latitude: number;
    longitude: number;
    countryId: number;
    countryName: string;
    trafficArea: TrafficArea;

    constructor(address?) {
        super();
        if (address) {
            this.id = address.id;
            this.line1 = address.line1;
            this.line2 = address.line2;
            this.line3 = address.line3;
            this.townCity = address.townCity;
            this.countyState = address.countyState;
            this.postCodeZip = address.postCodeZip;
            this.latitude = address.latitude;
            this.longitude = address.longitude;
            this.countryId = address.countryId;
            this.countryName = address.countryName;
            this.trafficArea = address.trafficArea;
        }
    }

    clone(): Address {
        return new Address(this);
    }
}
