import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {DiViewsModule} from 'app/common/di-views/di-views.module';
import {NzButtonModule} from 'ng-zorro-antd/button';

import {CheckRouteModalComponent} from 'app/main/private/common/check-route-modal/check-route-modal.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DiFormsModule} from 'app/common/di-forms/di-forms.module';
import {FormGroupDirective, ReactiveFormsModule} from '@angular/forms';
import {DiEntityLibsModule} from 'app/common/di-entity-libs/di-entity-libs.module';
import {DiRadioGroupModule} from 'app/common/di-radio-group/di-radio-group.module';
import {DiSelectFormlessModule} from 'app/common/di-forms/components/di-select-formless/di-select-formless.module';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FlexLayoutModule,
        NzButtonModule,
        DiViewsModule,
        FontAwesomeModule,
        DiFormsModule,
        ReactiveFormsModule,
        DiEntityLibsModule,
        DiRadioGroupModule,
        DiSelectFormlessModule
    ],
    declarations: [CheckRouteModalComponent],
    exports: [CheckRouteModalComponent],
    providers: [CheckRouteModalComponent, FormGroupDirective]
})
export class CheckRouteModalModule {}
