import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';

export interface IOrderGroup extends IBaseEntity {
    id?: number;
    loadNo?: string;
    fuelSurcharge?: number;
    rate?: number;
    groupPlan?: boolean;
    orders?: IHaulierOrder[];
    orderIds?: any[];
    notes?: string;
    type?: string;
    removedOrders?: boolean;
}

export class OrderGroup extends BaseEntity implements IOrderGroup {
    public id?: number;
    public loadNo?: string;
    public fuelSurcharge?: number;
    public rate?: number;
    public groupPlan?: boolean;
    public orders?: IHaulierOrder[];
    public orderIds?: any[];
    public notes?: string;
    public type?: string;
    public removedOrders?: boolean;

    constructor(orderGroup?) {
        super();
        if (orderGroup) {
            this.id = orderGroup.id;
            this.loadNo = orderGroup.loadNo;
            this.fuelSurcharge = orderGroup.fuelSurcharge;
            this.rate = orderGroup.rate;
            this.groupPlan = orderGroup.groupPlan;
            this.orders = orderGroup.orders;
            this.orderIds = orderGroup.orderIds;
            this.notes = orderGroup.notes;
            this.type = orderGroup.type;
            this.removedOrders = orderGroup.removedOrders;
        } else {
            this.orders = [];
            this.orderIds = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): OrderGroup {
        return new OrderGroup(this);
    }
}
