import {Injectable} from '@angular/core';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {DriverExpenses, IDriverExpenses} from 'app/blocks/model/driver-expenses';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class DriverExpensesService extends EntityCrudService<IDriverExpenses> {
    constructor(http: HttpClient) {
        super(http, Resource.EXPENSES, (obj?) => new DriverExpenses(obj));
    }

    public downloadPDF = (expenseId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/expenses/${expenseId}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };
}
