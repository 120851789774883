import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {FuseSearchBarComponent} from '@fuse/components/search-bar/search-bar.component';

@NgModule({
    declarations: [FuseSearchBarComponent],
    imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
    exports: [FuseSearchBarComponent]
})
export class FuseSearchBarModule {}
