import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {ILocationPoint, LocationPoint} from 'app/blocks/model/location-point.model';
import {ITimeWindow, TimeWindow} from 'app/blocks/model/time-window.model';
import {processArray} from 'app/common/utils/model-utils';
import {IOrganisationExtraType} from 'app/blocks/model/organisation-extra-type.model';
import {IOrganisationContactInfo} from 'app/blocks/model/organisation-contact-info.model';
import {IBank} from 'app/blocks/model/bank.model';
import {SubcontractedWork} from 'app/blocks/model/subcontracted-work.model';
import {IClientFuelSurcharge} from 'app/blocks/model/client-fuel-surcharge.model';

export enum OrgType {
    TENANT,
    CUSTOMER,
    SUB_CONTRACTOR,
    AGENCY,
    SUPPLIER
}

export class OrganisationBusinessTypeTariff {
    public id?;
    public tariffId?;
    public organisationId?;
    public businessTypeId?;
    public description?;

    constructor(entity?: any) {
        if (entity) {
            this.id = entity.id;
            this.tariffId = entity.tariffId;
            this.organisationId = entity.organisationId;
            this.businessTypeId = entity.businessTypeId;
            this.description = entity.description;
        }
    }
}

export interface IOrganisation extends IBaseEntity {
    id?: number;
    name?: string;
    email?: string; // Used by OrganisationLiteDTO in the backend
    orgType?: OrgType;
    vatRegNo?: string;
    website?: string;
    registrationNo?: string;
    accountNo?: string;
    secondaryAccountNo?: string;
    underReview?: boolean;
    deleted?: boolean;
    contacts?: IOrganisationContactInfo[];
    contactInfo?: IContactInfo;
    operationHours?: ITimeWindow[];
    points?: ILocationPoint[];
    organisationExtras?: IOrganisationExtraType[];
    currency?: any;
    fuelSurchargeGroupId?: number;
    fuelSurchargeGroupName?: string;
    writeSettings?: any[];
    overrideVatType?: string;
    organisationTariffs?: OrganisationBusinessTypeTariff[];
    invoiceTemplates?: any[];
    vatTypeOverrideId?: number;
    bankAccounts?: IBank[];
    defaultForAccountNo?: boolean;
    writeMobileSettings?: any[];
    packageTypes?: any[];
    packagingCollectReasons?: any[];
    packagingDeliveryReasons?: any[];
    subContractorTariffId?: number;
    defaultCollectionPoint?: ILocationPoint;
    defaultDeliveryPoint?: ILocationPoint;
    defaultRunStartPoint?: ILocationPoint;
    defaultRunEndPoint?: ILocationPoint;
    defaultTrafficArea?: any;
    defaultPallets?: number;
    defaultClientPortalAddOrderInfo?: string;
    podRequired?: boolean;
    defaultGTId?: number;
    defaultSVId?: number;
    defaultBTId?: number;
    activeGTs: number[];
    activeSVs: number[];
    activeBTs: number[];
    invoiceSettings: any;
    suspended?: boolean;
    accountOnHold?: boolean;
    nominalCodeId?: number;
    creditLimit?: string;
    costCentre?: string;
    subContractor?: boolean;
    controlAreaId?: number;
    controlAreaDesc?: string;
    customerType?: string;
    defaultCountryId?: number;
    fuelSurcharge?: IClientFuelSurcharge;
    ferry?: boolean;
    nominalCodeRules?: any[];
    creditApplicationFormId: number;
    clientTermsAndConditionsId: number;
    subcontractorTermsAndConditionsId: number;
    excludeFromReports?: boolean;
    fuelSurchargeNominalCodeId?: number;
}

export class Organisation extends BaseEntity implements IOrganisation {
    public id?: number;
    public name?: string;
    public email?: string; // Used by OrganisationLiteDTO in the backend
    public orgType?: OrgType;
    public vatRegNo?: string;
    public website?: string;
    public registrationNo?: string;
    public accountNo?: string;
    public secondaryAccountNo?: string;
    public underReview?: boolean;
    public deleted?: boolean;
    public contacts?: IOrganisationContactInfo[];
    public contactInfo?: ContactInfo;
    public operationHours?: ITimeWindow[];
    public points?: LocationPoint[];
    public organisationExtras?: IOrganisationExtraType[];
    public fuelSurchargeGroupId?: number;
    public fuelSurchargeGroupName?: string;
    public writeSettings?: any;
    public overrideVatType?: string;
    public organisationTariffs?: OrganisationBusinessTypeTariff[];
    public invoiceTemplates?: any[];
    public vatTypeOverrideId?: number;
    public bankAccounts?: IBank[];
    defaultForAccountNo?: boolean;
    public writeMobileSettings?: any[];
    public packageTypes: any[];
    public packagingCollectReasons: any[];
    public packagingDeliveryReasons: any[];
    public subContractorTariffId?: number;
    public defaultCollectionPoint?: ILocationPoint;
    public defaultDeliveryPoint?: ILocationPoint;
    public defaultRunStartPoint?: ILocationPoint;
    public defaultRunEndPoint?: ILocationPoint;
    public defaultTrafficArea?: any;
    public defaultPallets?: number;
    public defaultClientPortalAddOrderInfo?: string;
    public podRequired?: boolean;
    public defaultGTId?: number;
    public defaultSVId?: number;
    public defaultBTId?: number;
    public activeGTs: number[];
    public activeSVs: number[];
    public activeBTs: number[];
    public invoiceSettings: any;
    public suspended: boolean;
    public accountOnHold: boolean;
    public nominalCodeId?: number;
    public creditLimit?: string;
    public costCentre?: string;
    public subContractor?: boolean;
    public controlAreaId?: number;
    public controlAreaDesc?: string;
    public customerType?: string;
    public defaultCountryId?: number;
    public fuelSurcharge?: IClientFuelSurcharge;
    public ferry?: boolean;
    public nominalCodeRules?: any[];
    public creditApplicationFormId: number;
    public clientTermsAndConditionsId: number;
    public subcontractorTermsAndConditionsId: number;
    public currency: any;
    public excludeFromReports?: boolean;
    public fuelSurchargeNominalCodeId?: number;

    constructor(organisation?: any) {
        super();
        if (organisation) {
            this.id = organisation.id;
            this.name = organisation.name;
            this.email = organisation.email;
            this.orgType = organisation.orgType;
            this.vatRegNo = organisation.vatRegNo;
            this.website = organisation.website;
            this.registrationNo = organisation.registrationNo;
            this.accountNo = organisation.accountNo;
            this.secondaryAccountNo = organisation.secondaryAccountNo;
            this.underReview = organisation.underReview;
            this.deleted = organisation.deleted;
            this.bankAccounts = organisation.bankAccounts || [];
            this.contacts = organisation.contacts;
            this.contactInfo = new ContactInfo(organisation.contactInfo);
            this.operationHours = processArray(organisation.operationHours, (obj: any) => new TimeWindow(obj));
            this.points = processArray(organisation.points, (obj: any) => new LocationPoint(obj));
            this.fuelSurchargeGroupId = organisation.fuelSurchargeGroupId;
            this.fuelSurchargeGroupName = organisation.fuelSurchargeGroupName;
            this.organisationExtras = organisation.organisationExtras;
            this.writeSettings = organisation.writeSettings;
            this.overrideVatType = organisation.overrideVatType;
            this.organisationTariffs = organisation.organisationTariffs;
            this.invoiceTemplates = organisation.invoiceTemplates;
            this.vatTypeOverrideId = organisation.vatTypeOverrideId;
            this.defaultForAccountNo = organisation.defaultForAccountNo || false;
            this.writeMobileSettings = organisation.writeMobileSettings;
            this.packageTypes = organisation.packageTypes;
            this.packagingCollectReasons = organisation.packagingCollectReasons;
            this.packagingDeliveryReasons = organisation.packagingDeliveryReasons;
            this.subContractorTariffId = organisation.subContractorTariffId;
            this.defaultCollectionPoint = organisation.defaultCollectionPoint;
            this.defaultDeliveryPoint = organisation.defaultDeliveryPoint;
            this.defaultRunStartPoint = organisation.defaultRunStartPoint;
            this.defaultRunEndPoint = organisation.defaultRunEndPoint;
            this.defaultTrafficArea = organisation.defaultTrafficArea;
            this.defaultPallets = organisation.defaultPallets;
            this.defaultClientPortalAddOrderInfo = organisation.defaultClientPortalAddOrderInfo;
            this.podRequired = organisation.podRequired;
            this.defaultGTId = organisation.defaultGTId;
            this.defaultSVId = organisation.defaultSVId;
            this.defaultBTId = organisation.defaultBTId;
            this.activeGTs = organisation.activeGTs;
            this.activeSVs = organisation.activeSVs;
            this.activeBTs = organisation.activeBTs;
            this.invoiceSettings = organisation.invoiceSettings;
            this.suspended = organisation.suspended;
            this.accountOnHold = organisation.accountOnHold;
            this.nominalCodeId = organisation.nominalCodeId;
            this.creditLimit = organisation.creditLimit;
            this.costCentre = organisation.costCentre;
            this.subContractor = organisation.subContractor || false;
            this.controlAreaId = organisation.controlAreaId;
            this.customerType = organisation.customerType;
            this.controlAreaDesc = organisation.controlAreaDesc;
            this.defaultCountryId =
                typeof organisation.defaultCountryId === 'number' ? organisation.defaultCountryId : organisation.defaultCountryId?.id;
            this.fuelSurcharge = organisation.fuelSurcharge;
            this.ferry = organisation.ferry;
            this.nominalCodeRules = organisation.nominalCodeRules || [];
            this.creditApplicationFormId = organisation.creditApplicationFormId;
            this.clientTermsAndConditionsId = organisation.clientTermsAndConditionsId;
            this.subcontractorTermsAndConditionsId = organisation.subcontractorTermsAndConditionsId;
            this.currency = organisation.currency;
            this.excludeFromReports = organisation.excludeFromReports;
            this.fuelSurchargeNominalCodeId = organisation.fuelSurchargeNominalCodeId;
        } else {
            this.contactInfo = new ContactInfo();
            this.contacts = [];
            this.writeSettings = [];
            this.bankAccounts = [];
            this.organisationExtras = [];
            this.defaultForAccountNo = false;
            this.writeMobileSettings = [];
            this.packageTypes = [];
            this.packagingCollectReasons = [];
            this.packagingDeliveryReasons = [];
            this.nominalCodeRules = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Organisation {
        return new Organisation(this);
    }

    newClientInstance(org?): Organisation {
        const organisation = new Organisation();
        if (org) {
            this.currency = org.currency;
            this.podRequired = org.podRequired;
            this.invoiceSettings = org.invoiceSettings;
            this.defaultGTId = org.defaultGTId;
            this.defaultSVId = org.defaultSVId;
            this.defaultBTId = org.defaultBTId;
            this.activeGTs = org.activeGTs;
            this.activeSVs = org.activeSVs;
            this.activeBTs = org.activeBTs;
            this.defaultForAccountNo = org.defaultForAccountNo || false;
            this.defaultCountryId = typeof org.defaultCountryId === 'number' ? org.defaultCountryId : org.defaultCountryId?.id;
            this.organisationExtras = org.organisationExtras;
            this.customerType = org.customerType;
        }

        return organisation;
    }
}
