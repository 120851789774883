import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';
import {DiDate} from 'app/blocks/util/di-date';

export interface PlanningOrdersStateModel {
    listView: {
        initialized: boolean;
        orders: {
            collections: IHaulierOrder[];
            deliveries: IHaulierOrder[];
        };
        totalItems: number;
        collectionsLoading: boolean;
        deliveriesLoading: boolean;
        selectedIds: number[];
        searchTerm: string;
        filters: any;
        collectionsDateFilterOn: string;
        deliveriesDateFilterOn: string;
        collectionsRequireRefresh: boolean;
        deliveriesRequireRefresh: boolean;
        // ToDo: we may need to move the filters to their own separate store, since they'll be used by the all orders screen as well
    };
}

export function getDefaultPlanningOrdersState(): PlanningOrdersStateModel {
    return {
        listView: {
            initialized: false,
            orders: {collections: [], deliveries: []},
            totalItems: 0,
            collectionsLoading: false,
            deliveriesLoading: false,
            selectedIds: [],
            collectionsDateFilterOn: 'COLLECTIONS_FOR_PLANNING',
            deliveriesDateFilterOn: 'DELIVERIES_FOR_PLANNING',
            filters: {
                dateFromFilter: DiDate.currentDate(),
                dateToFilter: DiDate.currentDate().addDays(1),
                dateFilterOn: ''
            },
            searchTerm: '',
            collectionsRequireRefresh: true,
            deliveriesRequireRefresh: true
        }
    };
}
