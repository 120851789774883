import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {concat, interval} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ServiceWorkerUpdateService {
    constructor(
        appRef: ApplicationRef,
        private updates: SwUpdate,
        private _notificationService: NzNotificationService
    ) {
        const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
        const everyXMinutes$ = interval(10 * 60 * 1000);
        const everyXMinutesOnceAppIsStable$ = concat(appIsStable$, everyXMinutes$);

        if (updates.isEnabled) {
            // Allow the app to stabilize first, before starting polling for updates
            console.log('Service worker: Checking for updates...');
            everyXMinutesOnceAppIsStable$.subscribe(async () => {
                try {
                    await updates.checkForUpdate();
                } catch (err) {
                    console.error('Service Worker: Failed to check for updates:', err);
                }
            });
        } else {
            // if service worker is not enabled, unregister it
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                if (registrations.length === 0) {
                    return;
                }
                console.log('Service worker is not enabled. Unregistering all service workers...');
                for (let registration of registrations) {
                    registration.unregister().then(function (success) {
                        console.log(`Service worker unregistered: `, success);
                    });
                }
                this.showERPUpdateNotification();
            });
        }
    }

    public logServiceWorkerUpdates() {
        this.updates.versionUpdates.subscribe((evt) => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    console.log(`Service Worker: Downloading new app version: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    console.log(`Service Worker: Current app version: ${evt.currentVersion.hash}`);
                    console.log(` Service Worker: New app version ready for use: ${evt.latestVersion.hash}`);
                    this.showERPUpdateNotification();
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.error(`Service Worker: Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });
    }

    private showERPUpdateNotification() {
        this._notificationService.create(
            'info',
            'Version Update',
            'A new version of the ERP is available. Please refresh the page to avoid any issues.'
        );
    }
}
