import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ICreditNoteLine} from 'app/blocks/model/credit-note-line.model';
import {IInvoice} from 'app/blocks/model/invoice.model';
import {IOrganisation} from 'app/blocks/model/organisation.model';
import {DiDate} from 'app/blocks/util/di-date';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line.model';
import {ISurcharge} from 'app/blocks/model/invoice-surcharge.model';
import {IDiscount} from 'app/blocks/model/discount.model';

export interface IApplyCreditNoteDTO {
    invoice: IInvoice;
    amount: number;
}

export class ApplyCreditNoteDTO implements IApplyCreditNoteDTO {
    public invoice: IInvoice;
    public amount: number;
    constructor(invoice?: IInvoice) {
        if (invoice) {
            this.invoice = invoice;
        }
        this.amount = 0;
    }
}

export interface ICreditNote extends IBaseEntity {
    id?: number;
    creditNoteNo?: string;
    reference?: string;
    creditNoteStatus?: any;
    creditNoteType?: string;

    creationDate?: any;
    updateDate?: any;

    subTotal?: number;
    preTaxTotal?: number;
    tax?: number;
    total?: number;

    allocatedAmount?: number;

    notes?: any;
    customerId?: number;
    customer?: any;
    creditNoteLines?: ICreditNoteLine[];
    adHocLines?: IAdHocLine[];
    createdById?: number;
    createdBy?: any;

    exported?: boolean;
    posted?: boolean;
    deleted?: boolean;
    approved?: boolean;

    vatTypeId?: number;
    vatRateId?: number;

    discount?: IDiscount;
    surcharge?: ISurcharge;
    linkedOrderIds: number[];
}

export class CreditNote extends BaseEntity implements ICreditNote {
    public id?: number;
    public creditNoteNo?: string;
    public reference?: string;
    public creditNoteStatus?: any;
    public creditNoteType?: string;

    public creationDate?: any;
    public updateDate?: any;

    public subTotal?: number;
    public preTaxTotal?: number;
    public tax?: number;
    public total?: number;

    public allocatedAmount?: number;

    public notes?: any;
    public customerId?: number;
    public customer?: any;
    public creditNoteLines?: ICreditNoteLine[];
    public adHocLines?: IAdHocLine[];
    public createdById?: any;
    public createdBy?: any;

    public exported?: boolean;
    public posted?: boolean;
    public deleted?: boolean;
    public approved?: boolean;

    public vatTypeId?: number;
    public vatRateId?: number;

    public discount: any;
    public surcharge: any;

    public linkedOrderIds: number[];

    constructor(creditNote?: any) {
        super();
        if (creditNote) {
            this.id = creditNote.id;
            this.creditNoteNo = creditNote.creditNoteNo;
            this.reference = creditNote.reference;
            this.creditNoteStatus = creditNote.creditNoteStatus;
            this.creditNoteType = creditNote.creditNoteType;

            this.creationDate = DiDate.newInstance(creditNote.creationDate);
            this.updateDate = DiDate.newInstance(creditNote.updateDate);

            this.subTotal = creditNote.subTotal;
            this.preTaxTotal = creditNote.preTaxTotal;
            this.tax = creditNote.tax;
            this.total = creditNote.total;

            this.allocatedAmount = creditNote.allocatedAmount;

            this.notes = creditNote.notes;
            this.customerId = creditNote.customerId;
            this.customer = creditNote.customer;
            this.creditNoteLines = creditNote.creditNoteLines || [];
            this.adHocLines = creditNote.adHocLines || [];
            this.createdById = creditNote.createdById;
            this.createdBy = creditNote.createdBy;

            this.exported = creditNote.exported;
            this.posted = creditNote.posted;
            this.deleted = creditNote.deleted;
            this.approved = creditNote.approved;

            this.vatTypeId = creditNote.vatTypeId;
            this.vatRateId = creditNote.vatRateId;

            this.discount = creditNote.discount;
            this.surcharge = creditNote.surcharge;

            this.linkedOrderIds = creditNote.linkedOrderIds;
        } else {
            this.creditNoteStatus = 'DRAFT';
            this.creationDate = DiDate.currentDate();
            this.creditNoteLines = [];
            this.adHocLines = [];
            this.allocatedAmount = 0;
            this.linkedOrderIds = [];
        }
    }

    get discriminator(): any {
        return this.creditNoteNo;
    }

    clone(): CreditNote {
        return new CreditNote(this);
    }
}
