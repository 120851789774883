import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {IResourceIcon, ResourceIcon} from 'app/blocks/model/resource-icon.model';
import {Encode} from 'app/blocks/util/encode';
import {ResourceIconComponent} from 'app/main/private/common/resource-icons/resource-icon.component';

// PLEASE NOTE THIS IS BEING USED EXCLUSIVELY AS A WEBCOMPONENT.
@Component({
    selector: 'resource-icons-bar',
    templateUrl: './resource-icons-bar.component.html',
    styleUrls: ['./resource-icons-bar.component.scss'],
    providers: [ResourceIconComponent]
})
export class ResourceIconBarComponent implements OnChanges {
    @Input() positionAbsolutely: string;

    //determining version
    @Input() isVehicleDriverVersion: boolean;

    //vehicle and driver version
    @Input() vehicleSkillIconsJson: string;
    @Input() driverSkillIconsJson: string;
    public vehicleSkillIcons: ResourceIcon[];
    public driverSkillIcons: ResourceIcon[];

    //display ungrouped icons version
    @Input() skillIconsJson: string;
    public skillIcons: ResourceIcon[];

    // ----- LIFECYCLE ----//
    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnChanges(): void {
        if (this.isVehicleDriverVersion) {
            if (!this.vehicleSkillIconsJson || !this.driverSkillIconsJson) {
                console.warn('No (vehicle/driver)SkillIconsJson inputted into ResourceIconsBar. Provide data to this component before creating it.');
            }

            this.vehicleSkillIcons = this.parseSkillJSON(this.vehicleSkillIconsJson);
            this.driverSkillIcons = this.parseSkillJSON(this.driverSkillIconsJson);
        } else {
            if (!this.skillIconsJson) {
                console.warn('No SkillIconsJson inputted into ResourceIconsBar. Provide data to this component before creating it.');
            }

            this.skillIcons = this.parseSkillJSON(this.skillIconsJson);
        }

        this.cdRef.detectChanges();
    }

    // ----- FNs -------//
    private parseSkillJSON(input: string) {
        return Encode.decodeStringFromHTML<ResourceIcon[]>(input);
    }
}
