import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {DeviationReason, IDeviationReason} from 'app/blocks/model/deviation-reason.model';
import {DeviationReasonService} from 'app/blocks/service/api/deviation-reason.service';

@Injectable({
    providedIn: 'root'
})
export class DeviationReasonResource extends AbstractEntityResource<IDeviationReason> {
    constructor(deviationReasonService: DeviationReasonService, router: Router) {
        super(
            Resource.DEVIATION_REASON,
            deviationReasonService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DEVIATION_REASON, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DEVIATION_REASON),
            (entity?) => new DeviationReason(entity),
            (entity) => entity.id
        );
    }
}
