export const locale = {
    lang: 'fr',
    data: {
        checkRouteModal: {
            heading: 'Route Preview',
            scopes: {
                default: {
                    fields: {
                        collectionPoint: {
                            label: 'Collection Point'
                        },
                        deliveryPoint: {
                            label: 'Delivery Point'
                        }
                    }
                }
            },
            rate: 'Rate',
            mileage: 'Mileage'
        }
    }
};
