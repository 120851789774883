import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IEventType extends IBaseEntity {
    id?: number;
    name: string;
    color?: string;
    allowNotes?: boolean;
}

export class EventType extends BaseEntity implements IEventType {
    public id?: number;
    public name: string;
    public color?: string;
    public allowNotes?: boolean;

    constructor(eventType?: any) {
        super();
        if (eventType) {
            this.id = eventType.id;
            this.name = eventType.name;
            this.color = eventType.color;
            this.allowNotes = eventType.allowNotes;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): EventType {
        return new EventType(this);
    }
}
