import {INote, Note} from 'app/blocks/model/note.model';
import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IAddress} from 'app/blocks/model/address.model';
import {ITrafficArea, TrafficArea} from 'app/blocks/model/traffic-area.model';
import {ITpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';

export interface ILocationPoint extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    underReview?: boolean;
    residential?: boolean;
    contactInfo?: IContactInfo;
    ciAddress?: IAddress;
    notes?: INote;
    organisationId?: number;
    organisationName?: string;
    trafficArea?: ITrafficArea;
    tp_required_location_skills?: ITpVehicleSkill[];
    timeToService?: number;
    podRequired?: boolean;
    isEmailNotificationEnabled?: boolean;
    emailForDeliveryNotification?: string;
    deleted?: boolean;
    awaitingApproval?: boolean;
    type?: LocationPointType;
    hasStandingTrailers?: boolean;
}

export enum LocationPointType {
    DEPOT = 'DEPOT',
    OFFICE = 'OFFICE',
    HEAD_OFFICE = 'HEAD_OFFICE'
}

export class LocationPoint extends BaseEntity implements ILocationPoint {
    public id?: number;
    public name?: string;
    public description?: string;
    public underReview?: boolean;
    public residential?: boolean;
    public contactInfo?: IContactInfo;
    public ciAddress?: IAddress;
    public notes?: INote;
    public organisationId?: number;
    public organisationName?: string;
    public trafficArea?: ITrafficArea;
    public tp_required_location_skills?: ITpVehicleSkill[];
    public timeToService?: number;
    public podRequired?: boolean;
    public isEmailNotificationEnabled?: boolean;
    public emailForDeliveryNotification?: string;
    public deleted?: boolean;
    public awaitingApproval?: boolean;
    public type?: LocationPointType;
    public completeAddress?: String;
    public hasStandingTrailers?: boolean;

    constructor(locationPoint?) {
        super();
        if (locationPoint) {
            this.id = locationPoint.id;
            this.name = locationPoint.name;
            this.description = locationPoint.description;
            this.underReview = locationPoint.underReview;
            this.residential = locationPoint.residential;
            this.contactInfo = locationPoint.contactInfo ? new ContactInfo(locationPoint.contactInfo) : null;
            this.ciAddress = locationPoint.ciAddress;
            this.notes = locationPoint.notes;
            this.organisationId = locationPoint.organisationId;
            this.organisationName = locationPoint.organisationName;
            this.trafficArea = locationPoint.trafficArea;
            this.tp_required_location_skills = locationPoint.tp_required_location_skills;
            this.timeToService = locationPoint.timeToService;
            this.podRequired = locationPoint.podRequired;
            this.isEmailNotificationEnabled = locationPoint.isEmailNotificationEnabled;
            this.emailForDeliveryNotification = locationPoint.emailForDeliveryNotification;
            this.deleted = locationPoint.deleted ? true : false;
            this.awaitingApproval = locationPoint.awaitingApproval ? true : false;
            this.type = locationPoint.type;
            this.completeAddress = [
                locationPoint.name,
                locationPoint.description,
                locationPoint.ciAddress?.line1,
                locationPoint.ciAddress?.countyState
            ]
                .filter(Boolean)
                .join(', ');
            this.hasStandingTrailers = locationPoint.hasStandingTrailers;
        } else {
            this.contactInfo = new ContactInfo();
            this.notes = new Note();
            this.trafficArea = new TrafficArea();
            this.tp_required_location_skills = [];
            this.deleted = false;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): LocationPoint {
        return new LocationPoint(this);
    }
}
