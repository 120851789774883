import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalOptions, NzModalRef, NzModalService, NzModalState} from 'ng-zorro-antd/modal';

import {DiBlockingDialogComponent} from 'app/common/blocking-message/blocking-modal/blocking-modal.component';

@Injectable({
    providedIn: 'root'
})
export class BlockerDialogService {
    constructor(
        private modalService: NzModalService,
        private _translateService: TranslateService
    ) {}

    modal: NzModalRef;

    async translateData(messageKeys: any, params: any): Promise<any> {
        const translatedData: any = {};
        const keys = Object.keys(messageKeys);
        const values = keys.map((key) => messageKeys[key]).filter((x) => x);

        const translations = await this._translateService.get(values, params).toPromise();
        for (let i = 0; i < keys.length; i++) {
            translatedData[keys[i]] = translations[values[i]];
        }
        return translatedData;
    }

    async showBlocker(dialogData: any = {}, params: any = {}): Promise<void> {
        const translatedData = await this.translateData(dialogData, params);
        const modalConfig: ModalOptions = {
            nzContent: DiBlockingDialogComponent,
            nzData: {
                data: translatedData
            },
            nzFooter: null,
            nzClosable: false,
            nzCloseOnNavigation: false,
            nzMaskClosable: false
        };

        if (this.modal == null || this.modal.state == NzModalState.CLOSED) {
            this.modal = this.modalService.create(modalConfig);
        }
    }

    unBlock() {
        if (this.modal?.state == NzModalState.OPEN) {
            this.modal.close();
        }
    }
}
