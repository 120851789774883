import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {ExchangeRateService} from 'app/blocks/service/api/exchange-rate.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IExchangeRate, ExchangeRate} from 'app/blocks/model/exchange-rate.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateResource extends AbstractEntityResource<IExchangeRate> {
    constructor(exchangeRateService: ExchangeRateService, router: Router) {
        super(
            Resource.EXCHANGE_RATES,
            exchangeRateService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.EXCHANGE_RATES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.EXCHANGE_RATES),
            (entity?) => new ExchangeRate(entity),
            (entity) => entity.id,
            faExchangeAlt
        );
    }
}
