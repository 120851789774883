import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {LastPosition} from 'app/blocks/model/lastposition.model';
import {TrackingService} from 'app/blocks/service/api/tracking.service';
import {GetTrackingData} from 'app/blocks/store/actions/tracking-actions';
import produce from 'immer';

export interface LastPositionModel {
    data: LastPosition[];
    updating: boolean;
}

export function getDefaultState(): LastPositionModel {
    return {data: [], updating: false};
}

@State<LastPositionModel>({
    name: 'lastPosition',
    defaults: getDefaultState()
})
@Injectable({
    providedIn: 'root'
})
export class TrackingState {
    constructor(private _trackingService: TrackingService) {}

    @Action(GetTrackingData)
    async getLastPositions(ctx: StateContext<LastPositionModel>, action: GetTrackingData): Promise<void> {
        console.log('GetTrackingData');
        let alreadyUpdating = false;
        let createStateData = false;
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (draft) {
                    if (draft.updating) {
                        console.log('Already updating');
                        alreadyUpdating = true;
                    } else {
                        draft.updating = true;
                    }
                } else {
                    console.log('No state data');
                    createStateData = true;
                }
            })
        );
        if (alreadyUpdating) {
            return;
        }
        if (createStateData) {
            const state = getDefaultState();
            state.updating = true;
            ctx.setState(state);
        }

        const lastPositions = await this._trackingService.getLastPositionForGpsUnits(action.gpsUnitIds);
        console.log('lastPositions ' + (lastPositions ? 'defined' : 'undefined'));
        if (lastPositions) {
            console.log('lastPositions.length: ' + lastPositions.length);
        }

        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (draft) {
                    try {
                        draft.data = lastPositions;
                        draft.updating = false;
                    } catch (error) {
                        const x = error;
                        console.log(error);
                        draft.updating = false;
                    }
                } else {
                    console.log('Still no state data!');
                }
            })
        );
    }
}
