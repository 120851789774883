import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IDriverType, DriverType} from 'app/blocks/model/driver-type.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class DriverTypeService extends EntityCrudService<IDriverType> {
    constructor(http: HttpClient) {
        super(http, Resource.DRIVER_TYPES, (obj?) => new DriverType(obj));
    }

    findByDriverId = (driverId: number): Promise<IDriverType> => {
        return this.http.get<IDriverType>(`${SERVER_API_URL}api/driver-types/driver/${driverId}`).toPromise();
    };
}
