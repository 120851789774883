import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {QuestionCollection} from 'app/blocks/model/check-list-category.model';

export interface ISafetyProfile extends IBaseEntity {
    id?: number;
    name: string;
    categories?: QuestionCollection[];
    resources?: any[];
}

export class SafetyProfile extends BaseEntity implements ISafetyProfile {
    public id?: number;
    public name: string;
    public categories: QuestionCollection[];
    public resources: any[];

    constructor(safetyProfile?: any) {
        super();
        console.log(safetyProfile);
        if (safetyProfile) {
            this.id = safetyProfile.id;
            this.name = safetyProfile.name;
            this.categories = safetyProfile.categories;
            this.resources = safetyProfile.resources || [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): SafetyProfile {
        return new SafetyProfile(this);
    }
}
