import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ISubContractorLeg, SubContractor} from 'app/blocks/model/sub-contractor-leg.model';
import {Resource} from 'app/constants/resource';

@Injectable({
    providedIn: 'root'
})
export class SubContractService extends EntityCrudService<ISubContractorLeg> {
    constructor(http: HttpClient) {
        super(http, Resource.SUB_CONTRACTOR, (obj?) => new SubContractor(obj));
    }
    public unSubContractLegs = (ids: number[], sendSubContractCancellationEmail: boolean): Promise<any[]> => {
        return this.http
            .post<any[]>(`${SERVER_API_URL}api/legs/unsubcontract`, {
                legIds: ids,
                sendSubContractCancellationEmail: sendSubContractCancellationEmail
            })
            .toPromise();
    };
}
