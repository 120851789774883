import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {ITrafficSheetLeg, TrafficSheetLeg} from 'app/blocks/model/traffic-sheet-legs.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {TrafficSheetService} from 'app/blocks/service/api/traffic-sheet.service';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class TrafficSheetResource extends AbstractEntityResource<ITrafficSheetLeg> {
    constructor(legTrafficSheetService: TrafficSheetService, router: Router) {
        super(
            Resource.LEG_TRAFFIC_SHEET,
            legTrafficSheetService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.TRAFFIC_SHEET, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.TRAFFIC_SHEET),
            (entity?) => new TrafficSheetLeg(entity),
            (entity) => entity
        );
    }
}
