import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {DriverTypeService} from 'app/blocks/service/api/driver-type.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IDriverType, DriverType} from 'app/blocks/model/driver-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class DriverTypeResource extends AbstractEntityResource<IDriverType> {
    constructor(driverTypeService: DriverTypeService, router: Router) {
        super(
            Resource.DRIVER_TYPES,
            driverTypeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.DRIVER_TYPES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.DRIVER_TYPES),
            (entity?) => new DriverType(entity),
            (entity) => entity.id,
            faStar
        );
    }
}
