import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IBackup extends IBaseEntity {
    id?: number;
    tenantName: string;
    created: string;
    lastUpdated: string;
    status: string;
    url: string;
}

export class Backup extends BaseEntity implements IBackup {
    public id: number;
    public tenantName: string;
    public created: string;
    public lastUpdated: string;
    public status: string;
    public url: string;

    constructor(backup?) {
        super();
        if (backup) {
            this.tenantName = backup.tenantName;
            this.created = backup.created;
            this.lastUpdated = backup.lastUpdated;
            this.status = backup.status;
            this.url = backup.url;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Backup {
        return new Backup(this);
    }
}
