export const locale = {
    lang: 'en',
    data: {
        checkRouteModal: {
            heading: 'Route Preview',
            scopes: {
                default: {
                    fields: {
                        collectionPoint: {
                            label: 'Collection Point'
                        },
                        deliveryPoint: {
                            label: 'Delivery Point'
                        },
                        collectionAddressSearch: {
                            label: 'Collection Address'
                        },
                        deliveryAddressSearch: {
                            label: 'Delivery Address'
                        },
                        collectionSearchType: {
                            label: 'Search By'
                        },
                        deliverySearchType: {
                            label: 'Search By'
                        }
                    }
                }
            },
            rate: 'Rate',
            mileage: 'Mileage',
            costPerMile: 'Cost Per Mile',
            priceListId: 'Price List: '
        }
    }
};
