import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {ITrafficArea, TrafficArea} from 'app/blocks/model/traffic-area.model';

@Injectable({
    providedIn: 'root'
})
export class TrafficAreaService extends EntityCrudService<ITrafficArea> {
    constructor(http: HttpClient) {
        super(http, Resource.TRAFFIC_AREAS, (obj?) => new TrafficArea(obj));
    }

    getUnassigned(forControlAreaId: number): Promise<any> {
        let params = new HttpParams();

        if (forControlAreaId) {
            params = params.append('forControlAreaId', forControlAreaId.toString());
        }

        return this.http.get<ITrafficArea[]>(`${SERVER_API_URL}api/traffic-areas-unassigned`, {params: params}).toPromise();
    }

    public getLite = (): Promise<TrafficArea[]> => {
        return this.http.get<any[]>(`${SERVER_API_URL}api/traffic-areas-lite`).toPromise();
    };

    public getTrafficAreaForPostcodeArea = (postcodeArea: string): Promise<ITrafficArea> => {
        return this.http.get<any>(`${SERVER_API_URL}api/traffic-areas-for-postcode-area/` + postcodeArea).toPromise();
    };

    public getTrafficAreasForControlArea = (controlAreaId: string): Promise<ITrafficArea[]> => {
        return this.http.get<ITrafficArea[]>(`${SERVER_API_URL}api/control-areas/${controlAreaId}/traffic-areas`).toPromise();
    };

    getDefaultForTenant = (): Promise<ITrafficArea> => {
        return this.http.get<ITrafficArea>(SERVER_API_URL + 'api/traffic-areas/tenant-default').toPromise();
    };
}
