<div id="reset-password" fxLayout="column">
    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="reset-password-form" [@animate]="{value: '*', params: {duration: '300ms', y: '100px'}}">
            <div class="logo">
                <img *ngIf="!is_dark_mode" class="logo" src="assets/images/logos/logo-small.png" alt="logo" />
                <img *ngIf="is_dark_mode" class="logo" src="assets/images/logos/hauliermagic-logo.png" alt="logo" />
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>
                <mat-form-field>
                    <input matInput type="password" placeholder="Password" formControlName="password" />
                    <mat-error> Password is required </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="Confirm Password" formControlName="passwordConfirm" />
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')"> Password confirmation is required </mat-error>
                    <mat-error
                        *ngIf="
                            !resetPasswordForm.get('passwordConfirm').hasError('required') &&
                            resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')
                        ">
                        Passwords must match
                    </mat-error>
                </mat-form-field>

                <button
                    mat-raised-button
                    class="submit-button"
                    color="accent"
                    aria-label="RESET MY PASSWORD"
                    [disabled]="resetPasswordForm.invalid"
                    (click)="resetPassword()">
                    RESET MY PASSWORD
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>
        </div>
    </div>
</div>
