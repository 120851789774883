import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FuseNavigation} from '@fuse/types';
import {SERVER_API_URL} from 'app/app.constants';
import {IProfile, Profile} from 'app/blocks/model/profile.model';
import {Resource} from 'app/constants/resource';
import {adminFeature} from 'app/constants/role-settings/admin';
import {adminNavigation} from 'app/navigation/admin-navigation';
import {upperCase} from 'lodash';
import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends EntityCrudService<IProfile> {
    constructor(http: HttpClient) {
        super(http, Resource.PROFILES, (obj?) => new Profile(obj));
    }

    getProfile = (id: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/super-admin/profiles/${id}`).toPromise();
    };

    saveProfile = (profile: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/super-admin/profiles/', profile).toPromise();
    };

    deleteProfile = (id: number): Promise<any> => {
        return this.http.delete<any>(SERVER_API_URL + `api/super-admin/profiles/${id}`).toPromise();
    };

    getMenu = (filters: any): FuseNavigation[] => {
        return this.addVisibility(adminNavigation.concat(adminFeature), filters);
    };

    addVisibility = (nav: FuseNavigation[], filters: any) => {
        const menuList = [];
        for (const item of nav) {
            const menu = {
                id: item.id,
                visible: filters != null && filters[item.id] != null ? filters[item.id] : true,
                type: upperCase(item.type),
                children: item.children ? this.addVisibility(item.children, filters) : null
            };
            menuList.push(menu);
        }
        return menuList;
    };

    getProfileDefaults = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/super-admin/profiles/profile-defaults`).toPromise();
    };
}
