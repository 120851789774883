import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IStop} from 'app/blocks/model/stop.model';

export interface ITrailer extends IBaseEntity {
    id?: number;
    trailerRef: string;
    manufacturer: string;
    name: string;
    trailerTypeId: number;
    trailerTypeName: string;
    safetyProfileId: number;
    safetyProfileName: string;
    internalDimension: number;
    externalDimension: number;
    weight: number;
    customerTrailer: boolean;
    gpsUnit: string;
    tpTrailerTypeId: number;
    tpTrailerTypeName: string;
    deleted?: boolean;
    controlAreaId?: number;
    trafficAreaId?: number;
    now?: IStop[];
    next?: IStop[];
    tomorrow?: IStop[];
    nextPmi?: any;
    documents?: any[];
}

export class Trailer extends BaseEntity implements ITrailer {
    public id?: number;
    public trailerRef: string;
    public manufacturer: string;
    public name: string;
    public trailerTypeId: number;
    public trailerTypeName: string;
    public safetyProfileId: number;
    public safetyProfileName: string;
    public internalDimension: number;
    public externalDimension: number;
    public weight: number;
    public customerTrailer: boolean;
    public gpsUnit: string;
    public tpTrailerTypeId: number;
    public tpTrailerTypeName: string;
    public deleted?: boolean;
    public controlAreaId?: number;
    public trafficAreaId?: number;
    public documents?: any[];
    public now?: IStop[];
    public next?: IStop[];
    public tomorrow?: IStop[];
    public nextPmi?: any;

    constructor(trailer?: any) {
        super();
        if (trailer) {
            this.id = trailer.id;
            this.trailerRef = trailer.trailerRef;
            this.manufacturer = trailer.manufacturer;
            this.name = trailer.name;
            this.trailerTypeId = trailer.trailerTypeId;
            this.trailerTypeName = trailer.trailerTypeName;
            this.safetyProfileId = trailer.safetyProfileId;
            this.safetyProfileName = trailer.safetyProfileName;
            this.internalDimension = trailer.internalDimension;
            this.externalDimension = trailer.externalDimension;
            this.weight = trailer.weight;
            this.customerTrailer = trailer.customerTrailer;
            this.gpsUnit = trailer.gpsUnit;
            this.tpTrailerTypeId = trailer.tpTrailerTypeId;
            this.tpTrailerTypeName = trailer.tpTrailerTypeName;
            this.deleted = trailer.deleted;
            this.controlAreaId = trailer.controlAreaId;
            this.trafficAreaId = trailer.trafficAreaId;
            this.nextPmi = trailer.nextPmi;
            this.documents = trailer.documents;
            // Add other attributes
            // this.name = trailer.name;
            // Whenever there is a time element, use DiTime
            // this.timeAttr = DiTime.newInstance(trailer.timeAttr)
            // Whenever there is a date element, use DiDate
            // this.dateAttr = DiDate.newInstance(trailer.dateAttr)
        }
    }

    get discriminator(): any {
        return this.trailerRef;
    }

    clone(): Trailer {
        return new Trailer(this);
    }
}
