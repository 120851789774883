import {Injectable} from '@angular/core';
import {Principal} from 'app/core/auth/principal.service';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {Store} from '@ngxs/store';
import {LogoutUser} from 'app/blocks/store/actions/logout-reducer';
import {FilterSettingService} from 'app/blocks/service/api/filter-setting.service';
import {SuperUserService} from 'app/core/auth/super-user.service';

@Injectable()
export class LoginService {
    isSuperuser = false;
    constructor(
        // private languageService: JhiLanguageService,
        private principal: Principal,
        private authServerProvider: AuthServerProvider,
        private _store: Store,
        private filterService: FilterSettingService,
        private superUserService: SuperUserService
    ) {
        this.isSuperuser = this.superUserService.getIsSuperUser();
    }

    login(credentials, callback?): Promise<any> {
        const cb = callback || (() => {});

        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe(
                (data) => {
                    this.principal.identity(true).then((account) => {
                        // After the login the language will be changed to
                        // the language selected by the user during his registration
                        // if (account !== null) {
                        // this.languageService.changeLanguage(account.langKey);
                        // }
                        if (!this.isSuperuser) {
                            this.filterService.resetDateFilters(account.id);
                        }
                        resolve(data);
                    });
                    return cb();
                },
                (err) => {
                    this.logout();
                    reject(err);
                    return cb(err);
                }
            );
        });
    }

    loginWithToken(jwt, rememberMe): Promise<any> {
        return this.authServerProvider.loginWithToken(jwt, rememberMe);
    }

    logout(): void {
        this.authServerProvider.logout().subscribe();
        this.principal.authenticate(null);
        this._store.dispatch(new LogoutUser());
        this.superUserService.setIsSuperUser(false);
        this.superUserService.setDifferentUser(null);
        this.superUserService.setNavigationForReload();
    }

    createAccount(credentials, callback?): Promise<any> {
        return this.authServerProvider.create(credentials).toPromise();
    }
}
