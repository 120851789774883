import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {ICurrency} from 'app/blocks/model/currency.model';
import {IUser} from 'app/core/user/user.model';
import {IDriver} from 'app/blocks/model/driver.model';

export interface IDriverMessage extends IBaseEntity {
    id?: number;
    message?: any;
    messageTime?: any;
    user?: IUser;
    driver?: IDriver;
    ackTime?: any;
    readTime?: any;
    flow?: any;
}

export class DriverMessage extends BaseEntity implements IDriverMessage {
    id?: number;
    message?: any;
    messageTime?: any;
    user?: IUser;
    driver?: IDriver;
    ackTime?: any;
    readTime?: any;
    flow?: any;

    constructor(driverMessage?) {
        super();
        if (driverMessage) {
            this.id = driverMessage.id;
            this.messageTime = driverMessage.messageTime;
            this.message = driverMessage.message;
            this.driver = driverMessage.driver;
            this.user = driverMessage.user;
            this.ackTime = driverMessage.ackTime;
            this.readTime = driverMessage.readTime;
            this.flow = driverMessage.flow;
        }
    }

    clone(): DriverMessage {
        return new DriverMessage(this);
    }

    get discriminator(): any {
        return this.id;
    }
}
