import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {ITrafficSheetLeg, TrafficSheetLeg} from 'app/blocks/model/traffic-sheet-legs.model';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {createRequestOption2} from 'app/blocks/util/request-util';
import {Observable} from 'rxjs';
import {TsDriverMap, TsTrailerMap, TsVehicleMap} from 'app/blocks/model/traffic-sheet-resources.model';

@Injectable({
    providedIn: 'root'
})
export class TrafficSheetService extends EntityCrudService<ITrafficSheetLeg> {
    constructor(http: HttpClient) {
        super(http, Resource.TRAFFIC_SHEET, (obj?) => new TrafficSheetLeg(obj));
    }

    getTrafficSheetNew(filtersObj: any): Observable<TrafficSheetLeg[]> {
        const filters = createRequestOption2(filtersObj);
        return this.http.get<TrafficSheetLeg[]>(`${SERVER_API_URL}api/new-traffic-sheet`, {params: filters});
    }

    getTsDrivers(filtersObj: any): Observable<TsDriverMap> {
        const filters = createRequestOption2(filtersObj);
        return this.http.get<any>(`${SERVER_API_URL}api/ts-drivers`, {params: filters});
    }

    getTsVehicles(filtersObj: any): Observable<TsVehicleMap> {
        const filters = createRequestOption2(filtersObj);
        return this.http.get<any>(`${SERVER_API_URL}api/ts-vehicles`, {params: filters});
    }

    getTsTrailers(filtersObj: any): Observable<TsTrailerMap> {
        const filters = createRequestOption2(filtersObj);
        return this.http.get<any>(`${SERVER_API_URL}api/ts-trailers`, {params: filters});
    }
}
