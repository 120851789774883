import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {ITpVehicleType, TpVehicleType} from 'app/blocks/model/tp-vehicle-type.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class TpVehicleTypeService extends EntityCrudService<ITpVehicleType> {
    constructor(http: HttpClient) {
        super(http, Resource.TP_VEHICLE_TYPES, (obj?) => new TpVehicleType(obj));
    }

    public getAllTpVehicleTypesWithAvailableVehiclesAugmented = (): Promise<any> => {
        return this.http.get<TpVehicleType[]>(`${SERVER_API_URL}api/tp-vehicle-types-lite-available-vehicles`).toPromise();
    };

    public findByVehicleId = (vehicleId: number): Promise<any> => {
        return this.http.get<TpVehicleType>(`${SERVER_API_URL}api/tp-vehicle-types/vehicle/${vehicleId}`).toPromise();
    };
}
