import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {de} from 'date-fns/locale';

export interface IDeviationReason extends IBaseEntity {
    id: number;
    name: string;
}

export class DeviationReason extends BaseEntity implements IDeviationReason {
    id: number;
    name: string;

    constructor(deviationReason?) {
        super();
        if (deviationReason) {
            this.id = deviationReason.id;
            this.name = deviationReason.name;
        }
    }

    clone(): DeviationReason {
        return new DeviationReason(this);
    }
}
