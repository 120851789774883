import {Injectable} from '@angular/core';
import {ITrafficSheetLeg} from 'app/blocks/model/traffic-sheet-legs.model';
import {FilterSpec} from 'app/blocks/util/filter-spec';
import moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {SettingService} from 'app/blocks/service/api/settings.service';
import {OPEN_AT_TYPE} from 'app/constants/setting';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {HaulierOrderResource} from 'app/blocks/resource/haulier-order-resource';
import {RunResource} from 'app/blocks/resource/run-resource';

@Injectable({
    providedIn: 'root'
})
export class TrafficSheetResourcesService {
    // Filters:
    appliedModalFilters = new BehaviorSubject<any>(TrafficSheetResourcesService.getInitialFilters());

    // CheckBoxes
    private checkedLegsTrafficSheet = new BehaviorSubject<ITrafficSheetLeg[]>([]);
    currentLegTrafficSheetChecked = this.checkedLegsTrafficSheet.asObservable();
    private checkedIds = new BehaviorSubject<number[]>([]);
    currentCheckedIds = this.checkedIds.asObservable();
    private tenantName: string;
    private openAtType: 'NEW_TAB' | 'NEW_WINDOW' | 'SAME_SCREEN' = 'SAME_SCREEN';

    constructor(
        private authServerProvider: AuthServerProvider,
        private settingService: SettingService,
        private haulierOrderResource: HaulierOrderResource,
        private runResource: RunResource
    ) {
        this.subscribeToSettings();
        let tenant = this.authServerProvider.getTenant();
        this.tenantName = tenant ? tenant.toLocaleLowerCase() : null;
    }

    private subscribeToSettings() {
        this.settingService.getTenantSettingsObs(OPEN_AT_TYPE).subscribe((openAtType) => {
            this.openAtType = openAtType;
        });
    }

    static getInitialFilters() {
        const start_date = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const end_date = moment().add(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss');

        const filters = {
            primaryFilter: '',
            trafficAreas: '',
            businessTypes: '',
            dateFrom: start_date,
            dateTo: end_date,
            dateToFilterOnFilter: 'START_DATE',
            status: ['BOOKED', 'PLANNED'],
            legStatus: null,
            resources: '',
            primarySearchString: '',
            resourceSearchString: '',
            controlArea: null,
            resourceDepotSearchString: ''
        };
        return filters;
    }

    setCheckedIds(ids: number[]) {
        this.checkedIds.next(ids);
    }

    setAppliedModalFilters(newFilters) {
        this.appliedModalFilters.next(newFilters);
    }

    clearModalFilters() {
        this.appliedModalFilters.next(TrafficSheetResourcesService.getInitialFilters());
    }

    openRunView(id: number, type?: 'NEW_TAB' | 'NEW_WINDOW' | 'SAME_SCREEN'): void {
        if (this.tenantName === 'uitesttenant' || this.tenantName === 'uitesttenant2') {
            return;
        }
        if (type) {
            this.openAtType = type;
        }
        if (id) {
            if (this.openAtType === 'NEW_TAB') {
                window.open('/runs/runs/' + id, '_blank');
            } else if (this.openAtType === 'NEW_WINDOW') {
                window
                    .open('/runs/runs/' + id + '?hideSideBar=true', '_blank', 'location=yes,height=1080,width=1500,scrollbars=yes,status=yes')
                    .focus();
            } else {
                this.runResource.navigate.toView(id);
            }
        }
    }

    open(id: number): void {
        if (this.tenantName === 'uitesttenant' || this.tenantName === 'uitesttenant2') {
            return;
        }
        if (id) {
            window.open('/runs/runs/' + id, '_blank');
        }
    }

    openOrderNewWindow(id: number): void {
        if (id) {
            if (this.openAtType === 'NEW_TAB') {
                window.open('/orders/haulier-orders/' + id + '/edit', '_blank');
            } else if (this.openAtType === 'NEW_WINDOW') {
                window
                    .open(
                        'orders/haulier-orders/' + id + '/edit' + '?hideSideBar=true',
                        '_blank',
                        'location=yes,height=1080,width=1500,scrollbars=yes,status=yes'
                    )
                    .focus();
            } else {
                this.haulierOrderResource.navigate.toEdit(id);
            }
        }
    }

    openOrderNewViewWindow(id: number): void {
        if (id) {
            if (this.openAtType === 'NEW_TAB') {
                window.open('/orders/haulier-orders/' + id, '_blank');
            } else if (this.openAtType === 'NEW_WINDOW') {
                window
                    .open(
                        'orders/haulier-orders/' + id + '?hideSideBar=true',
                        '_blank',
                        'location=yes,height=1080,width=1500,scrollbars=yes,status=yes'
                    )
                    .focus();
            } else {
                this.haulierOrderResource.navigate.toView(id);
            }
        }
    }

    openDriverView(id: number): void {
        if (id) {
            window.open('/setup/drivers/' + id, '_blank');
        }
    }
    openVehicleView(id: number): void {
        if (id) {
            window.open('/setup/vehicles/' + id, '_blank');
        }
    }
    openTrailerView(id: number): void {
        if (id) {
            window.open('/setup/trailers/' + id, '_blank');
        }
    }

    // Filters

    getFilterSpecForModal(): FilterSpec {
        return {
            customer: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            status: {
                filterType: 'checkbox'
            },
            legStatus: {
                filterType: 'checkbox'
            },
            order_number: {
                filterType: 'search',
                searchDisplayAttribute: 'orderNo'
            },
            run_id: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            driver: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            vehicle: {
                filterType: 'search',
                searchDisplayAttribute: 'vehicleName'
            },
            trailer: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            sub_contractor: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            start_date: {
                filterType: 'date-range',
                searchDisplayAttribute: 'name'
            },
            end_date: {
                filterType: 'date-range',
                searchDisplayAttribute: 'name'
            },
            from_stop_arrival: {
                filterType: 'date-range',
                searchDisplayAttribute: 'name'
            },
            from_stop_departure: {
                filterType: 'date-range',
                searchDisplayAttribute: 'name'
            },
            to_stop_arrival: {
                filterType: 'date-range',
                searchDisplayAttribute: 'name'
            },
            to_stop_departure: {
                filterType: 'date-range',
                searchDisplayAttribute: 'name'
            },
            traffic_areas: {
                filterType: 'checkbox'
            },
            business_types: {
                filterType: 'checkbox'
            },
            control_area: {
                filterType: 'checkbox'
            },
            resource_depot: {
                filterType: 'search',
                searchDisplayAttribute: 'description'
            },
            from_stop: {
                filterType: 'search',
                searchDisplayAttribute: 'completeAddress'
            },
            to_stop: {
                filterType: 'search',
                searchDisplayAttribute: 'completeAddress'
            }
        };
    }

    getFilterSpec(): FilterSpec {
        return {
            customer: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            order_number: {
                filterType: 'search',
                searchDisplayAttribute: 'orderNo'
            },
            run_id: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            driver: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            vehicle: {
                filterType: 'search',
                searchDisplayAttribute: 'vehicleName'
            },
            trailer: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            traffic_areas: {
                filterType: 'search'
            },
            status: {
                filterType: 'checkbox',
                objectPath: 'trafficSheet.scopes.default.filters.status'
            },
            from_stop: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            },
            to_stop: {
                filterType: 'search',
                searchDisplayAttribute: 'name'
            }
        };
    }
}
