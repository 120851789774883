import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IExpenseType} from 'app/blocks/model/expense-type.model';

export interface IExpensePaymentMethod extends IBaseEntity {
    id?: number;
    name: string;
}
export class ExpensePaymentMethod extends BaseEntity implements IExpensePaymentMethod {
    public id?: number;
    public name: string;

    constructor(expensePaymentMethod?: any) {
        super();
        if (expensePaymentMethod) {
            this.id = expensePaymentMethod.id;
            this.name = expensePaymentMethod.name;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ExpensePaymentMethod {
        return new ExpensePaymentMethod(this);
    }
}
