import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {SettingService} from 'app/blocks/service/api/settings.service';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {ResourceIconService} from 'app/blocks/service/api/resource-icon.service';
import {SuperUserService} from 'app/core/auth/super-user.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _settingsService: SettingService,
        private _authServerProvider: AuthServerProvider,
        private _resourceIconService: ResourceIconService,
        private _superUserService: SuperUserService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this._authServerProvider.getToken() && !this._superUserService.getIsSuperUser()) {
            this.setDisabledIconIds().then((x) => this.setupNavigationComponent());
        } else {
            this.setupNavigationComponent();
        }
    }

    private readonly resourceIconSetupRouteId: string = 'resource-icons';
    private disabledRouteIds: string[] = [];

    private setupNavigationComponent() {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        this.removeDisabledNavigationItems();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            // Load the navigation
            this.navigation = this._fuseNavigationService.getCurrentNavigation();
            this.removeDisabledNavigationItems();

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    private async setDisabledIconIds() {
        const disabledRouteIds: string[] = [];

        let enableResourceIcons = await this._resourceIconService.getResourceIconsEnabledWhenLoadedAsync();
        if (!enableResourceIcons) {
            disabledRouteIds.push(this.resourceIconSetupRouteId);
        }

        this.disabledRouteIds = disabledRouteIds;
    }

    private removeDisabledNavigationItems() {
        if (!this.navigation || !this.disabledRouteIds?.length) {
            return;
        }

        this.navigation.forEach((navItem) => {
            //remove children navitems
            navItem.children = navItem.children.filter((c) => !this.disabledRouteIds.some((dri) => dri === c.id));

            //remove grandchildren navitems
            navItem.children.forEach((childNavItem) => {
                if (!childNavItem.children) {
                    return; //skip
                }

                childNavItem.children = childNavItem.children.filter((c) => !this.disabledRouteIds.some((dri) => dri === c.id));
            });
        });
    }
}
