import {ENTITIES_LEG_PLANNING_BASE_URL} from 'app/app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {ResourceEventTypeService} from 'app/blocks/service/api/resource-event-type.service';
import {Resource} from 'app/constants/resource';

import {IResourceEventType, ResourceEventType} from 'app/blocks/model/resource-event-type.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ResourceEventTypeResource extends AbstractEntityResource<IResourceEventType> {
    constructor(resourceEventTypeService: ResourceEventTypeService, router: Router) {
        super(
            Resource.RESOURCE_EVENT_TYPES,
            resourceEventTypeService,
            new EntityNavigator(ENTITIES_LEG_PLANNING_BASE_URL, Resource.RESOURCE_EVENT_TYPES, router),
            new EntityUrlProvider(ENTITIES_LEG_PLANNING_BASE_URL, Resource.RESOURCE_EVENT_TYPES),
            (entity?) => new ResourceEventType(entity),
            (entity) => entity.name,
            faStar
        );
    }
}
