import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IUserEntity, UserEntity} from 'app/blocks/model/user-entity.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends EntityCrudService<IUserEntity> {
    constructor(http: HttpClient) {
        super(http, Resource.USERS, (obj?) => new UserEntity(obj));
    }
}
