import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ISubcontractedWork extends IBaseEntity {
    id: number;
    subContractorName: string;
    trailerName: string;
    fromPointName: string;
    toPointName: string;
    pallets: number;
    cases: number;
    spaces: number;
    weight: number;
    litres: number;
    runId: number;
    rate: number;
    hasPodAttached: boolean;
}

export class SubcontractedWork extends BaseEntity implements ISubcontractedWork {
    public id: number;
    public subContractorName: string;
    public trailerName: string;
    public fromPointName: string;
    public toPointName: string;
    public pallets: number;
    public cases: number;
    public spaces: number;
    public weight: number;
    public litres: number;
    public runId: number;
    public rate: number;
    public charge: number;
    public hasPodAttached: boolean;

    constructor(subContractor?: any) {
        super();
        if (subContractor) {
            this.id = subContractor.id;
            this.subContractorName = subContractor.subContractorName;
            this.trailerName = subContractor.trailerName;
            this.fromPointName = subContractor.fromPointName;
            this.toPointName = subContractor.toPointName;
            this.pallets = subContractor.pallets;
            this.cases = subContractor.cases;
            this.spaces = subContractor.spaces;
            this.weight = subContractor.weight;
            this.litres = subContractor.litres;
            this.runId = subContractor.runId;
            this.rate = subContractor.rate;
            this.hasPodAttached = subContractor.hasPodAttached;
            this.charge = subContractor.charge;
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): IBaseEntity {
        return new SubcontractedWork(this);
    }
}
