import {faShippingFast, faStar} from '@fortawesome/free-solid-svg-icons';
import {FuseNavigation} from '@fuse/types';

export const customerNavigation: FuseNavigation[] = [
    {
        id: 'menu',
        title: 'Menu',
        translate: 'NAV.MENU',
        type: 'group',
        children: [
            {
                id: 'client-dashboard',
                title: 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type: 'item',
                icon: 'dashboard',
                url: '/orders/haulier-orders'
            },
            {
                id: 'subcontract-dashboard',
                title: 'Orders Pending Subcontracting',
                translate: 'NAV.ORDER_SUBCONTRACT.TITLE',
                type: 'item',
                icon: 'dashboard',
                url: '/orders/haulier-order-subcontracting/pending'
            },
            {
                id: 'subcontracted-dashboard',
                title: 'Subcontracted Orders',
                translate: 'NAV.ORDER_SUBCONTRACTED.TITLE',
                type: 'item',
                icon: 'dashboard',
                url: '/orders/haulier-order-subcontracting/assigned'
            },
            {
                id: 'client-daily-manifest',
                title: 'Daily Manifest',
                translate: 'NAV.DAILY_MANIFEST.TITLE',
                type: 'item',
                icon: 'assignment',
                url: '/orders/haulier-orders/daily-manifest'
            },
            {
                id: 'client-dedicated-vehicle-view',
                title: 'Dedicated Vehicle View',
                translate: 'NAV.DEDICATED_VEHICLE_VIEW.TITLE',
                type: 'item',
                icon: 'directions_bus',
                url: '/fleetviews'
            },
            {
                id: 'etaArrivalsBoard',
                title: 'Arrival Board',
                translate: 'NAV.ARRIVAL_BOARD.TITLE',
                type: 'item',
                url: '/history/eta-arrivals-board',
                icon: 'flight_land'
            },
            {
                id: 'invoice',
                title: 'Invoices',
                translate: 'NAV.INVOICE.TITLE',
                type: 'item',
                url: '/setup/invoices',
                faIcon: faStar
            }
        ]
    }
];
