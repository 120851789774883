import {UntypedFormGroup, FormControl, UntypedFormBuilder} from '@angular/forms';
import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Subject, timer} from 'rxjs';
import {debounceTime, skip, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {LoginService} from 'app/core/login/login.service';
import {ASYNC_TASKS, LOGIN_API_URL} from 'app/app.constants';
import {Principal} from 'app/core/auth/principal.service';

import {navigation} from 'app/navigation/navigation';
import {Router} from '@angular/router';
import {faAngleDown, faBell, faMoon, faSignOutAlt, faSun} from '@fortawesome/free-solid-svg-icons';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import {en_GB, fr_FR, NzI18nService} from 'ng-zorro-antd/i18n';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {NotificationService} from 'app/blocks/service/api/notification.service';
import {SatPopover} from '@ncstate/sat-popover';
import {getTimeText} from 'app/common/utils/date-utils';
import {DiSearchBarComponent} from 'app/common/di-search-bar/di-search-bar.component';
import {RunResource} from 'app/blocks/resource/run-resource';
import {HaulierOrderResource} from 'app/blocks/resource/haulier-order-resource';
import {TrafficSheetResourcesService} from 'app/blocks/service/api/traffic-sheet-resources.service';
import {IHaulierOrder} from 'app/blocks/model/haulier-order.model';
import {FuseTranslationLoaderService, TranslationSettings} from '@fuse/services/translation-loader.service';
import {locale as english} from 'app/layout/components/toolbar/i18n/en';
import {locale as french} from 'app/layout/components/toolbar/i18n/fr';
import {Store} from '@ngxs/store';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {TranslationService} from 'app/blocks/service/api/translation.service';

import {enable as enableDarkMode, disable as disableDarkMode, isEnabled as isDarkReaderEnabled, setFetchMethod as setFetchMethod} from 'darkreader';
import {GetTenantSettings, TrackAsyncRequest} from 'app/blocks/store/actions/common-actions';
import {ServerEventService} from 'app/blocks/service/server-event.service';
import {CurrencyService} from 'app/blocks/service/api/currency.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {CheckRouteModalComponent} from 'app/main/private/common/check-route-modal/check-route-modal.component';
import {RunService} from 'app/blocks/service/api/run.service';
import {DialogService} from 'app/common/dialog/dialog-service';
import versionJson from '../../../../../version.json';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar-ant.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    // Added by RouteMagic
    faAngleDown = faAngleDown;
    faSignOutAlt = faSignOutAlt;
    faQuestionCircle = faQuestionCircle;
    faBell = faBell;

    faSun = faSun;
    faMoon = faMoon;

    // End
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    name = '';
    tenant = '';
    appVersionNo = '';

    quickSearchForm: UntypedFormGroup;
    radioValuesSearchForm = ['Run', 'Order', 'Driver', 'Vehicle', 'Trailer'];
    public searchTermIsEmpty = true; // controls nzSuffix changes for search input

    timerSubscription;
    notifications$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    bulkProgress$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    @ViewChild('notifications', {static: false}) notifications: SatPopover;
    @ViewChild('orderPopover', {static: false}) orderPopover: SatPopover;

    @ViewChild(DiSearchBarComponent, {static: true})
    public readonly searchbar: DiSearchBarComponent;
    @Input() showSearchBar = true;

    // Private
    private _unsubscribeAll: Subject<void>;
    isSuperuser = false;

    isDarkModeActive = false;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public _store: Store,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _principal: Principal,
        private _loginService: LoginService,
        private _router: Router,
        private _NzI18nService: NzI18nService,
        private _notificationService: NotificationService,
        private _runService: RunService,
        private superUserService: SuperUserService,
        private _fb: UntypedFormBuilder,
        private tsService: TrafficSheetResourcesService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _authServerProvider: AuthServerProvider,
        private _translationService: TranslationService,
        private _serverEventService: ServerEventService,
        private currencySerice: CurrencyService,
        private nzModalService: NzModalService,
        private _dialogService: DialogService,
        private orderResource: HaulierOrderResource
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Set the defaults
        this.quickSearchForm = this._fb.group({
            searchType: [{value: 'Order', disabled: false}],
            searchQuery: ''
        });
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'uk'
            },
            {
                id: 'fr',
                title: 'French',
                flag: 'fr'
            }
        ];

        this.appVersionNo = versionJson.appVersion;

        this.isSuperuser = this.superUserService.getIsSuperUser();

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        this.isDarkModeActive = isDarkReaderEnabled();
        if (localStorage.getItem('isDarkModeActive') === 'true' && !this.isDarkModeActive) {
            this.toggleDarkMode();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang
        });

        this._store
            .select((state) => state.common[ASYNC_TASKS])
            .subscribe((asyncTasks: any) => {
                if (asyncTasks) {
                    this.bulkProgress$.next(asyncTasks);
                }
            });

        this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (userIdentity) {
                this.name = userIdentity.firstName + ' ' + userIdentity.lastName;
                this.tenant = userIdentity.tenantId;
                if (!this.isSuperuser) {
                    this._store.dispatch(new TrackAsyncRequest());
                    this._store.dispatch(new GetTenantSettings());
                    this.loadNotifications();
                }
            }
        });

        if (!this.isSuperuser) {
            this._serverEventService.userNotificationEvent.pipe(debounceTime(1000)).subscribe((data) => {
                if (this._principal.isAuthenticated()) {
                    this.loadNotifications();
                }
            });
            this._serverEventService.tenantSettingUpdateEvent.pipe(debounceTime(1000)).subscribe((data) => {
                if (this._principal.isAuthenticated()) {
                    this._store.dispatch(new GetTenantSettings());
                    this.currencySerice.getTenantCurrency();
                }
            });
            this._serverEventService.exhangeRateUpdateEvent.pipe(debounceTime(1000)).subscribe((data) => {
                if (this._principal.isAuthenticated()) {
                    this.currencySerice.getCurrencyMap();
                }
            });
            this._serverEventService.reindexEvent
                .pipe(skip(2))
                .pipe(debounceTime(1000))
                .subscribe((data) => {
                    if (data?.data?.message) {
                        this._dialogService.showMessage({
                            message: data?.data?.message,
                            confirmText: 'common.ok'
                        });
                    }
                });
            this.setTranslation();
        }
    }

    setTranslation() {
        if (this._authServerProvider.getToken()) {
            this._translationService.getTranslation().then((res) => {
                for (const item of res) {
                    TranslationSettings.TRANSLATION_SETTINGS[item.key] = item.value;
                }
            });
        }
    }

    async onSearch(): Promise<void> {
        window.localStorage.setItem('quickSearchParams', String(this.quickSearchForm.value.searchQuery));
        window.localStorage.setItem('quickSearchParamsState', 'notUsed');
        if (this.quickSearchForm.value.searchType == 'Order') {
            window.location.href = 'orders/haulier-orders';
        } else {
            const runId = parseInt(this.quickSearchForm.value.searchQuery);
            if (!isNaN(runId)) {
                const run = await this._runService.getRunWithExceptionCatch(runId);
                if (run) {
                    this.tsService.openRunView(runId, 'NEW_WINDOW');
                }
            }
            window.location.href = 'runs/runs';
        }
    }

    clearSearch(): void {
        this.quickSearchForm.controls.searchQuery.setValue('');
    }

    onSearchValueChange(): void {
        if (this.quickSearchForm.controls.searchQuery.value != '') {
            this.searchTermIsEmpty = false;
        } else {
            this.searchTermIsEmpty = true;
        }
    }

    loadNotifications(): void {
        if (this._authServerProvider.getToken()) {
            this._notificationService.getNotifications().then((notifications) => {
                this.notifications$.next(notifications.body);
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
        if (lang.id !== 'en') {
            this._NzI18nService.setLocale(fr_FR);
        } else {
            this._NzI18nService.setLocale(en_GB);
        }
    }

    help(): void {
        window.open(`https://datainsightshelp.freshdesk.com/support/solutions/65000104319`);
    }

    logout(): void {
        localStorage.setItem('bulkActionStarted', String(false));
        this._loginService.logout();
        this._router.navigate([LOGIN_API_URL]);
    }

    markNotificationsAsRead(): void {
        this._notificationService.markNotificationsAsRead().then((result) => {
            this.loadNotifications();
        });
    }

    getDateTimeAsString(dateStr: string): string {
        return getTimeText(dateStr);
    }

    toggleDarkMode() {
        setFetchMethod(window.fetch);

        if (isDarkReaderEnabled()) {
            disableDarkMode();
        } else {
            enableDarkMode({});
        }
        this.isDarkModeActive = !this.isDarkModeActive;
        localStorage.setItem('isDarkModeActive', String(this.isDarkModeActive));
    }

    openCheckRouteModal() {
        this.nzModalService.create({
            nzContent: CheckRouteModalComponent,
            nzData: {},
            nzWidth: 1040,
            nzStyle: {top: '20px'},
            nzClosable: true,
            nzFooter: null
        });
    }

    addNewOrderWindowed() {
        window.open('orders/haulier-orders/new?hideSideBar=true', '_blank', 'location=yes,height=1080,width=1500,scrollbars=yes,status=yes').focus();
    }

    addNewOrder() {
        this.orderResource.navigate.toNew()
    }

}
