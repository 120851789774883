import {tap} from 'rxjs/operators';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {LoginService} from 'app/core/login/login.service';

export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(private _loginService: LoginService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            console.log('[AuthExpiredInterceptor] Authentication has expired. Logging out.');
                            this._loginService.logout();
                        }
                    }
                }
            )
        );
    }
}
