import {ICountry, Country} from 'app/blocks/model/country.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IVatType} from 'app/blocks/model/vat-type-model';

export interface IDefaultVat extends IBaseEntity {
    id: number;
    type: string;
    vatType: IVatType;
}

export class DefaultVat extends BaseEntity implements IDefaultVat {
    id: number;
    type: string;
    vatType: IVatType;

    constructor(defaultVat?) {
        super();
        if (defaultVat) {
            this.id = defaultVat.id;
            this.type = defaultVat.type;
            this.vatType = defaultVat.vatType;
        }
    }

    clone(): DefaultVat {
        return new DefaultVat(this);
    }
}
