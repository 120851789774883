import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export enum ResourceType {
    VEHICLE,
    TRAILER,
    DRIVER
}

export interface IResourceEvent extends IBaseEntity {
    id?: number;
    description?: string;
    timeInterval?: string;
    lastOcurred?: string;
    nextOccurence?: string;
    startTime: string;
    endTime: string;
    resourceEventTypeId: number;
    resourceEventType?: string;
    driverId?: number;
    vehicleId?: number;
    trailerId?: number;
    colour: string; // this is defined on the type and isn't mapped back on POST AND PUT Requests
    resourceSubject: string;
    workBlocker?: boolean;
    approvalRequired?: boolean;
    isApproved?: boolean;
    creationSource?: string;
}

export class ResourceEvent extends BaseEntity implements IResourceEvent {
    public id?: number;
    public name?: string;
    public description?: string;
    public timeInterval?: string;
    public lastOcurred?: string;
    public nextOccurence?: string;
    public resourceEventType?: string;
    public startTime: string;
    public endTime: string;
    public resourceEventTypeId: number;
    public driverId?: number;
    public vehicleId?: number;
    public trailerId?: number;
    public colour: string;
    public resourceSubject: string;
    public workBlocker?: boolean;
    public approvalRequired?: boolean;
    public isApproved?: boolean;
    public creationSource?: string;

    constructor(resourceEvent?: any) {
        super();
        if (resourceEvent) {
            this.id = resourceEvent.id;
            this.name = resourceEvent.name;
            this.description = resourceEvent.description;
            this.timeInterval = resourceEvent.timeInterval;
            this.lastOcurred = resourceEvent.lastOccured;
            this.nextOccurence = resourceEvent.nextOccurence;
            this.startTime = resourceEvent.startTime;
            this.endTime = resourceEvent.endTime;
            this.resourceEventTypeId = resourceEvent.resourceEventTypeId;
            this.driverId = resourceEvent.driverId;
            this.vehicleId = resourceEvent.vehicleId;
            this.colour = resourceEvent.colour;
            this.resourceEventType = resourceEvent.resourceEventType;
            this.resourceSubject = resourceEvent.resourceSubject;
            this.workBlocker = resourceEvent.workBlocker;
            this.approvalRequired = resourceEvent.approvalRequired;
            this.isApproved = resourceEvent.isApproved;
            this.creationSource = resourceEvent.creationSource;
            this.trailerId = resourceEvent.trailerId;
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): ResourceEvent {
        return new ResourceEvent(this);
    }
}
