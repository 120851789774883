import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ITrafficArea, TrafficArea} from 'app/blocks/model/traffic-area.model';

export class ControlAreaLite {
    id?: number;
    description?: string;
}

export interface IControlArea extends IBaseEntity {
    id?: number;
    description?: string;
    controlAreaCode?: string;
    trafficAreas?: ITrafficArea[];
}

export class ControlArea extends BaseEntity implements IControlArea {
    public id?: number;
    public description?: string;
    public trafficAreas?: TrafficArea[];
    public controlAreaCode?: string;

    constructor(controlArea?: any) {
        super();
        if (controlArea) {
            this.id = controlArea.id;
            this.description = controlArea.description;
            this.controlAreaCode = controlArea.controlAreaCode;
            if (controlArea.trafficAreas && Array.isArray(controlArea.trafficAreas)) {
                this.trafficAreas = controlArea.trafficAreas.map((x) => {
                    const traffArea = new TrafficArea();
                    traffArea.id = x.id;
                    traffArea.description = x.description;
                    traffArea.shortName = x.shortName;
                    return traffArea;
                });
            }
        }
    }

    get discriminator(): any {
        return this.controlAreaCode;
    }

    clone(): ControlArea {
        return new ControlArea(this);
    }
}
