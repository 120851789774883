import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzSpinModule} from 'ng-zorro-antd/spin';

import {DiListPageHeaderComponent} from 'app/common/di-list-page-header/di-list-page-header.component';
import {DiSearchBarModule} from 'app/common/di-search-bar/di-search-bar.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DiHelpModule} from 'app/common/di-help/di-help.module';

@NgModule({
    declarations: [DiListPageHeaderComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NzIconModule,
        NzDropDownModule,
        NzButtonModule,
        DiSearchBarModule,
        FlexLayoutModule,
        FontAwesomeModule,
        NzSpinModule,
        DiHelpModule
    ],
    exports: [DiListPageHeaderComponent],
    providers: []
})
export class DiListPageHeaderModule {}
