import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IMetric, Metric} from 'app/blocks/model/metric.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class MetricService extends EntityCrudService<IMetric> {
    constructor(http: HttpClient) {
        super(http, Resource.METRICS, (obj?) => new Metric(obj));
    }
}
