import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {UserService} from 'app/blocks/service/api/user.service';
import {IUser, User} from 'app/core/user/user.model';
import {IUserEntity, UserEntity} from 'app/blocks/model/user-entity.model';

@Injectable({
    providedIn: 'root'
})
export class UsersResource extends AbstractEntityResource<IUserEntity> {
    constructor(userService: UserService, router: Router) {
        super(
            Resource.USERS,
            userService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.USERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.USERS),
            (entity?) => new UserEntity(entity)
        );
    }
}
