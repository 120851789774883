import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DisplayUtilsService {
    constructor() {}

    decodeHTMLEntity(str: string): string {
        if (!str) {
            return str;
        }

        let result = '';
        const tempArray = str.split(';');

        for (const temp of tempArray) {
            if (!temp) {
                continue;
            }

            result += temp.replace(/&#(\d+)/g, (match, dec): string => {
                return String.fromCharCode(dec);
            });
        }

        return result;
    }

    convertToHoursAndMins(mins: number, symbolForZeroMins = ' - '): string {
        let hhmm = '';
        if (mins === 0 && symbolForZeroMins) {
            hhmm = symbolForZeroMins;
        } else if (mins != null) {
            const hh = Math.floor(mins / 60);
            const mm = mins % 60;
            if (hh !== 0) {
                hhmm += hh + ' h';
                if (mm !== 0) {
                    hhmm += ' ';
                }
            }
            if (mm !== 0) {
                hhmm += mm + ' m';
            }
        }
        return hhmm;
    }

    getWithFixedDecimals(value: number | string, decimalPlaces = 3): number | string {
        if (typeof value === 'number') {
            return value.toFixed(decimalPlaces);
        } else if (typeof value === 'string') {
            return parseFloat(value).toFixed(decimalPlaces);
        }
        return value;
    }

    limitDecimalPlaces(input: number, decimalPlaces = 2): number {
        if (Number.isNaN(input)) {
            return 0.0;
        }
        return parseFloat(input.toFixed(decimalPlaces));
    }
}
