export class GetConfig {
    static readonly type = '[Company] Get Config';
    constructor() {}
}

export class GetTenantSettings {
    static readonly type = '[Tenant Settings] Load TenantSettings';
    constructor() {}
}

export class TrackAsyncRequest {
    static readonly type = '[Async Requests] Track in-progress async requests';
    constructor() {}
}
