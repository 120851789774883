import {Pipe, PipeTransform} from '@angular/core';
import {DisplayUtilsService} from 'app/common/display-utils-service';
import {DECIMAL_PLACES} from 'app/app.constants';
import {Sign} from 'app/blocks/model/sign.model';
import {CurrencyService} from 'app/blocks/service/api/currency.service';

@Pipe({name: 'diCurrency'})
export class CurrencyPipe implements PipeTransform {
    constructor(private _displayUtilsService: DisplayUtilsService) {}

    /**
     * Transform
     *
     * @param {number} amount
     * @param {ICurrency} currency
     * @returns {string}
     */
    transform(amount: number | string, currency: any, label: string = ''): string {
        let result = '';
        amount = parseFloat(amount as string);
        if (isNaN(amount)) {
            amount = 0.0;
        }
        if (amount != null && currency) {
            const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(currency.htmlCode);
            if (currency.isPrefix) {
                if (currency.isPrefix) {
                    result = `${label} ${decodedCurrencySymbol} ${amount.toFixed(DECIMAL_PLACES)}`;
                } else {
                    result = `${label} ${amount.toFixed(DECIMAL_PLACES)} ${decodedCurrencySymbol}`;
                }
            } else {
                if (currency.prefix) {
                    result = `${label} ${decodedCurrencySymbol} ${amount.toFixed(DECIMAL_PLACES)}`;
                } else {
                    result = `${label} ${amount.toFixed(DECIMAL_PLACES)} ${decodedCurrencySymbol}`;
                }
            }
        } else if (amount != null) {
            result = '' + amount;
        }
        return result;
    }
}

@Pipe({name: 'toCurrencySymbol'})
export class CurrencySymbolPipe implements PipeTransform {
    constructor(private _displayUtilsService: DisplayUtilsService) {}
    transform(currency: any): Sign {
        const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(currency.htmlCode);
        if (currency.prefix) return new Sign(decodedCurrencySymbol, currency.prefix);
        else return new Sign(decodedCurrencySymbol, currency.isPrefix);
    }
}

@Pipe({name: 'toCurrencyLabel'})
export class CurrencyLabelPipe implements PipeTransform {
    constructor(private _displayUtilsService: DisplayUtilsService) {}

    transform(label: string, currency: any): string {
        let result = '';
        if (currency) {
            const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(currency.htmlCode);
            result = `${label} (${decodedCurrencySymbol})`;
        } else if (label != null) {
            result = '' + label;
        }
        return result;
    }
}

@Pipe({name: 'toTenantCurrencyLabel'})
export class TenantCurrencyLabelPipe implements PipeTransform {
    constructor(
        private _displayUtilsService: DisplayUtilsService,
        private currencyService: CurrencyService
    ) {}

    transform(label: string): string {
        let result = '';
        const currency = this.currencyService._currencySubject.getValue();
        if (currency) {
            const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(currency.htmlCode);
            result = `${label} (${decodedCurrencySymbol})`;
        } else if (label != null) {
            result = '' + label;
        }
        return result;
    }
}

@Pipe({name: 'toTenantCurrency'})
export class TenantCurrencyPipe implements PipeTransform {
    constructor(
        private _displayUtilsService: DisplayUtilsService,
        private currencyService: CurrencyService
    ) {}

    transform = (amount: number, currency: any, label: string = ''): string => {
        let result = '';
        const multiplier = this.currencyService.getMultiplier(currency.id);
        const tenantCurr = this.currencyService._currencySubject.getValue();
        if (amount != null && currency) {
            const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(tenantCurr.htmlCode);
            if (tenantCurr.isPrefix) {
                if (tenantCurr.isPrefix) {
                    result = `${label} ${decodedCurrencySymbol} ${(multiplier * amount).toFixed(DECIMAL_PLACES)}`;
                } else {
                    result = `${label} ${(multiplier * amount).toFixed(DECIMAL_PLACES)} ${decodedCurrencySymbol}`;
                }
            } else {
                if (tenantCurr.prefix) {
                    result = `${label} ${decodedCurrencySymbol} ${(multiplier * amount).toFixed(DECIMAL_PLACES)}`;
                } else {
                    result = `${label} ${(multiplier * amount).toFixed(DECIMAL_PLACES)} ${decodedCurrencySymbol}`;
                }
            }
        }
        return result;
    };
}
