import {Injectable} from '@angular/core';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {ExpensePaymentMethod, IExpensePaymentMethod} from 'app/blocks/model/expense-payment-method';
import {ExpenseTypeService} from 'app/blocks/service/api/expense-type.service';
import {Router} from '@angular/router';
import {Resource} from 'app/constants/resource';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {faMoneyCheckAlt} from '@fortawesome/free-solid-svg-icons';
import {ExpensePaymentMethodService} from 'app/blocks/service/api/expense-payment-method.service';

@Injectable({
    providedIn: 'root'
})
export class ExpensePaymentMethodResource extends AbstractEntityResource<IExpensePaymentMethod> {
    constructor(expensePaymentMethodService: ExpensePaymentMethodService, router: Router) {
        super(
            Resource.EXPENSE_PAYMENT_METHOD,
            expensePaymentMethodService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.EXPENSE_PAYMENT_METHOD, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.EXPENSE_PAYMENT_METHOD),
            (entity?) => new ExpensePaymentMethod(entity),
            (entity) => entity.name,
            faMoneyCheckAlt
        );
    }
}
