export class Encode {
    public static encodeForHTML<T>(toEncode: T) {
        const asJson = JSON.stringify(toEncode);
        return asJson.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    public static decodeStringFromHTML<T>(encodedString: string) {
        if (!encodedString) {
            return null;
        }

        const decodedString = encodedString
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"');

        return <T>JSON.parse(decodedString);
    }
}
