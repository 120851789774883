import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {LocationPoint} from 'app/blocks/model/location-point.model';

export interface ILastCallIn extends IBaseEntity {
    id?: number;
    legId?: number;
    driverId: number;
    vehicleId?: number;
    trailerId?: number;
    arrival: Date;
    departure?: Date;
    runId?: number;
    description?: string;
    postCodeZip?: string;
}

export class LastCallIn extends BaseEntity implements ILastCallIn {
    public id?: number;
    public legId?: number;
    public driverId: number;
    public vehicleId?: number;
    public trailerId?: number;
    public arrival: Date;
    public departure?: Date;
    public runId: number;
    public description: string;
    public postCodeZip: string;
    public addressLine1: string;
    public addressLine2: string;
    public townCity: string;
    public pointContactPhone: string;
    public pointNotes: string;

    constructor(lastCallIn?: any) {
        super();
        this.id = lastCallIn.id;
        this.legId = lastCallIn.legId;
        this.driverId = lastCallIn.driverId;
        this.vehicleId = lastCallIn.vehicleId;
        this.trailerId = lastCallIn.trailerId;
        this.arrival = lastCallIn.arrival;
        this.departure = lastCallIn.departure;
        this.runId = lastCallIn.runId;
        this.description = lastCallIn.description;
        this.postCodeZip = lastCallIn.postCodeZip;
        this.addressLine1 = lastCallIn.addressLine1;
        this.addressLine2 = lastCallIn.addressLine2;
        this.townCity = lastCallIn.townCity;
        this.pointContactPhone = lastCallIn.pointContactPhone;
        this.pointNotes = lastCallIn.pointNotes;
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): LastCallIn {
        return new LastCallIn(this);
    }
}
