import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faTruckPickup} from '@fortawesome/free-solid-svg-icons';
import {VehicleClassService} from 'app/blocks/service/api/vehicle-class.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IVehicleClass} from 'app/blocks/model/vehicle-class.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {VehicleClass} from 'app/blocks/model/vehicle-class.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class VehicleClassResource extends AbstractEntityResource<IVehicleClass> {
    constructor(vehicleClassService: VehicleClassService, router: Router) {
        super(
            Resource.VEHICLE_CLASSES,
            vehicleClassService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.VEHICLE_CLASSES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.VEHICLE_CLASSES),
            (entity?) => new VehicleClass(entity),
            (entity) => entity.name,
            faTruckPickup
        );
    }
}
