export class Sign {
    private code: string;
    private prefix: boolean;

    constructor(code: string, isPrefix: boolean) {
        this.code = code;
        this.prefix = isPrefix;
    }

    getCode(): string {
        return this.code;
    }

    isPrefix(): boolean {
        return this.prefix;
    }

    isSuffix(): boolean {
        return !this.prefix;
    }
}
