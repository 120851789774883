import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IVatType extends IBaseEntity {
    id?: number;
    name?: string;
    accountsPackageCode?: string;
    rates?: any[];
}

export class VatType extends BaseEntity implements IVatType {
    public id?: number;
    public name?: string;
    public accountsPackageCode?: string;
    public rates?: any[];

    constructor(type?: any) {
        super();
        if (type) {
            this.id = type.id;
            this.name = type.name;
            this.accountsPackageCode = type.accountsPackageCode;
            this.rates = type.rates;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): VatType {
        return new VatType(this);
    }
}
