import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {TpAssetProfile} from 'app/blocks/model/tp-asset-profile.model';
import {ITpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';

export interface ITpVehicleType extends IBaseEntity {
    id?: number;
    vehicle_type: string;
    asset_profile_id?: number;
    cost_fixed: number;
    cost_distance: number;
    cost_time: number;
    maxDistance?: number;
    maxShiftTime?: number;
    amount: number;
    tp_skills?: ITpVehicleSkill[];
    mass?: number;
    volume?: number;
    bonus?: number;
}

export class TpVehicleType extends BaseEntity implements ITpVehicleType {
    public id?: number;
    public vehicle_type: string;
    public asset_profile_id?: number;
    public cost_fixed: number;
    public cost_distance: number;
    public cost_time: number;
    public maxDistance?: number;
    public maxShiftTime?: number;
    public amount: number;
    public tp_skills?: ITpVehicleSkill[];
    public mass?: number;
    public volume?: number;
    public bonus?: number;

    constructor(tpVehicleType?: any) {
        super();
        if (tpVehicleType) {
            this.id = tpVehicleType.id;
            this.vehicle_type = tpVehicleType.vehicle_type;
            this.asset_profile_id = tpVehicleType.asset_profile_id;
            this.cost_fixed = tpVehicleType.cost_fixed;
            this.cost_distance = tpVehicleType.cost_distance;
            this.cost_time = tpVehicleType.cost_time;
            this.maxDistance = tpVehicleType.maxDistance;
            this.maxShiftTime = tpVehicleType.maxShiftTime;
            this.amount = tpVehicleType.amount;
            this.tp_skills = tpVehicleType.tp_skills;
            this.mass = tpVehicleType.mass;
            this.volume = tpVehicleType.volume;
            this.bonus = tpVehicleType.bonus;
            // Add other attributes
            // this.name = tpVehicleType.name;
            // Whenever there is a time element, use DiTime
            // this.timeAttr = DiTime.newInstance(tpVehicleType.timeAttr)
            // Whenever there is a date element, use DiDate
            // this.dateAttr = DiDate.newInstance(tpVehicleType.dateAttr)
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TpVehicleType {
        return new TpVehicleType(this);
    }
}
