import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IExtraType, ExtraType} from 'app/blocks/model/extra-type.model';
import {Resource} from 'app/constants/resource';
import {Observable} from 'rxjs';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ExtraTypeService extends EntityCrudService<IExtraType> {
    constructor(http: HttpClient) {
        super(http, Resource.EXTRA_TYPES, (obj?) => new ExtraType(obj));
    }

    findAll = (): Promise<any> => {
        return this.http.get<ExtraType>(`${SERVER_API_URL}api/extra-types`).toPromise();
    };

    findAllExtraTypeByTariffTableId = (tariffTableId: number): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/extra-types/tariff-table/${tariffTableId}`).toPromise();
    };

    findAllEnabled = (): Observable<any[]> => {
        return this.http.get<ExtraType[]>(`${SERVER_API_URL}api/extra-types-enabled`);
    };

    findAllClausedRefusalExtra = (): Observable<any[]> => {
        return this.http.get<ExtraType[]>(`${SERVER_API_URL}api/extra-types/claused-refusal`);
    };
}
