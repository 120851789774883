import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IResourceIcon extends IBaseEntity {
    id?: number;
    name: string;
    color: string;
    tooltipText: string;
    shapeId: number;
}

export class ResourceIcon extends BaseEntity implements IResourceIcon {
    constructor(toClone?: IResourceIcon) {
        super();

        if (!toClone) {
            return;
        }

        this.id = toClone.id;
        this.color = toClone.color;
        this.tooltipText = toClone.tooltipText;
        this.name = toClone.name;
        this.shapeId = toClone.shapeId;
    }

    id?: number;
    name: string;
    color: string;
    tooltipText: string;
    shapeId: number;

    get discriminator(): any {
        return this.name;
    }

    clone(): IBaseEntity {
        return new ResourceIcon(this);
    }
}

export class ResourceIconAssignment {
    driverResourceIcons: ResourceIcon[];
    vehicleResourceIcons?: ResourceIcon[];
}

export class ResourceIconAssignmentCheckbox extends ResourceIcon {
    constructor(resourceIcon: IResourceIcon) {
        super(resourceIcon);
        this.checked = false;
    }

    checked: boolean;
}
