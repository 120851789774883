import {IScaleValue} from 'app/blocks/model/scale-value.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export enum LimitType {
    MINUMIM,
    MAXIMUM_NOT_INCLUDED,
    MAXIMUM_INCLUDED
}

export enum Metric {
    VOLUME,
    COUNT,
    PALLET_SPACES,
    PALLET_COUNT,
    WEIGHT
}

export interface IScale extends IBaseEntity {
    id?: number;
    description?: string;
    isEnabled?: boolean;
    metric?: Metric;
    scaleValues?: IScaleValue[];
    limitType?: LimitType;
}

export class Scale extends BaseEntity implements IScale {
    public id?: number;
    public description?: string;
    public isEnabled?: boolean;
    public metric?: Metric;
    public scaleValues?: IScaleValue[];
    public limitType?: LimitType;

    constructor(scale?: any) {
        super();
        if (scale) {
            this.id = scale.id;
            this.description = scale.description;
            this.metric = scale.metric;
            this.isEnabled = scale.isEnabled;
            this.limitType = scale.limitType;
            this.scaleValues = scale.scaleValues;
        }
    }

    get discriminator(): any {
        return this.description;
    }

    clone(): Scale {
        return new Scale(this);
    }
}
