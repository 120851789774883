import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faHardHat} from '@fortawesome/free-solid-svg-icons';
import {SafetyProfileService} from 'app/blocks/service/api/safety-profile.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_VEHICLE_INSPECTIONS_BASE_URL} from 'app/app.constants';
import {ISafetyProfile, SafetyProfile} from 'app/blocks/model/safety-profile.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class SafetyProfileResource extends AbstractEntityResource<ISafetyProfile> {
    constructor(safetyProfileService: SafetyProfileService, router: Router) {
        super(
            Resource.SAFETY_PROFILES,
            safetyProfileService,
            new EntityNavigator(ENTITIES_VEHICLE_INSPECTIONS_BASE_URL, Resource.SAFETY_PROFILES, router),
            new EntityUrlProvider(ENTITIES_VEHICLE_INSPECTIONS_BASE_URL, Resource.SAFETY_PROFILES),
            (entity?) => new SafetyProfile(entity),
            (entity) => entity.name,
            faHardHat
        );
    }
}
