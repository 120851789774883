import {Component, Input, OnInit} from '@angular/core';
import {faPlay, faStar} from '@fortawesome/free-solid-svg-icons';
import {Shape} from 'app/blocks/model/enums/shape.enum';
import {ResourceIcon} from 'app/blocks/model/resource-icon.model';

@Component({
    selector: 'resource-icon',
    templateUrl: './resource-icon.component.html',
    styleUrls: ['./resource-icon.component.scss']
})
export class ResourceIconComponent {
    constructor() {}

    @Input() data: ResourceIcon;
    @Input() unpersistedStyleJson: string; //for display purposes, not saved to db
    @Input() useBigVersion: string;

    public getIconStyle(isIcon = false) {
        if (!this.data) {
            return;
        }

        let styles: any = {};
        const color = '#' + this.data.color;

        if (isIcon) {
            styles.color = color;
        } else {
            styles.backgroundColor = color;
        }

        return styles;
    }

    public getShapeClass(): string {
        if (!this.data) {
            return '';
        }

        let classSuffix = this.useBigVersion?.toLowerCase() == 'true' ? '-big' : '';

        switch (this.data.shapeId) {
            case Shape.CIRCLE:
                return `circle${classSuffix} resource-icon`;
            case Shape.SQUARE:
                return `square${classSuffix} resource-icon`;
            case Shape.DIAMOND:
                return `diamond${classSuffix} resource-icon`;
            case Shape.TRIANGLE:
                return `triangle${classSuffix} resource-icon`;
            case Shape.UPSIDEDOWN_TRIANGLE:
                return `upside-down-triangle${classSuffix} resource-icon`;
            case Shape.STAR:
                return `star${classSuffix} resource-icon`;
            default:
                return '';
        }
    }

    public useFontAwesome() {
        return this.data.shapeId > Shape.DIAMOND;
    }

    public getFontAwesomeIconName() {
        switch (this.data.shapeId) {
            case Shape.TRIANGLE:
                return faPlay;
            case Shape.UPSIDEDOWN_TRIANGLE:
                return faPlay;
            case Shape.STAR:
                return faStar;
        }
    }
}
