import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IVehicleClass, VehicleClass} from 'app/blocks/model/vehicle-class.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class VehicleClassService extends EntityCrudService<IVehicleClass> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLE_CLASSES, (obj?) => new VehicleClass(obj));
    }
}
