import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {IClausedDeliveryReason, ClausedDeliveryReason} from 'app/blocks/model/claused-delivery-reason.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {ClausedDeliveryReasonService} from 'app/blocks/service/api/claused-delivery-reason.service';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ClausedDeliveryReasonResource extends AbstractEntityResource<IClausedDeliveryReason> {
    constructor(clausedDeliveryReasonService: ClausedDeliveryReasonService, router: Router) {
        super(
            Resource.CLAUSED_DELIVERY_REASONS,
            clausedDeliveryReasonService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CLAUSED_DELIVERY_REASONS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CLAUSED_DELIVERY_REASONS),
            (entity?) => new ClausedDeliveryReason(entity),
            (entity) => entity
        );
    }
}
