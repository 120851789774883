import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IExchangeRate, ExchangeRate} from 'app/blocks/model/exchange-rate.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateService extends EntityCrudService<IExchangeRate> {
    constructor(http: HttpClient) {
        super(http, Resource.EXCHANGE_RATES, (obj?) => new ExchangeRate(obj));
    }
}
