import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITaxCategory extends IBaseEntity {
    id?: number;
    label?: string;
    isDefaultInGroup?: boolean;
    taxPercentage: number;
}

export class TaxCategory extends BaseEntity implements ITaxCategory {
    public id?: number;
    public label?: string;
    public isDefaultInGroup?: boolean;
    public taxPercentage: number;

    constructor(productTaxCategory?) {
        super();
        if (productTaxCategory) {
            this.id = productTaxCategory.id;
            this.label = productTaxCategory.label;
            this.isDefaultInGroup = productTaxCategory.isDefaultInGroup;
            this.taxPercentage = productTaxCategory.taxPercentage;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TaxCategory {
        return new TaxCategory(this);
    }
}
