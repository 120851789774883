import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ITpVehicleSkill, TpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';

@Injectable({
    providedIn: 'root'
})
export class TpVehicleSkillService extends EntityCrudService<ITpVehicleSkill> {
    constructor(http: HttpClient) {
        super(http, Resource.TP_VEHICLE_SKILLS, (obj?) => new TpVehicleSkill(obj));
    }
}
